import React, { useState, useEffect, useRef } from "react";
import Constants from "../../../services/constant";
import { Form } from "../CommonFeild";
import { ContainerBox } from "../../../shared/ContainerBox";
import BadIcon from "../../../assets/icons/bad (1).png";
import BathroomIcon from "../../../assets/icons/tablelamp.png";
import CarIcon from "../../../assets/icons/car.png";
import ShareIcon from "../../../assets/icons/share.png";
import HomeContent from "../../home/HomeContent";
import { ProfileCard } from "../../../components/cards/ProfileCard";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getpostSavedAction,
  postDetailAction,
} from "../../../redux/actions/postAction";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../redux/actions/toastAction";
import {
  buyerGeneralInfo,
  enquieryMessageAction,
} from "../../../redux/actions/buyerAction";
import { allPostAction } from "../../../redux/actions/postAction";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { postSavedUnSavedAction } from "../../../redux/actions/postAction";
import { useNavigate } from "react-router-dom";
import { emailValidation } from "../../../utils/regex";
import LocationIcon from "../../../assets/icons/loaction.png";
import AllTypeIcon from "../../../assets/icons/All types.png";
import PriceIcon from "../../../assets/icons/price.png";
import LandIcon from "../../../assets/icons/Land size.png";
import { Galleria } from "primereact/galleria";
import moment from "moment";

export const SellerMoreInfo = ({ SetSelectProfile, selectProfile }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [outdoor, setOutdoor] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const [code, setCode] = useState("+61");
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    country: "",
    mobile: "",
    message: "",
  });
  const token = localStorage.getItem("realState");
  useEffect(() => {
    dispatch(postDetailAction(id));
    dispatch(buyerGeneralInfo());
    dispatch(allPostAction({}));
  }, []);
  const postData = useSelector((state) => state.postDetail.postDetailProfile);
  useEffect(() => {
    let array = [];
    let outDoorNewData = postData?.outdoorFeatures;
    array.push(outDoorNewData);
    let inDoorNewData = postData?.indoorFeatures;
    array.push(inDoorNewData);
    let climateNewData = postData?.climateControlEnergy;
    array.push(climateNewData);
    let result = array.reduce((r, a) => r.concat(a), []);
    setOutdoor(result);
  }, [postData]);
  let data = useSelector((state) => state.allPost.allPostProfile);
  const formErrorCheck = () => {
    let formErrors = {};
    if (form?.fullName === "" || form?.fullName === undefined) {
      formErrors.firstName = "Please Enter Full Name";
    }
    if (form.email === "" || form.email === undefined) {
      formErrors.email = "Please Enter Email";
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
    }
    if (form.mobile === "" || form.mobile === undefined) {
      formErrors.mobile = "Please Enter Valid Mobile Number";
    } else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only";
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (form.fullName === "" || form.email === "" || form.mobile === "") {
      dispatch(
        showToast({ severity: "error", summary: "All Fields Required" })
      );
    } else if (!formErrorCheck()) {
      return false;
    } else {
      dispatch(enquieryMessageAction(form, id));
      setForm({
        fullName: "",
        email: "",
        country: "",
        mobile: "",
        message: "",
      });
    }
  };

  const handleSaved = () => {
    if (token) {
      dispatch(postSavedUnSavedAction(id)).then((res) => {
        if (res.success) {
          dispatch(getpostSavedAction());
        }
      });
    } else {
      dispatch(
        showToast({ severity: "error", summary: "Please login to save Brief" })
      );
    }
  };

  const shareFunction = () => {
    navigator.clipboard.writeText(
      "https://therealestateplace.appdeft.biz/#" + location.pathname
    );
    dispatch(
      showToast({ severity: "success", summary: "link is copied to clipbard" })
    );
  };

  const postSaved = useSelector(
    (state) => state.postSavedUnSaved.postSavedUnSavedProfile
  );

  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  useEffect(() => {
    if (token) {
      dispatch(getpostSavedAction());
    }
  }, [token]);
  let similarProperty = data?.filter((item) => item?._id !== id);
  const briefSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [images, setImages] = useState([]);
  const [addFloorPlan, setAddFloorPlan] = useState([]);
  useEffect(() => {
    let array = [];
    let imagesData = [];
    if (postData?.addFloorplan?.length > 0) {
      postData?.addFloorplan?.map((item) => {
        if (item === null || item?.length == 0) {
          array = [];
        } else {
          array.push(Constants.BASE_URL + `${item}`);
        }
      });
      setAddFloorPlan(array);
    }
    if (postData?.image?.length > 0) {
      postData?.image?.map((item) => {
        imagesData.push(Constants.BASE_URL + `${item}`);
      });
      setImages(imagesData);
    }
  }, [postData]);

  const thumbnailTemplate = (item) => {
    return <img src={item} alt="" width="100px" height="100px" />;
  };
  const thumbnailsTemplate = (item) => {
    return <img src={item} alt="" width="100px" height="100px" />;
  };
  const itemTemplate = (item) => {
    return (
      <div>
        <img src={item} alt="image" width="300px" height="300px" />
      </div>
    );
  };
  const itemsTemplate = (item) => {
    return (
      <div>
        <img src={item} alt="image" width="300px" height="300px" />
      </div>
    );
  };
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "960px",
      numVisible: 4,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];
  const backFunc = () => {
    navigate(-1);
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <>
      <>
        <div className="pb-6 bg-green-light">
          <ContainerBox>
            <div className="seller-form-container  pt-8 mt-4">
              <div className="lg:mb-5 md:mb-5 mb-5 w-full ">
                <div className="col-12 md:col-12 flex  lg:mt-5 lg:mb-5 mt-3 mb-3">
                  <Link
                    className="no-underline flex align-items-center"
                    onClick={() => {
                      navigate(-1);
                      gotoBtn();
                    }}
                  >
                    <i
                      onClick={backFunc}
                      className="pi pi-arrow-left text-black "
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                    <span
                      className="ml-2 font-medium text-lg"
                      style={{ color: "rgba(72, 72, 72, 1)" }}
                    >
                      Back
                    </span>
                  </Link>
                </div>

                <div className=" grid p-0 m-0 ">
                  <div className="col-12 md:col-6 lg:col-7 ">
                    <div
                      className="shadow-2 lg:p-4 md:p-3 p-2"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="flex align-items-start justify-content-between mt-3 md:mb-3">
                        {/* <div>
                                                <h2 className=" heading-text-more-info font-semibold text-dark  p-0 m-0" style={{ fontSize: "32px" }}>Demo property name</h2>
                                            </div> */}
                        <div
                          class="heading-text-more-info white-space-nowrap overflow-hidden text-overflow-ellipsis lg:w-10 text-dark font-semibold "
                          style={{ fontSize: "32px", fontFamily: "Raleway" }}
                        >
                          <div className="grid">
                            <div className="col-12 md:col-12 lg:col-7">
                              <p
                                className="heading-text-more-info font-semibold p-0 m-0 text-dark lg:mb-3 md:mb-3"
                                style={{ fontSize: "32px" }}
                              >
                                Full Seller Brief
                              </p>
                            </div>
                            <div className="col-12 md:col-12 lg:col-5">
                              <p className="text-base m-0  font-medium text-dark">
                                {" "}
                                Seller Id: {`S ${postData?.id}`}
                              </p>
                            </div>
                          </div>
                          <div
                            className=" property-space  align-items-center p-0 m-0 mb-3 "
                            style={{ display: "flex" }}
                          >
                            <img
                              src={BadIcon}
                              width={24}
                              height={24}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-base text-black">
                              {postData?.bedrooms === null
                                ? "any"
                                : `${postData.bedrooms}+`}
                            </span>

                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <img
                                src={BathroomIcon}
                                width={24}
                                height={24}
                                alt=""
                              ></img>
                              <span className="ml-3 font-medium text-base text-black">
                                {postData?.bathrooms === null
                                  ? "any"
                                  : `${postData.bathrooms}+`}
                              </span>
                            </p>

                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <img
                                src={CarIcon}
                                width={24}
                                height={24}
                                alt=""
                              ></img>
                              <span className="ml-3 font-medium text-base text-black">
                                {postData?.carSpaces === null
                                  ? "any"
                                  : `${postData.carSpaces}+`}
                              </span>
                            </p>
                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <span className="font-medium text-base text-black">
                                |
                              </span>
                              <span className="ml-3 font-medium text-base text-black ">
                                {postData?.propertyType?.length > 0 &&
                                  postData?.propertyType[0]?.propertyType}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="lg:mb-5 md:mb-0 mb-0">
                          <img
                            src={ShareIcon}
                            alt=""
                            height={24}
                            width={24}
                            style={{ cursor: "pointer" }}
                            onClick={shareFunction}
                          />
                          {postSaved.includes(id) == true &&
                          token?.length > 0 ? (
                            <i
                              className="pi pi-star-fill lg:ml-4 md:ml-4 ml-3 text-main "
                              onClick={() => handleSaved()}
                              style={{ fontSize: "1.5rem", color: "#4E515F" }}
                            ></i>
                          ) : (
                            <i
                              className="pi pi-star lg:ml-4 md:ml-4 ml-3 "
                              onClick={() => handleSaved()}
                              style={{ fontSize: "1.5rem", color: "#4E515F" }}
                            ></i>
                          )}{" "}
                        </div>
                      </div>

                      <div className="flex align-items-center lg:hidden ">
                        <img src={BadIcon} width={24} height={24} alt=""></img>
                        <span className="ml-3 font-medium text-base text-black">
                          {postData?.bedrooms === null
                            ? "any"
                            : `${postData.bedrooms}+`}
                        </span>

                        <p className="flex align-items-center  ml-3">
                          <img
                            src={BathroomIcon}
                            width={24}
                            height={24}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-base text-black">
                            {postData?.bathrooms === null
                              ? "any"
                              : `${postData.bathrooms}+`}
                          </span>
                        </p>

                        <p className="flex align-items-center  ml-3">
                          <img
                            src={CarIcon}
                            width={24}
                            height={24}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-base text-black">
                            {postData?.carSpaces === null
                              ? "any"
                              : `${postData.carSpaces}+`}
                          </span>
                        </p>
                        <p className="flex align-items-center  ml-3">
                          <span className="font-medium text-base text-black">
                            |
                          </span>
                          <span className="ml-3 font-medium text-base text-black ">
                            {postData?.propertyType?.length > 0 &&
                              postData?.propertyType[0]?.propertyType}
                          </span>
                        </p>
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />
                      <div className="grid mb-3">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Property details
                          </h2>
                        </div>
                        <div className="col-12 md:col-12">
                          <p className="ml-2 text-black font-medium text-base">
                            {postData?.Description
                              ? postData?.Description
                              : "N/A"}
                          </p>
                          {postData?.address?.length > 0 &&
                            postData?.address?.map((item) => {
                              return (
                                <div className="flex align-items-center">
                                  <img
                                    src={LocationIcon}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {`${postData?.address[0]?.city}, Australia  ${postData?.address[0]?.zipCode}, ${postData?.address[0]?.state}`}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-12 md:col-12">
                          <div className="flex align-items-center">
                            <img
                              src={PriceIcon}
                              width={20}
                              height={20}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-black text-lg ">
                              Asking Price ($) -{" "}
                              {formatter.format(postData?.price)}
                            </span>
                          </div>
                          <p className="text-black font-medium text-base py-2 ">
                            Build Year -{" "}
                            {moment(postData?.buildYear)?.format("YYYY")}
                          </p>
                          <p className="text-dark font-semibold text-base ">
                            Photos{" "}
                          </p>
                          <>
                            <Galleria
                              value={images}
                              responsiveOptions={responsiveOptions}
                              item={itemsTemplate}
                              thumbnail={thumbnailsTemplate}
                              panelWidth={600}
                              panelHeight={400}
                              numVisible={5}
                            />
                          </>
                        </div>
                        <div className="col-12 md:col-12">
                          <p className="text-dark font-semibold text-base ">
                            Floorplan{" "}
                          </p>

                          {addFloorPlan?.length > 0 ? (
                            <>
                              <Galleria
                                value={addFloorPlan}
                                responsiveOptions={responsiveOptions}
                                item={itemTemplate}
                                thumbnail={thumbnailTemplate}
                                panelWidth={600}
                                panelHeight={400}
                                numVisible={5}
                              />
                            </>
                          ) : (
                            <span className="ml-3 font-medium text-black text-lg  mb-3">
                              N/A
                            </span>
                          )}
                        </div>
                      </div>

                      {/* new property */}
                      <hr className="buyer-hr p-0 m-0 mb-3" />
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Property type
                          </h2>
                        </div>
                        {postData?.propertyType?.length > 0 &&
                          postData?.propertyType?.map((item, index) => {
                            return (
                              <div className="col-12 md:col-6  mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                                    {item?.propertyType}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <hr className="buyer-hr p-0 m-0 mb-3" />
                      {/* Land Size */}
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Land Size
                          </h2>
                        </div>
                        <div className="col-12 md:col-12 mb-3">
                          <div className="flex align-items-center">
                            <img
                              src={LandIcon}
                              width={20}
                              height={20}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-black text-lg">
                              {" "}
                              {postData?.landSize?.min}
                              <sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* new establishedproperty */}
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            New or established property
                          </h2>
                        </div>
                        <div className="col-12 md:col-12 mb-3">
                          <div className="flex align-items-center">
                            <img
                              src={AllTypeIcon}
                              width={20}
                              height={20}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-black text-lg ">
                              {postData?.establishedProperty === "established"
                                ? "Established"
                                : postData?.establishedProperty === "new"
                                ? "New"
                                : form?.establishedProperty}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Outdoor features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Outdoor features
                          </h2>
                        </div>
                        {postData?.outdoorFeatures?.length > 0 ? (
                          postData?.outdoorFeatures?.map((item, index) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {item?.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-3 font-medium text-black text-lg  mb-3">
                            N/A
                          </span>
                        )}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Indoor  features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Indoor features
                          </h2>
                        </div>
                        {postData?.indoorFeatures?.length > 0 ? (
                          postData?.indoorFeatures?.map((item) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg">
                                    {item.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-3 font-medium text-black text-lg mb-3">
                            N/A
                          </span>
                        )}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Climate control & energy features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Climate control & energy
                          </h2>
                        </div>
                        {postData?.climateControlEnergy?.length > 0 ? (
                          postData?.climateControlEnergy?.map((item) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                                    {item?.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-3 font-medium text-black text-lg mb-3">
                            N/A
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-5 ">
                    <Form
                      setForm={setForm}
                      form={form}
                      handleSubmit={handleSubmit}
                      code={code}
                      setCode={setCode}
                      postData={postData}
                    />
                  </div>
                </div>
              </div>
              {/* <h2
                className=" heading-text-more-info font-semibold text-dark  p-0 m-0 text-center mb-5 mt-5"
                style={{ fontSize: "32px" }}
              >
                Similar properties
              </h2>
              <Slider className="" {...briefSettings}>
                {similarProperty?.map((item, index) => {
                  return index <= 2 ? (
                    <>
                      {item.postType === "BUYER" ||
                        item.postType === "RENTER" ? (
                        item.address?.map((items, childIndex) => {
                          return childIndex <= 0 ? (
                            <div className="p-2">
                              <ProfileCard
                                cardclass="all-profile-card"
                                size=" mb-0 bussiness-card-box"
                                detailClass="brief-card"
                                item={item}
                                index={index}
                                items={items}
                                card={true}
                              />
                            </div>
                          ) : null;
                        })
                      ) : (
                        <div className="p-2">
                          <ProfileCard
                            cardclass="all-profile-card"
                            size=" mb-0 bussiness-card-box"
                            detailClass="brief-card"
                            item={item}
                            index={index}
                            card={true}
                          />
                        </div>
                      )}
                    </>
                  ) : null;
                })}
              </Slider> */}
            </div>
          </ContainerBox>
        </div>
        {selectProfile === true ? (
          <div className="landing-page-modal">
            <CustomDialog
              header={
                <h2
                  className="mt-0 font-semibold text-dark mt-3 ml-3"
                  style={{ fontSize: "32px" }}
                >
                  Pick a Profile to Create a Brief
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                </h2>
              }
              visible={selectProfile}
              onHide={() => SetSelectProfile(false)}
            >
              <PickProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            </CustomDialog>
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
};
