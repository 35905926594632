import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { authenticate, isAuthenticated } from "../../services/authService";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { formDataapi } from "../../services/formDataapi";

export const SellerUploadImageAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const formData = new FormData();
  data?.map((item) => {
    formData.append("files", item);
  });

  const res = await formDataapi(
    "post",
    Constants.END_POINT.UPLOADFILE,
    formData
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.UPLOAD_FILE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const singleFileAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const formData = new FormData();
  formData.append("file", data);
  const res = await formDataapi(
    "post",
    Constants.END_POINT.SINGLEUPLOADFILE,
    formData
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SINGLE_UPLOAD_FILE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
