
export const spaceBetweenWords = (word) => word.replace(/([a-z])([A-Z])/g, "$1 $2");
export const firstLetterToUppercase = (value) => spaceBetweenWords(value.replace(/\b\w/g, (c) => c.toUpperCase()));
export const stringValidation = (val) => {
    const regex = /^[a-zA-Z0-9_.-]*$/;
    return regex.test(val);
};
export const emailValidation = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
};
export const specialCharacters = (value) => /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(value);


export const number = (value) => {
    const regex = /^[0-9]+$|^$/;
    return regex.test(value);
};