import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ContainerBox } from "../../shared/ContainerBox";
import Siderbar from "../../components/sidebar/Siderbar";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { CustomInput, CustomPhoneInput } from "../../components/input/AllInput";
import { Dialog } from "primereact/dialog";
import CheckmarkIcon from "../../assets/icons/checkmark-circle.png";
import { CommonField } from "./commonField";
import EmployeIcon from "../../assets/images/employeeImage.jpg";
import PlacesAutocomplete from "react-places-autocomplete";
import Constants from "../../services/constant";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import { isAuthenticated } from "../../services/authService";
const Profile = ({ SetSelectProfile, selectProfile }) => {
  const {
    form,
    handleChange,
    handleSubmit,
    visible,
    setVisible,
    handleCancel,
    handlechangeAddress,
    handleSelectAddress,
    code,
    setCode,
    setForm,
    showButton,
    setShowButton,
  } = CommonField();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();

  return (
    <>
      <>
        <div className="user-profile-container pt-8 mt-4">
          <ContainerBox>
            <div className="grid sidebar-container lg:py-6 md:py-6 p-0 m-0 flex ">
              <div className="col-12 md:col-4 lg:col-3 sidebar border-round-lg">
                <Siderbar />
              </div>
              <div className="col-12 md:col-8 lg:col-9 ">
                <div className="lg:ml-5">
                  <div className="grid p-0 m-0  flex justify-content-between ">
                    <div className="col-12 md:col-12">
                      <h2 className="text-dark font-semibold text-4xl p-0 m-0 mb-3">
                        {" "}
                        Profile
                      </h2>
                      <div
                        className="profile-image mb-4"
                        style={{ width: "120px", height: "120px" }}
                      >
                        <img
                          src={
                            typeof form?.image === "string"
                              ? Constants.BASE_URL + `${form.image}`
                              : typeof form?.image === "object" &&
                                form.image !== null
                              ? window.URL.createObjectURL(form?.image)
                              : EmployeIcon
                          }
                          alt=""
                          width={120}
                          height={120}
                          className=""
                          style={{ borderRadius: "4.5rem" }}
                        ></img>
                      </div>
                      <div className="upload_image mb-4">
                        <label
                          htmlFor="profile-img"
                          className="bg-white text-main border-1 border-main cursor-pointer w-3 px-4 text-sm font-semibold p-2 border-round-lg"
                        >
                          <span>CHANGE PHOTO</span>
                        </label>
                        <input
                          type="file"
                          mode="basic"
                          hidden
                          className="file-upload text-white border-round-lg"
                          id="profile-img"
                          chooseLabel="CHANGE PHOTO"
                          name="profile-img"
                          accept="image/*"
                          maxFileSize={1000000}
                          onChange={(e) => {
                            setForm(
                              { ...form, image: e.target.files[0] },
                              setShowButton(true)
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12 md:col-6">
                      <CustomInput
                        label="First name"
                        placeholder="Md Ilias"
                        name="firstName"
                        extraClassName="w-full "
                        value={form?.firstName}
                        onChange={handleChange}
                        errorMessage={form?.formErrors?.firstName}
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <CustomInput
                        label="Last name"
                        placeholder="Miah"
                        name="lastName"
                        extraClassName="w-full "
                        value={form?.lastName}
                        onChange={handleChange}
                        errorMessage={form?.formErrors?.lastName}
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <CustomInput
                        label="Email"
                        placeholder="iliasmiah000@gmail.com"
                        name="email"
                        extraClassName="w-full"
                        value={form?.email}
                        onChange={handleChange}
                        errorMessage={form?.formErrors?.email}
                        disabled
                      />
                    </div>
                    <div className="col-12 md:col-6 ">
                      <CustomPhoneInput
                        col="12"
                        errorMessage={form?.formErrors?.mobile}
                        extraClassName="contact "
                        label="Phone"
                        placeholder="0483 91 38 38"
                        name="mobile"
                        setCode={setCode}
                        code={code}
                        form={form}
                        value={form?.mobile}
                        onChange={handleChange}
                        setShowButton={setShowButton}
                        disabled
                      />
                    </div>

                    <div className="col-12 md:col-6">
                      <PlacesAutocomplete
                        value={form.suburb}
                        onChange={handlechangeAddress}
                        onSelect={handleSelectAddress}
                        searchOptions={{
                          componentRestrictions: { country: ["au"] },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <>
                            <CustomInput
                              extraClassName="w-full"
                              label="Full Address"
                              required
                              errorMessage={form?.formErrors?.suburb}
                              value={form?.suburb}
                              {...getInputProps({
                                placeholder: "Street address",
                                // className: "location-search-input w-full p-3"
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                                  : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div
                    className={
                      showButton === false
                        ? "hidden grid p-0 m-0 justify-content-end "
                        : "block grid p-0 m-0 justify-content-end flex"
                    }
                  >
                    <div
                      className="col-12 md:col-3"
                      onClick={() => handleCancel()}
                    >
                      <ButtonOutlined
                        label="CANCEL"
                        className="w-full"
                      ></ButtonOutlined>
                    </div>
                    <div
                      className="col-12 md:col-3"
                      onClick={() => handleSubmit()}
                    >
                      <ButtonComponent
                        label="UPDATE PROFILE"
                        className="text-light-cyan"
                      ></ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <main>{Children}</main> */}
          </ContainerBox>
        </div>
      </>
      <div className="update-profile-modal relative">
        <Dialog
          visible={visible}
          style={{ width: "1000vw" }}
          onHide={() => setVisible(false)}
          className="update-profile-modal lg:w-5 md:w-10 w-11 "
        >
          <div className="m-0 text-center mb-8">
            <img
              src={CheckmarkIcon}
              width={64}
              height={64}
              className=""
              alt=""
            />
            <p className="text-4xl text-dark font-semibold">Profile Updated</p>
          </div>
          <div className="drwaing p-0 m-0 ">
            <h1 className="opacity-0">drawing</h1>
          </div>
        </Dialog>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {!isAuthenticated() ? (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                ) : (
                  ""
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Profile;
