import React from "react";
import Topbar from "./topbar";
import Footer from "./footer";

export const Index = ({ children, SetSelectProfile, selectProfile}) => {
  return (
    <div>
      <Topbar  SetSelectProfile={SetSelectProfile} selectProfile={selectProfile}/>
      {children}
      <Footer />
    </div>
  );
};