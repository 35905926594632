import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../../../components/buttons/button";
import { ContainerBox } from "../../../shared/ContainerBox";
import { ProfileCard } from "../../../components/cards/ProfileCard.jsx";
import {
  createBuyerAction,
  verifyOTP,
} from "../../../redux/actions/buyerAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { editPostAction } from "../../../redux/actions/postAction";
import { Dialog } from "primereact/dialog";
import OtpInput from "react-otp-input";
import { CustomPhoneInput } from "../../../components/input/AllInput";
import CustomDialog from "../../../components/dialog/CustomDialog.jsx";
import { PickProfile } from "../../home/PickProfile.jsx";
import { mobileSendOtp } from "../../../redux/actions/buyerAction";
import { isAuthenticated } from "../../../services/authService.js";
export default function ProfilePreview({
  data,
  setPage,
  page,
  SetSelectProfile,
  selectProfile,
  emailCheck,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [code, setCode] = useState("+61");
  const [error, setError] = useState({
    otp: "",
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [counter, setCounter] = useState();
  const [form, setForm] = useState([{ ...data }]);
  const [otp, setOtp] = useState("");
  const [visible, setVisible] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const handleSendOtpRequest = () => {
    const payload = {
      countryCode: data?.countryCode,
      mobile: data?.mobile,
    };
    dispatch(mobileSendOtp(payload)).then((res) => {
      if (res.success === true) {
        setVisible(false);
        setOtpVerification(true);
      }
    });
  };
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSendOtpRequest();
  };
  useEffect(() => {
    if (otpVerification === true) {
      setCounter(60);
    }
  }, [otpVerification]);

  const getNumericValue = (value) => {
    return value.replace(/\D/g, "");
  };
  const hadleChangeOtp = (e) => {
    const numericValue = getNumericValue(e);

    setOtp(numericValue);
    setError({
      ...error,
      otp: null,
    });
  };
  const handleVerifyOtp = () => {
    if (otp === "" || otp === undefined) {
      setError({
        ...error,
        otp: otp === "" || otp === undefined ? "Please Enter OTP" : error.otp,
      });
      return false;
    } else {
      let payload = {
        otpCode: otp,
        countryCode: data?.countryCode,
        mobile: data?.mobile,
      };
      dispatch(verifyOTP(payload)).then((res) => {
        if (res.success === true) {
          let propertyTypeArray = [];
          let outdoorFeaturesArray = [];
          let indoorFeaturesArray = [];
          let climateControlEnergyArray = [];
          data?.propertyType?.map((item) => {
            propertyTypeArray.push(item._id);
          });
          data?.outdoorFeatures?.map((item) => {
            outdoorFeaturesArray.push(item._id);
          });
          data?.indoorFeatures?.map((item) => {
            indoorFeaturesArray.push(item._id);
          });
          data?.climateControlEnergy?.map((item) => {
            climateControlEnergyArray.push(item._id);
          });
          let obj = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            countryCode: data?.countryCode,
            mobile: data?.mobile,
            suburb: data?.suburb,
            // streetAddress: data?.streetAddress,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            typeOfPurchase: data?.typeOfPurchase,
            establishedProperty: data?.establishedProperty,
            propertyType: propertyTypeArray,
            priceRange: data.priceRange,
            bedrooms:
              data?.bedrooms === "any"
                ? null
                : typeof data.bedrooms === "number"
                ? data.bedrooms
                : Number(data.bedrooms?.slice(0, 1)),
            bathrooms:
              data?.bathrooms === "any"
                ? null
                : typeof data.bathrooms === "number"
                ? data.bathrooms
                : Number(data.bathrooms?.slice(0, 1)),
            carSpaces:
              data?.carSpaces === "any"
                ? null
                : typeof data.carSpaces == "number"
                ? data.carSpaces
                : Number(data.carSpaces?.slice(0, 1)),
            purchaseTimeFrame: data?.purchaseTimeFrame,
            landSize: data?.landSize,
            address: data?.address,
            purchaseMethod: data?.purchaseMethod,
            outdoorFeatures: outdoorFeaturesArray,
            indoorFeatures: indoorFeaturesArray,
            climateControlEnergy: climateControlEnergyArray,
            note: data?.note,
            financeDetails:
              data?.purchaseMethod === "OWNFUNDS"
                ? { isArranged: false, depositAmount: "", preApproved: "" }
                : data?.financeDetails,
            postType: data?.postType,
            propertyMustHave: data?.propertyMustHave,
            password: data?.password,
          };
          if (location.pathname === "/buyer-form") {
            dispatch(createBuyerAction(obj, () => setPage(4)));
          } else {
            dispatch(editPostAction(obj, id, () => setPage(4)));
          }
          setOtpVerification(false);
        } else {
          setOtp("");
        }
      });
    }
  };

  const handleSubmit = () => {
    let propertyTypeArray = [];
    let outdoorFeaturesArray = [];
    let indoorFeaturesArray = [];
    let climateControlEnergyArray = [];
    data?.propertyType?.map((item) => {
      propertyTypeArray.push(item._id);
    });
    data?.outdoorFeatures?.map((item) => {
      outdoorFeaturesArray.push(item._id);
    });
    data?.indoorFeatures?.map((item) => {
      indoorFeaturesArray.push(item._id);
    });
    data?.climateControlEnergy?.map((item) => {
      climateControlEnergyArray.push(item._id);
    });
    let obj = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      countryCode: data?.countryCode,
      mobile: data?.mobile,
      suburb: data?.suburb,
      // streetAddress: data?.streetAddress,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      typeOfPurchase: data?.typeOfPurchase,
      establishedProperty: data?.establishedProperty,
      propertyType: propertyTypeArray,
      priceRange: data.priceRange,
      bedrooms:
        data?.bedrooms === "any"
          ? null
          : typeof data.bedrooms === "number"
          ? data.bedrooms
          : Number(data.bedrooms?.slice(0, 1)),
      bathrooms:
        data?.bathrooms === "any"
          ? null
          : typeof data.bathrooms === "number"
          ? data.bathrooms
          : Number(data.bathrooms?.slice(0, 1)),
      carSpaces:
        data?.carSpaces === "any"
          ? null
          : typeof data.carSpaces == "number"
          ? data.carSpaces
          : Number(data.carSpaces?.slice(0, 1)),
      purchaseTimeFrame: data?.purchaseTimeFrame,
      landSize: data?.landSize,
      address: data?.address,
      purchaseMethod: data?.purchaseMethod,
      outdoorFeatures: outdoorFeaturesArray,
      indoorFeatures: indoorFeaturesArray,
      climateControlEnergy: climateControlEnergyArray,
      note: data?.note,
      financeDetails:
        data?.purchaseMethod === "OWNFUNDS"
          ? { isArranged: false, depositAmount: "", preApproved: "" }
          : data?.financeDetails,
      postType: data?.postType,
      propertyMustHave: data?.propertyMustHave,
      password: data?.password,
    };
    if (!isAuthenticated()) {
      setVisible(true);
    } else if (location.pathname === "/buyer-form") {
      dispatch(createBuyerAction(obj, () => setPage(4)));
    } else {
      dispatch(editPostAction(obj, id, () => setPage(4)));
    }
  };
  useEffect(() => {
    let array = [{ ...data }];
    setForm(array);
  }, []);
  return (
    <>
      <>
        <div className="preview-profile-container bg-green-light pt-8 mt-4">
          <div className=" py-5 bg-green-light ">
            <ContainerBox>
              <div className="grid p-0 m-0 ">
                <div className="col-12 md:col-12">
                  <h1
                    className="font-semibold text-dark heading_text p-0 m-0 mb-3 "
                    style={{ fontSize: "56px" }}
                  >
                    Brief Preview
                  </h1>
                </div>
                {form?.map((item, index) => {
                  return (
                    <>
                      {item.postType === "BUYER" ? (
                        item.address?.map((items) => {
                          return (
                            <ProfileCard
                              cardclass="all-profile-card"
                              size="col-12 md:col-6 lg:col-4 mb-5 "
                              item={item}
                              index={index}
                              items={items}
                              setPage={setPage}
                              priceData={true}
                              button={true}
                            />
                          );
                        })
                      ) : (
                        <ProfileCard
                          cardclass="all-profile-card"
                          item={item}
                          index={index}
                          size="col-12 md:col-6 lg:col-4 mb-5 "
                          setPage={setPage}
                          priceData={true}
                          button={true}
                        />
                      )}
                    </>
                  );
                })}
              </div>

              <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                <div className="col-12 md:col-3 md:text-left lg:col-2 ">
                  <div className="" onClick={gotoBtn}>
                    <div className="no-underline">
                      <ButtonComponent
                        label="BACK TO EDIT"
                        className="bg-transparent border-none text-black"
                        onClick={() => setPage(1)}
                      ></ButtonComponent>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 md:col-4 lg:col-3 lg:w-2"
                  onClick={gotoBtn}
                >
                  <div className="">
                    <div className="no-underline">
                      <ButtonComponent
                        onClick={() => handleSubmit()}
                        label="SUBMIT BRIEF"
                        className="bg-main text-light-cyan  border-0 "
                        style={{ color: "rgba(143, 154, 153, 1)" }}
                      ></ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </ContainerBox>
          </div>
        </div>
      </>

      <div className="bookProfile-otp">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium p-0 m-0">
                We will send you an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on this number
              </p>
            </div>
            <div className="grid p-0 m-0">
              <div className="col-12 md:col-12 p-0 m-0">
                <p className="text-lg font-semibold text-dark ml-1">
                  Enter your mobile number
                </p>
                <CustomPhoneInput
                  code={data?.countryCode}
                  setCode={setCode}
                  col={12}
                  value={data?.mobile}
                  disabled
                />
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0 ">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning  text-sm font-semibold lg:w-full md:w-full w-10 "
                  onClick={() => setVisible(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SEND CODE"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={() => {
                      handleSendOtpRequest();
                    }}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
        <Dialog
          visible={otpVerification}
          style={{ width: "100vw" }}
          onHide={() => setOtpVerification(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium ">
                We have sent an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on your number
              </p>
            </div>
            <div className="grid p-0 m-0 ">
              <div className="col-12 md:col-12 flex justify-content-center p-0 m-0">
                <div className="">
                  <OtpInput
                    value={otp}
                    onChange={(e) => hadleChangeOtp(e)}
                    numInputs={4}
                    inputStyle={{
                      width: "48px",
                      height: "48px",
                      border: "1px solid #8F9A99",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      margin: "10px",
                    }}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {error?.otp && (
                    <small className="p-error text-sm ml-2 mt-2">
                      {error?.otp}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-12 text-center  mb-4 mt-2 ">
                <p className="text-lg font-medium text-black text-center p-0 m-0">
                  {counter !== 0 ? (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <span className="text-main font-semibold text-lg">
                        {counter}
                      </span>
                      <span className="text-main font-semibold text-lg">s</span>
                    </>
                  ) : (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <cod
                        className="text-main font-semibold text-lg cursor-pointer"
                        onClick={() => resendOtp()}
                      >
                        Resend code
                      </cod>
                    </>
                  )}
                </p>
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning lg:w-full md:w-full w-10 text-sm font-semibold "
                  onClick={() => setOtpVerification(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SUBMIT"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={handleVerifyOtp}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {isAuthenticated() ? null : (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
