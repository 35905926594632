import { types } from "../types";

const initialState = {
    generaInfo: {},
    createBuyerProfile: [],
    enquieryMessageProfile: [],
    mobileSendOtpProfile: {},
    verifyOtpProfile: [],
    newsLetterProfile: []
};

export const buyerGeneralInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GENERAL_PROPERTY_INFO:
            return {
                ...state,
                generaInfo: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const createBuyerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_BUYER_FORM:
            return {
                ...state,
                createBuyerProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const enquieryMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ENQUIERY_MESSAGE:
            return {
                ...state,
                enquieryMessageProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const mobileSendOtpReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_OTP:
            return {
                ...state,
                mobileSendOtpProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const verifyOtpReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VERIFY_OTP:
            return {
                ...state,
                verifyOtpProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const newsLetterReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_NEWS_LETTER:
            return {
                ...state,
                newsLetterProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}