import PlatformIcon from "../../assets/icons/platform.png";
import ConnectionIcon from "../../assets/icons/connection.png";
import SearchIcon from "../../assets/icons/search.png";
import NetworkIcon from "../../assets/icons/network.png";
import BuildingImg from "../../assets/images/seller.png";
import WaterMarkImg from "../../assets/images/buyer_Frame.png";
import WaterMarkImg1 from "../../assets/images/renter_frame.png";
import WaterMarkLogo from "../../assets/images/Logo.png";
import TrustedLogo from "../../assets/images/trusted-Logo.png";
import TrustedLogo1 from "../../assets/images/Logo (1).png";
import TrustedLogo2 from "../../assets/images/Logo (2).png";
import TrustedLogo3 from "../../assets/images/Logo (3).png";
import TrustedLogo4 from "../../assets/images/Logo (4).png";
import TrustedLogo5 from "../../assets/images/Logo (5).png";
import ClientCircleImg from "../../assets/images/client circle frame.png";
import Morgan_S_img from "../../assets/images/morgan s circle img.png";
import Mark_P_img from "../../assets/images/mark p circle img.png";
import { useDispatch, useSelector } from "react-redux";
import { allPostAction } from "../../redux/actions/postAction";
import React, { useEffect, useState } from "react";
import BuyerIcon from "../../assets/icons/user.png";
import SellerICon from "../../assets/icons/seller.png";
import RenterIcon from "../../assets/icons/renter.png";
import { ButtonOutlined } from "../../components/buttons/button";
import { allValidations } from "../../utils/formValidation";
import { createContactAction } from "../../redux/actions/profileAction";

const HomeContent = () => {
  const MoreInfo = () => {
    <ButtonOutlined />;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allPostAction({}));
  }, []);

  const allPostData = useSelector((state) => state.allPost.allPostProfile);

  const WhatWeDoContent = [
    {
      img: PlatformIcon,
      heading: "Buyers Platform",
      paragraph:
        "We change the way Buyers have access to property by bringing the Sellers to them. We also keep all Buyer details private and manage the inquiries for them, making our platform safe.",
    },
    {
      img: ConnectionIcon,
      heading: "Real Estate Connections",
      paragraph:
        "We aren’t just a platform for Buyers to connect with Sellers. We can also assist with all your property needs, including Selling, Renting and Buying. Creating the ultimate Real estate connections.",
    },
    {
      img: SearchIcon,
      heading: "Property Search",
      paragraph:
        "We make the search process easy by matching your Buyer profile to the Sellers property features. We even look beyond just basic property features by wanting to make sure properties also suit your unique lifestyle. Creating the ultimate property connections.",
    },
    {
      img: NetworkIcon,
      heading: "Professional Networks",
      paragraph:
        "With an extensive network of trusted Professionals, including Buyer's Agents, Real Estate Agents, Accountants, Conveyancers, Valuers and more, your property journey can be made easy.",
    },
  ];
  const BuyerCard = [
    {
      link: "/buyer-details",
      heading: "BUYER",
      headingbg: "bg-blue-cyan",
      location: "North strathfield 2137",
      bedavailable: "3",
      bathroomavilable: "2",
      parking: "2",
      property: "House",
      amout: "$2.0M - $2.2M",
      months: "0 - 3 months",
      approval: "Finance Approved",
      backyard: "Close to station Big backyard",
      watermark: WaterMarkImg,
      watermarklogo: WaterMarkLogo,
      value: "Buyer",
    },
  ];
  const SellerCard = [
    {
      link: "/seller-details",
      heading: "SELLER",
      headingbg: "bg-purple-cyan",
      propertyimg: BuildingImg,
      location: "Concord 2137",
      amout: "$2.2 M",
      bedavailable: "3",
      bathroomavilable: "2",
      parking: "2",
      property: "House",
      months: "0 - 3 months",
      approval: "Finance Approved",
      backyard: "Close to station Big backyard",

      // watermark: WaterMarkImg,
      watermarklogo: WaterMarkLogo,
      value: "seller",
    },
  ];
  const RenterCard = [
    {
      link: "/renter-details",
      heading: "RENTER",
      headingbg: "bg-green-cyan",
      location: "North strathfield 2137",
      bedavailable: "3",
      bathroomavilable: "1",
      parking: "1",
      property: "Unit",
      amout: "$600 p/w",
      months: "0 - 3 months",
      renterhistory: "Rental History",
      backyard: "Walk to public transport Close to shops",
      watermark: WaterMarkImg1,
      watermarklogo: WaterMarkLogo,
      value: "renter",
    },
  ];

  const trustedpartners = [
    {
      img: TrustedLogo,
    },
    {
      img: TrustedLogo1,
    },
    {
      img: TrustedLogo2,
    },
    {
      img: TrustedLogo3,
    },
    {
      img: TrustedLogo4,
    },
    {
      img: TrustedLogo5,
    },
  ];

  const happyClient = [
    {
      clientimg: ClientCircleImg,
      clientname: "Luke F",
      company: "VIC",
      post: "1 Days ago",
      review:
        "I can’t say enough to express my positive experience with The Buyers Place.",
    },
    {
      clientimg: Morgan_S_img,
      clientname: "MorganS",
      company: "NSW",
      post: "1 week ago",
      review:
        "We needed assistance purchasing our family home. The Buyers Place made it easy finding a home and within budget!",
    },
    {
      clientimg: Mark_P_img,
      clientname: "Mark P",
      company: "Sydney, NSW",
      post: "1 week ago",
      review:
        "The Buyers Place really helped me focus on the location and property attributes I needed in my investment.",
    },
  ];

  const memberlogo = [
    {
      img: TrustedLogo,
    },
    {
      img: TrustedLogo1,
    },
    {
      img: TrustedLogo2,
    },
    {
      img: TrustedLogo3,
    },
    {
      img: TrustedLogo4,
    },
    {
      img: TrustedLogo5,
    },
  ];

  const chooseprofile = [
    {
      link: "/buyer-form",
      img: BuyerIcon,
      heading: "I am a Buyer ",
    },
    {
      link: "/seller-form",
      img: SellerICon,
      heading: " I am a  Seller ",
    },
    {
      link: "/renter-form",
      img: RenterIcon,
      heading: "I am a Renter",
    },
  ];

  const modalprofile = [
    {
      id: "1",
      img: BuyerIcon,
      heading: "I am a Buyer",
      name: "BUYER",
    },
    {
      id: "2",
      img: SellerICon,
      heading: "I am a Seller",
      name: "SELLER",
    },
    {
      id: "3",
      img: RenterIcon,
      heading: "I am a Renter",
      name: "RENTER",
    },
  ];
  const [code, setCode] = useState("+61");
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    country: "",
    mobile: "",
    message: "",
  });
  const handleChangeData = (e) => {
    const { name, value } = e?.target;
    const formErrors = allValidations(name, value, form);
    setForm({
      ...form,
      [name]: value,
      formErrors,
    });
  };
  useEffect(() => {
    if (code) {
      setForm({
        ...form,
        country: code,
      });
    } else {
      setForm({
        ...form,
        country: "+61",
      });
    }
  }, [code, form.mobile]);
  const contactError = () => {
    let formErrors = {};
    if (form?.fullName === "") {
      formErrors.fullName = "Please Enter FullName";
    }
    if (form?.email === "") {
      formErrors.email = "Please Enter Email";
    }
    if (form?.mobile === "") {
      formErrors.mobile = "Please Enter Mobile";
    }
    else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only"
    }
    else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only"
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (!contactError()) {
      return false;
    } else {
      dispatch(createContactAction(form)).then((res) => {
        if (res.success === true) {
          setForm({
            fullName: "",
            email: "",
            country: "",
            mobile: "",
            message: "",
          });
        }
      });
    }

  };
  return {
    WhatWeDoContent,
    trustedpartners,
    happyClient,
    memberlogo,
    chooseprofile,
    BuyerCard,
    SellerCard,
    RenterCard,
    modalprofile,
    allPostData,
    handleChangeData,
    form,
    setForm,
    code,
    setCode,
    handleSubmit,
  };
};
export default HomeContent;
