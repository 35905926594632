import React, { useState, useEffect } from "react";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import Siderbar from "../../components/sidebar/Siderbar";
import { ProfileCard } from "../../components/cards/ProfileCard";
import HomeContent from "../home/HomeContent";
import { myProfileAction } from "../../redux/actions/profileAction";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import { deletePostAction } from "../../redux/actions/postAction";
import ButtonComponent from "../../components/buttons/button";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/authService";
const Post = ({ SetSelectProfile, selectProfile }) => {
  const { PropertyCard } = HomeContent();
  const [name, setName] = useState("");
  const mainRef = useRef();
  const [profileData, setProfileData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(myProfileAction());
  }, []);
  const myProfileData = useSelector((state) => state.myProfileData.myProfile);
  const handleDelete = (id) => {
    dispatch(deletePostAction(id)).then(() => {
      dispatch(myProfileAction());
    });
  };
  const handleChange = (e) => {
    setName(e.target.value);
    let array = [];
    let filterData = null;
    myProfileData?.posts?.map((item) => {
      filterData = item?.address?.find((items) => {
        return items?.streetAddress
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toLowerCase());
      });
      if (filterData) {
        array?.push(item);
      }
    });
    setProfileData(array);
  };

  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();

  return (
    <>
      <>
        <div ref={mainRef} className="user-profile-container pt-8 mt-4">
          <ContainerBox>
            <div className="grid sidebar-container lg:py-6 md:py-6 p-0 m-0  flex justify-content-between ">
              <div className="col-12 md:col-4 lg:col-3 sidebar border-round-lg">
                <Siderbar />
              </div>
              <div className="col-12 md:col-8 lg:col-9 all-post-card-container">
                <div className="lg:ml-5">
                  <div className="grid p-0 m-0 flex ">
                    <div className="col-12 md:col-6 lg:col-9">
                      <h2 className="text-dark font-semibold text-4xl p-0 m-0 mb-3">
                        My Briefs
                      </h2>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 lg:text-right md:text-right mb-3 ">
                      <div
                        className="search-box px-3  flex align-items-center  border-1 p-2 border-round-xl  "
                        style={{ borderColor: "rgba(204, 209, 209, 1)" }}
                      >
                        <span>
                          <i
                            className="pi pi-search  mt-1"
                            style={{ color: "rgba(143, 154, 153, 1)" }}
                          />
                        </span>
                        <input
                          type="search"
                          placeholder="Search here"
                          className="text-base px-2 font-medium Raleway border-none w-full "
                          onChange={(e) => handleChange(e)}
                          value={name}
                        />
                      </div>
                    </div>
                    <div className="grid w-full p-0 m-0">
                      {profileData !== null && name?.length > 0 ? (
                        profileData?.length > 0 ? (
                          profileData?.map((item, index) => {
                            return (
                              <>
                                {item.postType === "BUYER" ||
                                item.postType === "RENTER" ? (
                                  item.address?.map((items) => {
                                    return (
                                      <ProfileCard
                                        cardclass="all-profile-card"
                                        className="w-full"
                                        size=" col-12 md:col-6 mb-4"
                                        item={item}
                                        index={index}
                                        items={items}
                                        card={true}
                                        handleDelete={handleDelete}
                                      />
                                    );
                                  })
                                ) : (
                                  <ProfileCard
                                    cardclass="all-profile-card"
                                    className="w-full"
                                    item={item}
                                    index={index}
                                    size=" col-12 md:col-6 mb-4"
                                    card={true}
                                    handleDelete={handleDelete}
                                  />
                                )}
                              </>
                            );
                          })
                        ) : (
                          <h3>No Data Found</h3>
                        )
                      ) : (
                        <>
                          {myProfileData?.posts?.length > 0 ? (
                            myProfileData?.posts?.map((item, index) => {
                              return (
                                <>
                                  {item.postType === "BUYER" ||
                                  item.postType === "RENTER" ? (
                                    item.address?.map((items) => {
                                      return (
                                        <ProfileCard
                                          cardclass="all-profile-card"
                                          className="w-full"
                                          size=" col-12 md:col-6 mb-4"
                                          item={item}
                                          index={index}
                                          items={items}
                                          card={true}
                                          handleDelete={handleDelete}
                                        />
                                      );
                                    })
                                  ) : (
                                    <ProfileCard
                                      cardclass="all-profile-card"
                                      className="w-full"
                                      item={item}
                                      index={index}
                                      size=" col-12 md:col-6 mb-4"
                                      card={true}
                                      handleDelete={handleDelete}
                                    />
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <h3>No Data Found</h3>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <main>{Children}</main> */}
          </ContainerBox>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {!isAuthenticated() ? (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                ) : (
                  ""
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Post;
