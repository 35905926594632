import { types } from "../types";

const initialState = {
    allPostProfile: [],
    postDetailProfile: [],
    postSavedUnSavedProfile: [],
    deletePostProfile: {},
    editPostProfile: {},
    viewPostProfile: {}
}

export const allPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ALL_POST:
            return {
                ...state,
                allPostProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const postDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_DETAIL:
            return {
                ...state,
                postDetailProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const postSavedUnsavedReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVED_UNSAVED_POST:
            return {
                ...state,
                postSavedUnSavedProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const deletePostReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_POST:
            return {
                ...state,
                deletePostProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const editProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_POST:
            return {
                ...state,
                editPostProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const viewProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.VIEW_POST:
            return {
                ...state,
                viewPostProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}