export default class Constants {
  static BASE_URL = "https://api.thebuyersplace.com.au/";
  static END_POINT = {
    SIGNUP: "api/user/signup",
    LOGIN: "api/user/login",
    FORGETPASSWORD: "api/user/forgot-password",
    RESETPASSWORD: "api/user/change-forgot-password",
    SOCIALLOGIN: "api/user/social-signin",
    GERENALPROPERTYINFO: "api/user/gernal-propety-info",
    CREATEBUYERFORM: "api/user/create-post",
    UPLOADFILE: "api/user/upload-files",
    AllPOST: "api/user/posts",
    POSTDETAIL: "api/user/post-detail",
    ENQUIERYMESSAGE: "api/user/create-inquiry",
    SAVEDUNSAVEDPOST: "api/user/saved-unsaved-porperty",
    MYPROFILE: "api/user/my-profile",
    UPDATEPROFILE: "api/user/update-profile",
    SINGLEUPLOADFILE: "api/user/upload-file",
    CHANGEPASSWORD: "api/user/change-password",
    DELETEPOST: "api/user/delete-post",
    UPDATEPOST: "api/user/update-post",
    VIEWPOST: "api/user/post-detail",
    CREATECONTACT: "api/user/create-contact",
    TERMCONDITION: "api/user/user-term-and-condition",
    PRIVACYPOLICY: "api/user/user-privacy-policy",
    EMAILCHECK: "api/user/check-email",
    REQUESTOTP: "api/user/request-otp",
    SENDOTP: "api/user/verify-otp",
    SOCIALLOGIN: "api/user/social-signin",
    GETSAVEDPOST: "api/user/saved-property",
    CREATENEWSLETTER: "api/user/create-news-letter",
  };
}
