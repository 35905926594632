export const types = {
    SING_UP: "SING_UP",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_TOAST: "SHOW_TOAST",
    SIGN_IN: "SIGN_IN",
    FORGET_PASSWORD: "FORGET_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    SOCIAL_LOGIN: "SOCIAL_LOGIN",
    GENERAL_PROPERTY_INFO: "GENERAL_PROPERTY_INFO",
    CREATE_BUYER_FORM: "CREATE_BUYER_FORM",
    UPLOAD_FILE: "UPLOAD_FILE",
    ALL_POST: "ALL_POST",
    POST_DETAIL: "POST_DETAIL",
    ENQUIERY_MESSAGE: "ENQUIERY_MESSAGE",
    SAVED_UNSAVED_POST: "SAVED_UNSAVED_POST",
    MY_PROFILE: "MY_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    SINGLE_UPLOAD_FILE: "SINGLE_UPLOAD_FILE",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    DELETE_POST: "DELETE_POST",
    UPDATE_POST: "UPDATE_POST",
    VIEW_POST: "VIEW_POST",
    CREATE_CONTACT: "CREATE_CONTACT",
    TERM_CONDITION: "TERM_CONDITION",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    EMAIL_CHECK: "EMAIL_CHECK",
    REQUEST_OTP: "REQUEST_OTP",
    VERIFY_OTP: "SEND_OTP",
    CHANGE_POST_STATUS: "CHANGE_POST_STATUS",
    CREATE_NEWS_LETTER: "CREATE_NEWS_LETTER"
}