import React from "react";
import CheckMarkIcon from "../../../assets/icons/checkmark-circle.png";
import { Index } from "../../../layout";
import { ContainerBox } from "../../../shared/ContainerBox";
import { ButtonOutlined } from "../../../components/buttons/button";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { useNavigate } from "react-router-dom";

export default function RenterSuccessfullProfile({
  SetSelectProfile,
  selectProfile,
}) {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  return (
    <>
      <>
        <div className="renter-successfull-message">
          <div className="bg-light-gray py-8 mt-4">
            <ContainerBox>
              <div
                className="form-card grid mt-6 p-0 m-0 flex justify-content-center lg:p-5 md:p-3 p-2 ml-2 mr-2 border-1"
                style={{
                  borderColor: "rgba(204, 209, 209, 1)",
                  borderRadius: "20px",
                  backgroundColor: "rgba(250, 255, 255, 1)",
                }}
              >
                <div className="col-12 md:col-12 lg:col-12 text-center mb-5 ">
                  <img src={CheckMarkIcon} alt="" height={64} width={64} />
                  <h1
                    className=" buyer-form-successful-heading-text text-dark font-semibold "
                    style={{ fontSize: "32px" }}
                  >
                    Your Renter Brief has been submitted successfully.
                  </h1>
                  <p className="text-lg font-medium text-black line-height-3">
                    In order to make the Brief Live, please book a call to get
                    your Brief Verified.
                  </p>
                </div>
                <div className="col-12 md:col-12 lg:col-2 lg:mb-5 md:mb-3 text-center">
                  <ButtonOutlined
                    label="BOOK A CALL"
                    onClick={() =>
                      window.open(
                        "https://link.agentmate.io/widget/booking/OYtRamHgthLvrPDfufaz"
                      )
                    }
                  ></ButtonOutlined>
                </div>
              </div>
            </ContainerBox>
          </div>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
