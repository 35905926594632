import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { authenticate, isAuthenticated } from "../../services/authService";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { forgetApi } from "../../services/forgetApi";
import axios from "axios";
export const signupAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.SIGNUP, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SING_UP,
        payload: res.data,
      });
      authenticate(res.data.token, () => {
        if (res.success) {
          localStorage.setItem("name", res?.data?.firstName);
        }
      });
    }
    next();
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    if (res.message.includes("E11000")) {
      dispatch(hideLoaderAction());
      dispatch(
        showToast({ severity: "error", summary: "Email allready Exist" })
      );
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  }
};

export const signInAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.LOGIN, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SIGN_IN,
        payload: res.data,
      });
      authenticate(res.data.token, () => {
        if (isAuthenticated()) {
          localStorage.setItem("name", res.data.firstName);
          localStorage.setItem("realState", JSON.stringify(res.data.token));
        }
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

//testing
export const forgetPasswordAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.FORGETPASSWORD, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.FORGET_PASSWORD,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const resetPasswordAction = (data, token, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await forgetApi(
    "post",
    Constants.END_POINT.RESETPASSWORD,
    token,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.RESET_PASSWORD,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const emailCheckAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.EMAILCHECK, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.EMAIL_CHECK,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    return res;

    // dispatch(showToast({ severity: "error", summary: res.message }))
  }
};

export const googlelogin = (respose) => async (disaptch) => {
  try {
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${respose.access_token}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const socialLogin = (data) => async (dispatch) => {
  let value = "";
  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.SOCIALLOGIN, { ...data });
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SIGN_IN,
        payload: res.data,
      });

      authenticate(res.data.token, () => {
        if (isAuthenticated()) {
          window.localStorage.setItem("name", res.data.firstName);
          dispatch(hideLoaderAction());
          dispatch(showToast({ severity: "success", summary: res.message }));
          value = true;
        }
        if (!isAuthenticated) {
          dispatch(hideLoaderAction());
          value = false;
        }
      });
    }

    return value;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
