import React from "react";
import BorderCard from "../../components/cards/BorderCard";

export const ServiceCard = ({ src, heading, description,extraclass }) => {
  return (
    <div className={`border-card p-2 ${extraclass}`}>
      <BorderCard>
        <img src={src} alt="" width={64} height={64}></img>
        <h1 className="font-medium text-dark text-xl">{heading}</h1>
        <p className="font-medium text-black " style={{lineHeight:"1.5"}}>{description}</p>
      </BorderCard>
    </div>
  );
};
