import React, { useState } from "react";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import Siderbar from "../../components/sidebar/Siderbar";
import BorderCard from "../../components/cards/BorderCard";
import CheckmarkIon from "../../assets/icons/checkmark-circle-02.png";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { Dialog } from "primereact/dialog";
import CheckmarkSmallIcon from "../../assets/icons/Checkmark.png";
import {
  CustomInput,
  CustomRadioButtons,
  CustomTextArea,
} from "../../components/input/AllInput";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/authService";
const Subscription = ({ SetSelectProfile, selectProfile }) => {
  const [visible, setVisible] = useState(false);
  const [ingredient, setIngredient] = useState("");
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  const [comment, setComment] = useState(false);
  return (
    <>
      <div className="user-profile-container pt-8 mt-4">
        <ContainerBox>
          <div className="grid sidebar-container lg:py-6 md:py-6 p-0 m-0  flex justify-content-between ">
            <div className="col-12 md:col-4 lg:col-3 sidebar border-round-lg">
              <Siderbar />
            </div>
            <div className="col-12 md:col-8 lg:col-9">
              <div className="lg:ml-5">
                <div className="grid p-0 m-0  flex  justify-content-between">
                  <div className="col-12 md:col-5 lg:col-7">
                    <h2 className="subscription-plan-heading-text text-dark font-semibold text-4xl p-0 m-0 mb-3 ">
                      {" "}
                      My Subscription
                    </h2>
                  </div>
                  <div className="col-12 md:col-7 lg:col-5 lg:text-right md:text-right mb-3 hidden lg:block md:block ">
                    <ButtonComponent
                      label="CANCEL  SUBSCRIPTION"
                      className="bg-red-700 border-0 text-sm font-semibold w-8"
                      onClick={() => setVisible(true)}
                    ></ButtonComponent>
                  </div>
                </div>
                <div className="grid p-0 m-0">
                  <div className="col-12 md:col-6 lg:col-6 ">
                    <BorderCard>
                      <p className="p-0 m-0 text-center text-black font-medium text-xl mb-3">
                        Basic plan
                      </p>
                      <h2
                        className="text-center mb-6 font-bold p-0 m-0"
                        style={{ fontSize: "64px", color: "#000000" }}
                      >
                        Free
                      </h2>
                      <p className="flex align-items-center ">
                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                        <span
                          className="ml-3 text-base font-medium"
                          style={{ color: "#000000" }}
                        >
                          1 free profile post
                        </span>
                      </p>
                      <p className="flex align-items-center">
                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                        <span
                          className="ml-3  text-base font-medium"
                          style={{ color: "#000000" }}
                        >
                          All personal Details kept private{" "}
                        </span>
                      </p>
                      <p className="flex align-items-center">
                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                        <span
                          className="ml-3  text-base font-medium"
                          style={{ color: "#000000" }}
                        >
                          All Enquiries handled on behalf of you
                        </span>
                      </p>
                      <p className="flex align-items-center mb-5 ">
                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                        <span
                          className="ml-3  text-base font-medium"
                          style={{ color: "#000000" }}
                        >
                          Free profile matching
                        </span>
                      </p>
                      <ButtonOutlined
                        label="CURRENT PLAN"
                        className="mt-7 border-500 text-gray text-sm font-semibold"
                      ></ButtonOutlined>
                    </BorderCard>
                  </div>
                </div>
                {/* Mobile View  Subscription Button */}
                <div className="lg:hidden md:hidden mt-5 mb-5  ">
                  <ButtonComponent
                    label="CANCEL  SUBSCRIPTION"
                    className="bg-red-700 border-0 text-sm font-semibold w-8"
                    onClick={() => setVisible(true)}
                  ></ButtonComponent>
                </div>
              </div>
            </div>
          </div>
          {/* <main>{Children}</main> */}
          {selectProfile === true ? (
            <div className="landing-page-modal">
              <CustomDialog
                header={
                  <h2
                    className="mt-0 font-semibold text-dark mt-3 ml-3"
                    style={{ fontSize: "32px" }}
                  >
                    Pick a Profile to Create a Brief
                    {!isAuthenticated() ? (
                      <p
                        className=" text-black font-medium pick-login-here"
                        style={{ fontSize: "18px" }}
                      >
                        {" "}
                        Already have an account?
                        <cod
                          className="text-main font-semibold text-sm cursor-pointer"
                          onClick={() => {
                            navigate("/sign-in");
                            SetSelectProfile(false);
                            gotoBtn();
                          }}
                        >
                          {" "}
                          Login here
                        </cod>{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </h2>
                }
                visible={selectProfile}
                onHide={() => SetSelectProfile(false)}
              >
                <PickProfile
                  SetSelectProfile={SetSelectProfile}
                  selectProfile={selectProfile}
                />
              </CustomDialog>
            </div>
          ) : (
            ""
          )}
        </ContainerBox>
      </div>

      <div className="subscription-modal">
        <Dialog
          header="Cancel Subscription"
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="subscription-modal lg:w-5 md:w-10 w-11"
        >
          <div className="m-0">
            <h1
              className="cancel-subsription text-4xl font-semibold mb-5"
              style={{ color: "#231F20" }}
            >
              Reasons for leaving
            </h1>
            <CustomRadioButtons
              optionLabel="I successfully purchased a property"
              value="value1"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value1"}
            />
            <CustomRadioButtons
              optionLabel="I am no longer in the market to Buy a Property"
              value="value2"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value2"}
            />
            <CustomRadioButtons
              optionLabel="I didn't find what I want"
              value="value3"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value3"}
            />
            <CustomRadioButtons
              optionLabel="I wasn't able to secure a deal"
              value="value4"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value4"}
            />
            <CustomRadioButtons
              optionLabel="The Platform is too complicated"
              value="value5"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value5"}
            />
            <CustomRadioButtons
              optionLabel="I found a property using another Buyers Platform"
              value="value6"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "value6"}
            />
            {/* <ButtonComponent label="Add" onClick={() => setComment(true)} /> */}
            <div className="other mb-2 py-2" onClick={() => setComment(true)}>
              <i
                className="pi pi-plus border-1 text-center p-2 border-300 border-round-2xl"
                style={{ fontSize: "1rem" }}
              />
              <span className="text-base font-semibold text-black ml-2">
                Other
              </span>
            </div>
          </div>
          <div className="p-1">
            <hr className=""></hr>
          </div>

          <div className="btn flex justify-content-between p-3">
            <div className="button">
              <ButtonOutlined
                label="CANCEL "
                className="border-none text-black font-semibold text-sm"
              ></ButtonOutlined>
            </div>
            <div className="button">
              <ButtonOutlined
                label="CANCEL SUBSCRIPTION"
                className="text-sm font-semibold"
              ></ButtonOutlined>
            </div>
          </div>
        </Dialog>
        <Dialog
          header=""
          visible={comment}
          style={{ width: "100vw" }}
          className=" other-feedbaack lg:w-4 md:w-8 w-11"
          onHide={() => setComment(false)}
        >
          <CustomTextArea label="Leave Feedback" col={12} />
          <ButtonComponent label="Submit" onClick={() => setComment(false)} />
        </Dialog>
      </div>
    </>
  );
};
export default Subscription;
