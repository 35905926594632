import React from "react";
import CardComponent from "./card";
import LocationIcon from "../../assets/icons/loaction.png";
import BadIcon from "../../assets/icons/bad (1).png";
import TableLampIcon from "../../assets/icons/tablelamp.png";
import CarIcon from "../../assets/icons/car.png";
import ClockIcon from "../../assets/icons/clock.png";
import TagIcon from "../../assets/icons/tag.png";
import CheckMarkIcon from "../../assets/icons/checkmark-circle-01.png";
import dollarIcon from "../../assets/icons/coins-dollar.png";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import RenterMaskIcon from "../../assets/icons/Mask group (1).png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import WaterMarkImg from "../../assets/images/buyer_Frame.png";
import WaterMarkLogo from "../../assets/images/The Buyers place.png";
import moment from "moment";
import Constants from "../../services/constant";
import { useLocation } from "react-router-dom";
import WaterMarkImg1 from "../../assets/images/renter_frame.png";
//
export const ProfileCard = ({
  size,
  cardclass,
  staybuilding,
  PropertyImg,
  renterhistory,
  setPage,
  link,
  value,
  item,
  items,
  detailClass,
  card,
  priceData,
  handleDelete,
  button,
}) => {
  const location = useLocation();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (item.postType === "BUYER" && card === true) {
      navigate(`/buyer-details/${item._id}`);
    } else if (item.postType === "SELLER" && card === true) {
      navigate(`/seller-details/${item._id}`);
    } else if (item.postType === "RENTER" && card === true) {
      navigate(`/renter-details/${item._id}`);
    } else {
      setPage(2);
    }
  };
  const handleViewPost = () => {
    if (item.postType === "BUYER") {
      navigate(`/buyer-form/${item._id}`);
    } else if (item.postType === "SELLER") {
      navigate(`/seller-form/${item._id}`);
    } else if (item.postType === "RENTER") {
      navigate(`/renter-form/${item._id}`);
    }
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <div
      className={`${
        item?.postType === "BUYER" || item?.postType === "RENTER"
          ? "renter-box"
          : ""
      } ${size}`}
    >
      <p
        className={` buyer-card-heading p-0 m-0 p-3 text-gray-light text-center font-semibold text-sm  ${
          item?.postType === "BUYER"
            ? "bg-blue-cyan"
            : item?.postType === "SELLER"
            ? "bg-purple-cyan"
            : item?.postType === "RENTER"
            ? "bg-green-cyan"
            : ""
        }`}
      >
        {`${item?.postType} BRIEF`}
      </p>
      <CardComponent cardsize={` buyer-card w-full bg-light ${cardclass}`}>
        <div
          className={`flex flex-column justify-content-between h-full ${detailClass}`}
        >
          {item?.postType === "BUYER" ? (
            <>
              <img
                src={WaterMarkImg}
                alt=""
                className="w-6 absolute  watermark-img"
              />
              <img
                src={WaterMarkLogo}
                alt=""
                className="w-7 absolute  watermark-logo"
              />{" "}
            </>
          ) : item?.postType === "RENTER" ? (
            <>
              <img
                src={WaterMarkImg1}
                alt=""
                className="w-6 absolute  watermark-img"
              />
              <img
                src={WaterMarkLogo}
                alt=""
                className="w-7 absolute  watermark-logo"
              />
            </>
          ) : item?.postType === "SELLER" ? (
            <>
              <img
                src={WaterMarkLogo}
                alt=""
                className="w-7 absolute  watermark-logo"
              />
            </>
          ) : (
            ""
          )}
          <div className="BUYER_card_content relative h-full pb-3 ">
            {item?.postType === "SELLER" ? (
              <img
                src={
                  item.image[0]?.length > 0
                    ? Constants.BASE_URL + `${item?.image[0]}`
                    : null
                }
                alt=""
                className="w-full mt-3"
                style={{ height: "220px" }}
              />
            ) : (
              ""
            )}
            <p className="flex align-items-center p-0 m-0">
              <img src={LocationIcon} width={24} height={24} alt=""></img>
              <span className="ml-3 text-lg font-bold z-1 text-black">
                {item?.postType === "BUYER"
                  ? `${items?.streetAddress}`
                  : item?.postType === "SELLER"
                  ? item.address?.length > 0 &&
                    `${item?.address[0]?.city}, Australia  ${item?.address[0]?.zipCode}, ${item?.address[0]?.state}`
                  : item?.postType === "RENTER"
                  ? `${items?.streetAddress}`
                  : ""}
              </span>
            </p>
            {/* <div className={`flex flex-column ${PropertyImg ? "flex-column-reverse" : ""}`}> */}
            <p className="flex align-items-center z-1 p-0 m-0">
              <img
                src={BadIcon}
                width={24}
                height={24}
                className="z-1"
                alt=""
              ></img>
              <span className="text-lg font-bold ml-3 z-1 text-black">
                {item?.bedrooms !== null && priceData === true
                  ? item?.bedrooms
                  : item?.bedrooms === null
                  ? "any"
                  : `${item?.bedrooms}+`}
              </span>
              <img
                src={TableLampIcon}
                width={24}
                height={24}
                alt=""
                className="ml-3 z-1"
              ></img>
              <span className="text-lg font-bold ml-2 z-1 text-black">
                {item?.bathrooms !== null && priceData === true
                  ? item?.bathrooms
                  : item?.bathrooms === null
                  ? "any"
                  : `${item?.bathrooms}+`}
              </span>
              <img
                src={CarIcon}
                width={24}
                height={24}
                alt=""
                className="ml-3 z-1"
              ></img>
              <span className="text-lg font-bold ml-2 z-1 text-black">
                {item?.carSpaces !== null && priceData === true
                  ? item?.carSpaces
                  : item?.carSpaces === null
                  ? "any"
                  : `${item?.carSpaces}+`}
              </span>
              <span className="text-lg font-bold ml-3 z-1 text-black">|</span>
              <span className="text-lg font-bold ml-3 z-1 text-black">
                {item?.propertyType
                  ? item?.propertyType[0]?.propertyType
                  : "House"}
              </span>
            </p>
            <p className="flex align-items-center p-0 m-0 ">
              <img
                src={ClockIcon}
                width={24}
                height={24}
                className="z-1"
                alt=""
              ></img>
              <span className="text-lg font-bold ml-3 z-1 text-black">
                {(item?.postType === "BUYER" && priceData === true) ||
                (item?.postType === "RENTER" && priceData === true)
                  ? `$${item?.priceData?.min} ${
                      item?.priceData?.max
                        ? "-" + "$" + item?.priceData?.max
                        : ""
                    }`
                  : item?.postType === "BUYER" || item?.postType === "RENTER"
                  ? `$${
                      item?.priceRange.min < 1000000
                        ? item?.priceRange.min / 1000 + "K"
                        : item?.priceRange.min / 1000000 + "M"
                    } - $${
                      item?.priceRange.max < 1000000
                        ? item?.priceRange.max / 1000 + "K"
                        : item?.priceRange.max / 1000000 + "M"
                    }`
                  : item?.postType === "SELLER"
                  ? `${formatter.format(item?.price)}`
                  : null}
              </span>
            </p>

            {/* </div> */}

            {item?.postType === "BUYER" || item?.postType === "RENTER" ? (
              <>
                <p className="flex align-items-center p-0 m-0 ">
                  <img src={TagIcon} width={24} height={24} alt=""></img>
                  <span className="text-lg font-bold ml-3 z-1 text-black">
                    {item?.postType === "BUYER"
                      ? item?.purchaseTimeFrame
                      : item?.postType === "RENTER"
                      ? item?.purchaseTimeFrame
                      : ""}
                  </span>
                </p>
                {item?.purchaseMethod ? (
                  <p className="flex align-items-center p-0 m-0">
                    <img src={dollarIcon} width={24} height={24} alt=""></img>
                    <span className="text-lg font-bold ml-3 text-black">
                      {item?.purchaseMethod === "OWNFUNDS"
                        ? "Own Funds"
                        : item?.purchaseMethod === "FINANCE" &&
                          item?.financeDetails?.isArranged === true
                        ? "Finance Ready"
                        : item?.financeDetails?.isArranged === false
                        ? "Finance Not Ready"
                        : ""}
                    </span>
                  </p>
                ) : null}
                {renterhistory ? (
                  <p className="flex align-items-center p-0 m-0">
                    <img
                      src={RenterMaskIcon}
                      width={24}
                      height={24}
                      alt=""
                    ></img>
                    <span className="text-lg font-bold ml-3 text-black">
                      {renterhistory}
                    </span>
                  </p>
                ) : null}

                {item?.propertyMustHave?.min ? (
                  <p className=" card_logo align-items-center flex m-0 p-0">
                    <img
                      src={CheckMarkIcon}
                      width={24}
                      height={24}
                      alt=""
                    ></img>
                    <span className="text-lg font-bold ml-3 z-1 text-black">
                      {item?.propertyMustHave?.min
                        ? item?.propertyMustHave?.min
                        : null}
                    </span>
                  </p>
                ) : null}
                {item?.propertyMustHave?.max ? (
                  <p className=" card_logo align-items-center flex p-0 m-0">
                    <img
                      src={CheckMarkIcon}
                      width={24}
                      height={24}
                      alt=""
                    ></img>
                    <span className="text-lg font-bold ml-3 z-1 text-black">
                      {item?.propertyMustHave?.max
                        ? item?.propertyMustHave?.max
                        : null}
                    </span>
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
          {location.pathname !== "/post" ? (
            <div className="no-underline">
              <ButtonOutlined
                label={button === true ? "BRIEF PREVIEW" : "MORE INFO"}
                className="text-sm font-semibold"
                onClick={() => {
                  handleSubmit();
                  gotoBtn();
                }}
              />
            </div>
          ) : (
            <>
              <div className="no-underline mb-3">
                <ButtonComponent
                  label="VIEW POST"
                  className="text-sm font-semibold  "
                  onClick={() => {
                    handleSubmit();
                    gotoBtn();
                  }}
                />
              </div>
              <div className="no-underline mb-3">
                <ButtonComponent
                  label="EDIT POST"
                  className="text-sm font-semibold bg-green-cyan border-0  "
                  onClick={() => {
                    handleViewPost();
                    gotoBtn();
                  }}
                />
              </div>
              <div className="no-underline mb-3">
                <ButtonComponent
                  label="DELETE POST"
                  className="text-sm font-semibold bg-red-500 text-white  border-0"
                  onClick={() => {
                    handleDelete(item._id);
                    gotoBtn();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </CardComponent>
    </div>
  );
};
