import { types } from "../types";

const initialState = {
    sellerUploadFileInfo: {},
    singleUploadProfile: {}
};


export const sellerUploadImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPLOAD_FILE:
            return {
                ...state,
                sellerUploadFileInfo: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const SINGLEUPLOADFILEReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SINGLE_UPLOAD_FILE:
            return {
                ...state,
                singleUploadProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}