import React, { useRef } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { ProfileCard } from "../../components/cards/ProfileCard.jsx";
import FilterIcon from "../../assets/icons/Filter Vertical.png";
import { CustomDropDown } from "../../components/input/AllInput";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import { AllPostCommon } from "./AllPostCommon";
import { Dialog } from "primereact/dialog";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Navigate, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/authService.js";
export default function AllPost({ selectProfile, SetSelectProfile }) {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();
  const {
    data,
    handleSearchChange,
    inputValue,
    bedRoomsArray,
    bedRoomsVisible,
    recent,
    setFilter,
    name,
    setRecentCheck,
    priceVisible,
    handleBeddRooms,
    setPriceVisible,
    handleProfileType,
    setProfileType,
    profileType,
    handlePriceSearch,
    profileTypeArray,
    minPriceRange,
    handlePriceChange,
    price,
  } = AllPostCommon();
  const op = useRef(null);
  return (
    <>
      <div className="all-post-container pt-8">
        <div className=" lg:py-6 py-5 ">
          <ContainerBox>
            <div className="grid p-0 m-0 flex align-items-center justify-content-between mb-3 ">
              <div className="col-12 md:col-12 lg:col-5 flex search-box-hide lg:hidden p-0 m-0 p-1">
                <div className="search-box w-full flex align-items-center justify-content-between">
                  <input
                    type="search"
                    placeholder="Search here"
                    className="text-base font-medium Raleway"
                    value={inputValue}
                    onChange={(e) => handleSearchChange(e)}
                  />
                  <span>
                    <i className="pi pi-search ml-2 mt-1" />
                  </span>
                </div>
              </div>
            </div>
            <div className=" flex overflow-auto mt-3  mb-3 ">
              <div className="w-5 ml-2 search_box">
                <div className="search-box w-full flex align-items-center justify-content-between">
                  <input
                    type="search"
                    placeholder="Search here"
                    className="text-base font-medium Raleway"
                    value={inputValue}
                    onChange={(e) => handleSearchChange(e)}
                  />
                  <span>
                    <i className="pi pi-search ml-2 mt-1" />
                  </span>
                </div>
              </div>
              <div className="lg:ml-3">
                <CustomDropDown
                  placeholder="PROFILE TYPE"
                  extraClassName="w-full"
                  options={profileTypeArray}
                  optionLabel=""
                  name="profileType"
                  value={profileType}
                  onChange={(e) => handleProfileType(e)}
                ></CustomDropDown>
              </div>
              <div className="ml-3">
                <div
                  onClick={(e) => op.current.toggle(e)}
                  className="price-btn border-1 w-full  px-4 border-round-lg text-gray bg-white flex align-items-center"
                  style={{ borderColor: "#E3E5E8" }}
                >
                  <p
                    className={
                      price?.min !== "" || price?.max !== ""
                        ? "font-bold text-base  text-black"
                        : "font-semibold text-sm p-1 text-gray"
                    }
                  >
                    PRICE
                  </p>
                  <span>
                    <i
                      className="pi pi-angle-down ml-2"
                      style={{ fontSize: "1.2rem", color: "#8F9A99" }}
                    ></i>
                  </span>
                </div>
              </div>

              <div className="ml-3">
                <CustomDropDown
                  placeholder="BEDROOMS"
                  extraClassName="w-full"
                  options={bedRoomsArray}
                  optionLabel="label"
                  value={bedRoomsVisible}
                  onChange={(e) => handleBeddRooms(e)}
                ></CustomDropDown>
              </div>
              {/* <div className="">
                <div className="filter-button bg-white flex align-items-center mx-2 px-3 p-1">
                  <p className="flex white-space-nowrap font-semibold text-gray text-sm">
                    MORE FILTERS
                  </p>
                  <span className="ml-3">
                    <img src={FilterIcon} alt="" height={24} width={24} />
                  </span>
                </div>
              </div> */}
            </div>
            {/* Added Profile Section */}
            <div className=" profile ">
              <div className="grid p-0 m-0 flex  align-items-center mb-3">
                <div className="col-12 md:col-6 lg:col-6">
                  <p className="font-bold text-dark text-xl p-0 m-0 ">
                    {" "}
                    All Briefs Directory
                  </p>
                </div>
                <div className="col-12 md:col-6 lg:col-6 lg:justify-content-end flex  md:justify-content-end">
                  <p className="font-medium text-black  p-0 m-0 flex align-items-center font-medium text-base  ">
                    {" "}
                    Sort by
                    <span className="ml-3 flex align-items-center text-dark font-medium text-base">
                      {name}
                      <i
                        className="pi pi-angle-down ml-2"
                        style={{
                          fontSize: "1.5rem",
                          color: "rgba(42, 53, 61, 1)",
                        }}
                        onClick={(e) => recent.current.toggle(e)}
                      ></i>
                    </span>
                    <OverlayPanel
                      style={{ width: "7rem" }}
                      ref={recent}
                      className="text-center"
                    >
                      <div
                        className="pb-2 border-bottom-1 cursor-pointer"
                        onClick={() => setRecentCheck(true)}
                      >
                        Recent
                      </div>
                      <div
                        className="pt-2 cursor-pointer"
                        onClick={() => setRecentCheck(false)}
                      >
                        Old
                      </div>
                    </OverlayPanel>
                    {/* <span>
                                            <i className="pi pi-angle-down" style={{ fontSize: '1.6rem' }}></i>
                                        </span> */}
                  </p>
                </div>
              </div>
              <div className="grid p-0 m-0">
                {data?.length > 0 ? (
                  data?.map((item, index) => {
                    return item?.isVerified === "VERIFIED" ? (
                      <>
                        {item.postType === "BUYER" ||
                        item.postType === "RENTER" ? (
                          item.address?.map((items) => {
                            return (
                              <ProfileCard
                                cardclass="all-profile-card"
                                size="col-12 md:col-6 lg:col-4 mb-4 "
                                item={item}
                                index={index}
                                items={items}
                                card={true}
                              />
                            );
                          })
                        ) : (
                          <ProfileCard
                            item={item}
                            index={index}
                            cardclass="all-profile-card"
                            size="col-12 md:col-6 lg:col-4 mb-4 "
                            card={true}
                          />
                        )}
                      </>
                    ) : null;
                  })
                ) : (
                  <h3>No Data Found</h3>
                )}
              </div>
            </div>
          </ContainerBox>
        </div>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {isAuthenticated() ? null : (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
      <div className="price-modal">
        <OverlayPanel ref={op} className="price-modal lg:w-5 md:w-10 w-12">
          <p className="m-0">
            <h1 className="text-black text-lg p-2">Price</h1>
            <div className="grid p-0 m-0 flex justify-content-center">
              <div className="col-12 md:col-6">
                <CustomDropDown
                  extraClassName="w-full"
                  placeholder="Any"
                  label="Min"
                  options={minPriceRange}
                  optionLabel="label"
                  name="min"
                  value={price.min}
                  onChange={handlePriceChange}
                  errorMessage={price?.formErrors?.min}
                />
              </div>
              <div className="col-12 md:col-6">
                <CustomDropDown
                  extraClassName="w-full"
                  placeholder="Any"
                  label="Max"
                  options={
                    price.min
                      ? [
                          minPriceRange[0],
                          ...minPriceRange.slice(
                            minPriceRange.findIndex((item) => {
                              return item.value == price.min;
                            })
                          ),
                        ]
                      : minPriceRange
                  }
                  optionLabel="label"
                  name="max"
                  value={price.max}
                  onChange={handlePriceChange}
                  errorMessage={price?.formErrors?.max}
                />
              </div>
            </div>
          </p>
          <div className=" opacity-1 grid p-0 m-0 justify-content-end">
            <div className="col-12 md:col-3">
              <ButtonComponent
                label="Search"
                onClick={(e) => {
                  handlePriceSearch();
                  op.current.toggle(e);
                }}
              ></ButtonComponent>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
}
