import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { CustomPassword } from "../../components/input/AllInput";
import { Index } from "../../layout";
import { resetPasswordAction } from "../../redux/actions/signupAction";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidation";
import { showFormErrors } from "../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
import { customPasswordCheck } from "../../utils/javascript";
export default function SetPassword({ SetSelectProfile, selectProfile }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    token: ""
  })
  const { token } = useParams()

  const handleChange = (e) => {
    const { name, value } = e.target
    const formErrors = allValidations(name, value, form);
    setForm((prev) => ({ ...prev, [name]: value, formErrors }));
  }
  const formErrorCheck = () => {
    const valid = customPasswordCheck(form?.password)
    let formErrors = {}
    if (form?.password === "") {
      formErrors.password = "Please Enter Password"
    }
    else if (valid) {
      formErrors.password = valid
    }
    if (form?.confirmPassword === "") {
      formErrors.confirmPassword = "Please Enter Confirm Password"
    } else if (form?.password !== form?.confirmPassword) {
      formErrors.confirmPassword = "Password and Confirm Password are not same"
    }
    if (Object.keys(formErrors).length == 0) {
      return true
    }
    else {

      setForm({ ...form, formErrors })
      return false
    }
  }
  const handleSubmit = () => {
    if (!formErrorCheck()) {
      return false;
    } else {
      dispatch(resetPasswordAction(form, token, () => navigate("/")))
    }


  }
  useEffect(() => {
    if (token) {
      setForm({
        ...form,
        token: token
      })
    }
  }, [token])
  return (
    <>
      <div className="forgot-container pt-8 ">
        <div className="p-5 bg-light-gray ">
          <div className="grid p-0 m-0 flex justify-content-center bg-main-light ">
            <div className="col-12 md:col-5  mb-3">
              <h1 className="fonr-semibold text-dark text-4xl text-center">
                Reset Password
              </h1>
              <CustomPassword
                label="Password"
                col="12"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                errorMessage={form?.formErrors?.password}
              />
              <CustomPassword
                label="Confirm Password"
                col="12"
                placeholder="Password"
                name="confirmPassword"
                onChange={handleChange}
                disabled={form.password.length === 0 ? true : false}
                errorMessage={form?.formErrors?.confirmPassword}
              />
              <Link to="" className="no-underline" onClick={handleSubmit}>
                <ButtonOutlined label="SUBMIT" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {selectProfile === true ?
        <div className="landing-page-modal">
          <CustomDialog visible={selectProfile} onHide={() => SetSelectProfile(false)}>
            <PickProfile SetSelectProfile={SetSelectProfile} selectProfile={selectProfile} />
          </CustomDialog>
        </div> : ""}
    </>
  );
}
