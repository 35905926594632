import React from "react";
import { ContainerBox } from "./ContainerBox";

export default function ContentArea({ children }) {
  return (
    <ContainerBox>
      <div className="form-card shadow-2 lg:p-5 md:p-3 mr-2 ml-2 " style={{borderRadius:"20px"}}>{children}</div>
    </ContainerBox>
  );
}
