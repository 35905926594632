import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  googlelogin,
  signInAction,
  socialLogin,
} from "../../redux/actions/signupAction";
import { useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidation";
import { showFormErrors } from "../../utils/commonFunction";
import { useGoogleLogin } from "@react-oauth/google";

const Loginfunction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    loginPassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formErrors = allValidations(name, value, form);
    setForm((prev) => ({ ...prev, [name]: value, formErrors }));
  };
  const handleSubmit = () => {
    if (showFormErrors(form, setForm)) {
      let payload = {
        email: form.email,
        password: form.loginPassword,
      };
      dispatch(signInAction(payload, () => navigate("/profile")));
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (respose) => {
      dispatch(googlelogin(respose)).then((res) => {
        if (res.data.sub) {
          let payload = {
            email: res.data.email,
            socialId: res.data.sub,
            loginType: "google",
            firstName: res.data.given_name,
            lastName: res.data.family_name,
          };
          dispatch(socialLogin(payload)).then((res) => {
            if (res) {
              navigate("/profile");
            } else {
              navigate("/sign-in");
            }
          });
        }
      });
    },
  });

  return { form, setForm, handleChange, handleSubmit, googleLoginHandler };
};

export default Loginfunction;
