import React, { useState } from "react";
import ProfileImage from "../../assets/images/Completed-rafiki 1.png";
import CheckIcon from "../../assets/icons/Checkmark.png";
import { Link, useNavigate } from "react-router-dom";
import HomeContent from "./HomeContent";
import { ButtonOutlined } from "../../components/buttons/button";
import { Checkbox } from "primereact/checkbox";
import { useEffect } from "react";
import { Card } from "antd";
export const PickProfile = ({ SetSelectProfile }) => {
  const { chooseprofile } = HomeContent();
  const navigate = useNavigate();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [checked, setChecked] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const handleClick = (item) => {
    if (checked === true) {
      navigate(item.link);
      SetSelectProfile(false);
      gotoBtn();
    } else {
      setCheckBox(true);
    }
  };
  useEffect(() => {
    if (checked === true) {
      setCheckBox(false);
    }
  }, [checked]);
  return (
    <div className=" bg-green-light">
      <div className="bg-lightest-gray text-center lg:block md:block hidden">
        <img src={ProfileImage} alt="" className="w-5" />
      </div>
      <ul className="p-0 mb-4 " style={{ fontFamily: "Raleway" }}>
        <li
          className="lg:text-lg md:text-lg text-base font-medium flex align-items-center mb-4 text-black Raleway"
          style={{ fontFamily: "Raleway" }}
        >
          <img src={CheckIcon} alt="" width={20} />
          <span
            className="font-medium ml-2 text-dark step-1 "
            style={{ fontSize: "18px" }}
          >
            Step 1.
          </span>{" "}
          &nbsp; Complete your details
        </li>
        <li className="lg:text-lg md:text-lg text-base font-medium  flex align-items-center mb-4 text-black ">
          <img src={CheckIcon} alt="" width={20} />
          <span className="font-medium ml-2 text-dark  text-lg">
            Step 2.
          </span>{" "}
          &nbsp;Submit and Get Verified
        </li>
        <li className="lg:text-lg md:text-lg text-base  font-medium  flex align-items-center mb-4 text-black ">
          <img src={CheckIcon} alt="" width={20} />
          <span className="font-medium ml-2 text-dark  text-lg">
            Step 3.
          </span>{" "}
          &nbsp; Your Brief goes Live
        </li>
        <li className="lg:text-lg md:text-lg text-base font-medium  flex align-items-center mb-5 text-black ">
          <img src={CheckIcon} alt="" width={20} />
          <span className="font-medium ml-2 text-dark  text-lg">
            Step 4.
          </span>{" "}
          &nbsp; Find the Perfect Place
        </li>
      </ul>
      <div className="flex md:align-items-center">
        <Checkbox
          onChange={(e) => setChecked(e.checked)}
          checked={checked}
        ></Checkbox>
        <span className="mb-3 text-black font-medium text-base md:mt-3 ml-2">
          By proceeding, you confirm that you have read, understood and agree to
          our <Link to="/term-condition"  className="text-main font-semibold">Terms & Conditions</Link> and <Link to="/privacy-policy" className="text-main font-semibold">Privacy policy</Link>.
         
        </span>
      </div>
      {checkbox === true ? (
        <small className="p-error text-sm ml-2">
          Please Accept Privacy Policy
        </small>
      ) : (
        ""
      )}
      <div className=" pic-profile-modal grid mb-3">
        {/* onClick={() => { SetSelectProfile(false); gotoBtn() }} */}
        {chooseprofile?.map((item, index) => {
          return (
            <div className="lg:col-4 col-12 select-profile-buyer h-full">
              <p
                className="no-underline pic-profile text-black"
                onClick={() => handleClick(item)}
              >
                <Card
                  className=" text-center shadow-2 justify-content-center p-3 profile-select cursor-pointer h-full p-0 m-0 "
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    src={item.img}
                    alt=""
                    width={48}
                    height={48}
                    className="text-center"
                  />
                  <p className="lg:p-2 md:p-2 p-0 m-0 text-lg font-medium  ">
                    {item.heading}
                  </p>
                </Card>
              </p>
            </div>
          );
        })}
      </div>
      {/* 
      <ButtonOutlined label="CONTINUE " onClick={handleSubmit} /> */}
    </div>
  );
};
