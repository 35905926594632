import React from 'react'
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import BorderCard from '../../components/cards/BorderCard';
import CheckmarkIon from "../../assets/icons/checkmark-circle-02.png";
import ButtonComponent, { ButtonOutlined } from '../../components/buttons/button';

export default function PricingPlan() {
    return (
        <>
            <>
                <div className='pricing plan py-6 pt-8 mt-4' style={{ backgroundColor: "rgba(250, 255, 255, 1)" }}>

                    <ContainerBox>
                        <h2 className='text-center font-semibold text-dark p-0 m-0 mt-8' style={{ fontSize: "56px" }}>Pricing plan</h2>
                        <p className='text-center text-black text-lg font-medium mb-6'>Choose the Perfect Plan for Your Needs</p>
                        <div className='gird p-0 m-0 lg:flex md:flex lg:justify-content-center md:justify-content-center'>
                            <div className='col-12 md:col-6 lg:col-4 '>
                                <BorderCard>
                                    <p className='p-0 m-0 text-center text-black font-medium text-xl mb-3'>Basic plan</p>
                                    <h2 className='text-center mb-5 font-bold p-0 m-0 text-dark-black' style={{ fontSize: "64px" }}>Free</h2>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base'>1 free profile post</span>
                                    </p>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>All personal Details kept private </span>
                                    </p>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>All Enquiries handled on behalf of you</span>
                                    </p>
                                    <p className='flex align-items-center '>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>Free profile matching</span>
                                    </p>
                                    <ButtonOutlined label="SUBSCRIBE" className="mt-8"></ButtonOutlined>
                                </BorderCard>
                            </div>
                            <div className='col-12 md:col-6 lg:col-4'>
                                <BorderCard>
                                    <p className='p-0 m-0 text-center font-medium text-xl mb-3 text-dark-black' >Business plan</p>
                                    <h2 className='text-center mb-5 font-bold p-0 m-0 pricing-plan-text text-dark-black' style={{ fontSize: "64px" }}>$149
                                        <span className="font-semibold text-4xl " >/Monthly</span></h2>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium '>Unlimited profile posts</span>
                                    </p>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'> We manage all profile enquiries </span>
                                    </p>
                                    <p className='flex align-items-center'>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>All personal details kept private</span>
                                    </p>
                                    <p className='flex align-items-center '>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>Agent support </span>
                                    </p>
                                    <p className='flex align-items-center mb-5 '>
                                        <img src={CheckmarkIon} height={24} width={24} alt="" />
                                        <span className='ml-3 text-dark-black text-base font-medium'>Free profile matching </span>
                                    </p>
                                    <ButtonOutlined label="SUBSCRIBE" className="mt-4"></ButtonOutlined>
                                </BorderCard>
                            </div>
                        </div>

                    </ContainerBox>


                </div>
            </>


        </>
    )
}
