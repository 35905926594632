import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../../utils/formValidation";
import { buyerGeneralInfo } from "../../../redux/actions/buyerAction";
import { showToast } from "../../../redux/actions/toastAction";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { viewPostAction } from "../../../redux/actions/postAction";
import { useParams } from "react-router-dom";
import { myProfileAction } from "../../../redux/actions/profileAction";
import { emailCheckAction } from "../../../redux/actions/signupAction";
import _debounce from "lodash/debounce";
import { emailValidation } from "../../../utils/regex";
import { customPasswordCheck } from "../../../utils/javascript";
import { isAuthenticated } from "../../../services/authService";
export const SellerIndex = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [code, setCode] = useState("+61");
  const [inputList, setInputList] = useState([{ upload: "" }]);
  const [floorPlan, setFloorPlan] = useState([{ upload: "" }]);
  const [maxLand, setMaxLand] = useState(null);
  const [minLand, setMinLand] = useState(null);
  const [page, setPage] = useState(1);
  const [check, setChecked] = useState([]);
  const [highlight, setHightlight] = useState([]);
  const [selectAddress, setSelectAddress] = useState(false);
  const [selectSubhurb, setSelectSuburb] = useState(false);
  const [emailCheck, setEmailCheck] = useState();
  const [address, setAddress] = useState("");
  const [bathRoomHightlight, setBathRoomHightlight] = useState([]);
  const [carHightlight, setCarHightlight] = useState([]);
  const [show, setShow] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    suburb: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    establishedProperty: "",
    buildYear: "",
    postType: "SELLER",
    propertiesTitle: "",
    address: {
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    },
    price: "",
    Unit: "",
    Description: "",
    propertyType: "",
    bedrooms: "",
    bathrooms: "",
    carSpaces: "",
    landSize: {},
    outdoorFeatures: [],
    indoorFeatures: [],
    climateControlEnergy: [],
    image: "",
    addFloorplan: "",
    password: "",
    confirmPassword: "",
  });
  const handleDebounceFn = (payload) => {
    dispatch(emailCheckAction(payload)).then((res) => {
      if (res.success === true) {
        setEmailCheck(true);
      } else if (res.success === false) {
        setEmailCheck(false);
      }
    });
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleChange = (e) => {
    let array = [];
    const { name, value } = e?.target;
    let formErrors = {};
    if (name === "minLand") {
      formErrors = allValidations("landSize", value, form);
    } else {
      formErrors = allValidations(name, value, form);
    }
    if (name === "email") {
      setForm({
        ...form,
        email: value,
        formErrors,
      });
      let payload = { email: value };
      debounceFn(payload);
    } else if (name === "minLand" || name === "maxLand") {
      if (name === "minLand") {
        setForm((prev) => {
          return {
            ...prev,
            landSize: {
              ...prev.landSize,
              min: value,
            },
            formErrors,
          };
        });
        setMinLand(value);
      }
    } else if (name === "propertyType") {
      array.push(value);
      setForm({
        ...form,
        propertyType: array,
        formErrors,
      });
    } else {
      setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };
  useEffect(() => {
    if (code) {
      setForm({
        ...form,
        countryCode: code,
      });
    } else {
      setForm({
        ...form,
        countryCode: "+61",
      });
    }
  }, [code, form.mobile]);
  useEffect(() => {
    dispatch(buyerGeneralInfo());
  }, []);
  const unitData = [
    { year: "1", value: "1" },
    { year: "2", value: "2" },
    { year: "3", value: "3" },
    { year: "4", value: "4" },
    { year: "5", value: "5" },
    { year: "6", value: "6" },
    { year: "7", value: "7" },
    { year: "8", value: "8" },
    { year: "9", value: "9" },
    { year: "10", value: "10" },
  ];
  const minLandSize = [
    "Any",
    "200 ㎡",
    "300 ㎡",
    "400 ㎡",
    "500 ㎡",
    "600 ㎡",
    "700 ㎡",
    "800 ㎡",
    "900 ㎡",
    "1,000 ㎡",
    "1,200 ㎡",
    "1,500 ㎡",
    "1,750 ㎡",
    "2,000 ㎡",
    "3,000 ㎡",
    "4,000 ㎡",
    "5,000 ㎡",
    "1 ha",
    "2 ha",
    "3 ha",
    "4 ha",
    "5 ha",
    "10 ha",
    "20 ha",
    "30 ha",
    "40 ha",
    "50 ha",
    "60 ha",
    "70 ha",
    "80 ha",
    "90 ha",
    "100 ha",
    "150 ha",
    "200 ha",
    "300 ha",
    "500 ha",
    "1000 ha",
    "3000 ha",
    "5000 ha",
    "10000 ha",
  ];
  const handleRoomChange = (name, value) => {
    const formErrors = allValidations(name, value, form);
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
        formErrors,
      };
    });
    if (name === "bedrooms") {
      setHightlight(value);
    } else if (name === "bathrooms") {
      setBathRoomHightlight(value);
    } else {
      setCarHightlight(value);
    }
  };
  const handleTypeChange = (e) => {
    if (e.value._id === "6502e67c4bae95323d453d17" && e.checked === true) {
      let selectType = [...PropertyList?.propertyTypes];
      const formErrors = allValidations("propertyType", selectType, form);
      setChecked(selectType);
      setForm({
        ...form,
        propertyType: selectType,
        formErrors,
      });
    } else if (
      e.value._id === "6502e67c4bae95323d453d17" &&
      e.checked === false
    ) {
      const formErrors = allValidations("propertyType", [], form);
      setChecked([]);
      setForm({
        ...form,
        propertyType: [],
        formErrors,
      });
    } else {
      let selectType = [...check];
      if (e.checked) {
        selectType.push(e.value);
      } else {
        selectType = selectType.filter((type) => type._id !== e.value._id);
      }
      setChecked(selectType);
      const formErrors = allValidations("propertyType", selectType, form);
      setForm({
        ...form,
        propertyType: selectType,
        formErrors,
      });
    }
  };
  const formCheck = () => {
    const valid = customPasswordCheck(form?.password);
    let formErrors = {};
    if (form.firstName === "") {
      formErrors.firstName = "Please Enter First Name";
    }
    if (form.lastName === "") {
      formErrors.lastName = "Please Enter Last Name";
    }
    if (form.email === "") {
      formErrors.email = "Please Enter Email";
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
    }
    if (form.mobile === "") {
      formErrors.mobile = "Please Enter Mobile Number";
    } else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only";
    }
    if (emailCheck === false && form?.password === "") {
      formErrors.password = "Please Enter Password";
    } else if (emailCheck === false && valid) {
      formErrors.password = valid;
    }
    if (emailCheck === false && form?.confirmPassword === "") {
      formErrors.confirmPassword = "Please Enter ConfirmPassword";
    } else if (
      emailCheck === false &&
      form?.password !== form?.confirmPassword
    ) {
      formErrors.confirmPassword = "Password and Confirm Password are not same";
    }
    if (form.suburb === "") {
      formErrors.suburb = "Please Enter Full Address";
    }
    if (selectAddress === false) {
      formErrors.suburb = "Please Enter Full Address";
    }
    if (selectSubhurb === false) {
      formErrors.address = "Please Select";
    }
    if (form.establishedProperty === "") {
      formErrors.establishedProperty = "Please Select";
    }
    if (form.buildYear === "Invalid date") {
      formErrors.buildYear = "Please Select";
    }
    if (form.propertiesTitle === "") {
      formErrors.propertiesTitle = "Please Enter";
    }
    if (form.price === "") {
      formErrors.price = "Please Enter";
    }
    if (form?.address?.streetAddress === "") {
      formErrors.address = "Please Select";
    }
    if (form.image === "") {
      formErrors.image = "Please Select Image";
    }
    if (form.propertyType === "") {
      formErrors.propertyType = "Please Select";
    }
    if (form.bedrooms === "") {
      formErrors.bedrooms = "Please Select";
    }
    if (form.bathrooms === "") {
      formErrors.bathrooms = "Please Select";
    }
    if (form.carSpaces === "") {
      formErrors.carSpaces = "Please Select";
    }
    if (Object.keys(form.landSize).length === 0) {
      formErrors.landSize = "Please Enter";
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (!formCheck()) {
      dispatch(
        showToast({ severity: "error", summary: "Required all fields" })
      );
      return false;
    } else if (token === null && form?.password === "") {
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else if (token === null && emailCheck === true) {
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else if (form?.password !== form?.confirmPassword) {
      dispatch(
        showToast({
          severity: "error",
          summary: "Password & ConfirmPassword can't match",
        })
      );
    } else {
      setPage(2);
    }
  };
  const maxInputFields = 10;
  const handleAddImage = () => {
    if (inputList.length < maxInputFields) {
      setInputList([...inputList, { upload: "" }]);
    }
  };
  const handleImage = (e, index) => {
    let array = [];
    const list = [...inputList];
    if (e.target.files.length === 1) {
      let obj = {
        ...list[index],
        upload: e.target.files[0],
      };
      list.splice(index, 1, obj);
      setInputList(list);
    } else {
      Object?.entries(e.target.files).map((item) => {
        let obj = {
          ...list[index],
          upload: item[1],
        };
        array.push(obj);
      });
      setInputList([...array]);
      if (array.length < 11) {
        setInputList(array);
      } else {
        dispatch(
          showToast({
            severity: "error",
            summary: "Maximum 10 images you can upload",
          })
        );
      }
    }
  };
  const handleChangeAddFloorPlan = (e, index) => {
    const list = [...floorPlan];
    let array = [];
    Object.entries(e.target.files).map((item) => {
      let obj = {
        ...list[index],
        upload: item[1],
      };
      array.push(obj);
    });
    if (array.length < 11) {
      setFloorPlan(array);
    } else {
      dispatch(
        showToast({
          severity: "error",
          summary: "Maximum 10 images you can upload",
        })
      );
    }
  };
  useEffect(() => {
    const formErrors = allValidations("image", inputList, form);
    setForm({
      ...form,
      image: inputList,
      formErrors,
    });
  }, [inputList]);
  useEffect(() => {
    const formErrors = allValidations("addFloorplan", floorPlan, form);
    setForm({
      ...form,
      addFloorplan: floorPlan,
      formErrors,
    });
  }, [floorPlan]);
  const handleClear = () => {
    window.scrollTo({ top: 0, left: 0 });
    window.location.reload();
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobile: "",
      suburb: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      establishedProperty: "",
      buildYear: "",
      postType: "SELLER",
      propertiesTitle: "",
      address: {
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        location: { lat: "", long: "" },
      },
      price: "",
      Unit: "",
      Description: "",
      propertyType: "",
      bedrooms: "",
      bathrooms: "",
      carSpaces: "",
      landSize: {},
      outdoorFeatures: [],
      indoorFeatures: [],
      climateControlEnergy: [],
      image: "",
      addFloorplan: "",
    });
  };
  const handleChangeSuburb = (newAddress) => {
    setShow(true);
    setSelectAddress(false);
    // setAddress(newAddress);
    setForm({
      ...form,
      suburb: newAddress,
    });
  };
  const handleSelectAddressSuburb = (newAddress) => {
    setSelectAddress(true);
    const formErrors = allValidations("suburb", newAddress, form);
    // setAddress(newAddress)
    let obj = {
      state: "",
      city: "",
      zipcode: "",
      suburb: "",
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.suburb = results[0]?.formatted_address;
                obj.zipcode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),

          setForm({
            ...form,
            state: obj.state,
            zipCode: obj.zipcode,
            suburb: obj.suburb,
            city: obj.city,
            formErrors,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  const handleChangeAddress = (newAddress) => {
    setShow(true);
    setSelectSuburb(false);
    let obj = {
      streetAddress: newAddress,
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    };
    setForm({
      ...form,
      address: obj,
    });
  };
  const handleSelectAddress = (newAddress) => {
    setSelectSuburb(true);
    const formErrors = allValidations("address", newAddress, form);
    let array = [];
    let obj = {
      streetAddress: newAddress,
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.zipCode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),
          (obj.location.lat = results[0]?.geometry?.location.lng()),
          (obj.location.long = results[0]?.geometry?.location.lat()),
          (obj = {
            streetAddress: newAddress?.includes(obj?.zipCode)
              ? newAddress + "," + obj.state
              : newAddress + " " + obj?.zipCode + "," + obj.state,
            city: obj.city,
            state: obj.state,
            zipCode: obj.zipCode,
            location: { lat: obj.location.lat, long: obj.location.long },
          }),
          array.push(obj),
          setForm({
            ...form,
            address: array,
            formErrors,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {
    if (id) {
      dispatch(viewPostAction(id)).then((res) => {
        if (res.success) {
          setSelectSuburb(true);
        }
      });
    }
  }, [id]);
  const viewPostData = useSelector((state) => state.viewPost.viewPostProfile);
  useEffect(() => {
    if (Object.values(viewPostData)?.length > 0) {
      let array = [];
      let payload = {
        city: viewPostData?.address[0]?.city,
        state: viewPostData?.address[0]?.state,
        streetAddress: viewPostData?.address[0]?.streetAddress,
        zipCode: viewPostData?.address[0]?.zipCode,
        location: {
          lat: viewPostData?.address[0]?.location?.coordinates[0],
          long: viewPostData?.address[0]?.location?.coordinates[1],
        },
      };
      array.push(payload);
      setForm({
        ...form,
        firstName: viewPostData?.firstName,
        lastName: viewPostData?.lastName,
        email: viewPostData?.email,
        countryCode: viewPostData?.countryCode,
        mobile: viewPostData?.mobile,
        streetAddress: viewPostData?.streetAddress,
        suburb: viewPostData?.suburb,
        state: viewPostData?.state,
        zipCode: viewPostData?.zipCode,
        establishedProperty: viewPostData?.establishedProperty,
        buildYear: new Date(viewPostData?.buildYear),
        propertiesTitle: viewPostData?.propertiesTitle,
        address: array,
        price: viewPostData?.price,
        Unit: viewPostData?.Unit,
        Description: viewPostData?.Description,
        image: viewPostData?.image,
        addFloorplan: viewPostData?.addFloorplan,
        propertyType: viewPostData?.propertyType,
        bedrooms: viewPostData?.bedrooms,
        bathrooms: viewPostData?.bathrooms,
        carSpaces: viewPostData?.carSpaces,
        landSize: viewPostData?.landSize,
        outdoorFeatures: viewPostData?.outdoorFeatures,
        indoorFeatures: viewPostData?.indoorFeatures,
        climateControlEnergy: viewPostData?.climateControlEnergy,
      });
      if (viewPostData.propertyType?.length > 0) {
        setHightlight(
          viewPostData.bedrooms === null
            ? "any"
            : `${viewPostData?.bedrooms?.toString()}+`
        );
        setBathRoomHightlight(
          viewPostData?.bathrooms === null
            ? "any"
            : `${viewPostData?.bathrooms?.toString()}+`
        );
        setCarHightlight(
          viewPostData?.carSpaces === null
            ? "any"
            : `${viewPostData?.carSpaces?.toString()}+`
        );
        setMinLand(viewPostData?.landSize?.min);
        setMaxLand(viewPostData?.landSize?.max);
        setChecked(viewPostData?.propertyType);
        setCode(viewPostData?.countryCode);
        let image = viewPostData?.image?.map((item) => {
          return {
            upload: item,
          };
        });

        setInputList(image);
        let floorPlan = viewPostData?.addFloorplan?.map((item) => {
          return {
            upload: item,
          };
        });
        setFloorPlan(floorPlan);
        setSelectAddress(true);
      }
    }
  }, [viewPostData]);
  useEffect(() => {
    if (!id) {
      setForm({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobile: "",
        suburb: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        establishedProperty: "",
        buildYear: "",
        postType: "SELLER",
        propertiesTitle: "",
        address: {
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          location: { lat: "", long: "" },
        },
        price: "",
        Unit: "",
        Description: "",
        propertyType: "",
        bedrooms: "",
        bathrooms: "",
        carSpaces: "",
        landSize: {},
        outdoorFeatures: [],
        indoorFeatures: [],
        climateControlEnergy: [],
        image: "",
        addFloorplan: "",
      });
      setHightlight("");
      setBathRoomHightlight("");
      setCarHightlight("");
      setMinLand("");
      setMaxLand("");
      setChecked([]);
      setInputList([{ upload: "" }]);
      setFloorPlan([{ upload: "" }]);
    }
  }, [id]);
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    setInputList(remove);
  };
  const handleRemoveFloorPlane = (index) => {
    const list = [...floorPlan];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    setFloorPlan(remove);
  };
  const token = localStorage?.getItem("realState");
  useEffect(() => {
    if (token) {
      dispatch(myProfileAction());
    }
  }, [token]);
  const myProfileData = useSelector((state) => state.myProfileData.myProfile);
  useEffect(() => {
    if (myProfileData && id === undefined) {
      setForm({
        ...form,
        firstName: myProfileData?.firstName,
        lastName: myProfileData?.lastName,
        email: myProfileData?.email,
        countryCode: myProfileData?.countryCode,
        mobile: myProfileData?.mobile,
        // image: myProfileData?.image,
        suburb: myProfileData?.suburb,
        streetAddress: myProfileData?.streetAddress,
        zipCode: myProfileData?.zipCode,
        state: myProfileData?.state,
      });
      setCode(myProfileData?.countryCode);
      setSelectAddress(true);
    }
  }, [myProfileData, id]);
  const PropertyList = useSelector(
    (state) => state.buyergeneralInfo.generaInfo
  );
  useEffect(() => {
    if (token) {
      setEmailCheck(true);
    }
  }, [token, emailCheck]);
  useEffect(() => {
    if (id) {
      setChecked(viewPostData?.propertyType);
    }
  }, [id, viewPostData]);
  return {
    form,
    setForm,
    code,
    setCode,
    show,
    handleChange,
    handleRemoveClick,
    id,
    handleRemoveFloorPlane,
    handleChangeAddress,
    handleSelectAddress,
    address,
    unitData,
    inputList,
    highlight,
    bathRoomHightlight,
    carHightlight,
    handleChangeSuburb,
    handleSelectAddressSuburb,
    setInputList,
    PropertyList,
    minLandSize,
    maxLand,
    minLand,
    handleTypeChange,
    check,
    setChecked,
    floorPlan,
    setFloorPlan,
    page,
    setPage,
    handleSubmit,
    handleAddImage,
    handleImage,
    handleChangeAddFloorPlan,
    handleClear,
    handleRoomChange,
    emailCheck,
    token,
  };
};
