import React, { useState, useEffect } from "react";
import { Index } from "../../../layout";
import ContentArea from "../../../shared/ContentArea";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import {
  Link,
  json,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import TitleIcon from "../../../assets/icons/Title.png";
import LocationIcon from "../../../assets/icons/loaction.png";
import AllTypeIcon from "../../../assets/icons/All types.png";
import PriceIcon from "../../../assets/icons/price.png";
import CalendarIcon from "../../../assets/icons/calendar.png";
import LandIcon from "../../../assets/icons/Land size.png";
import { FormTopHeading } from "../../../shared/FormTopHeading";
import {
  ConfirmContactDetails,
  PropertySpaceDetail,
} from "../../profile-forms/CommonFeild";
import { SellerUploadImageAction } from "../../../redux/actions/selerAction";
import { createBuyerAction } from "../../../redux/actions/buyerAction";
import { useDispatch } from "react-redux";
import Constants from "../../../services/constant";
import moment from "moment";
import OtpInput from "react-otp-input";
import { mobileSendOtp, verifyOTP } from "../../../redux/actions/buyerAction";
import { editPostAction } from "../../../redux/actions/postAction";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { CustomPhoneInput } from "../../../components/input/AllInput";
import { Dialog } from "primereact/dialog";
import { isAuthenticated } from "../../../services/authService";
export const SellerConfirmDetails = ({
  form,
  setPage,
  SetSelectProfile,
  selectProfile,
  emailCheck,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [code, setCode] = useState("+61");
  const [error, setError] = useState({
    otp: "",
  });
  const [counter, setCounter] = useState();
  const [visible, setVisible] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const { id } = useParams();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();
  const GetImagePath = async (value, dispatch, type) => {
    if (value === null || value === "" || value === undefined) {
      return false;
    } else if (value && typeof value === "object") {
      let NewValue = [];
      await dispatch(SellerUploadImageAction(value)).then((res) => {
        if (res.success) {
          res.data?.length > 0 &&
            res.data?.map((item) => {
              NewValue.push(item?.path);
            });
        }
      });
      return NewValue;
    } else {
      return value;
    }
  };
  const handleSubmit = async () => {
    let array = [];
    let addFloor = [];
    // let addressArray = []
    form.image?.forEach((item) => {
      array.push(item.upload || item);
    });
    form.addFloorplan?.forEach((item) => {
      addFloor.push(item.upload);
    });
    let addimageArrayObject = [];
    let addimageArraystring = [];
    let floorImageArrayObject = [];
    let floorImageArrayString = [];
    array.map(async (item) => {
      if (typeof item === "object") {
        addimageArrayObject.push(item);
      } else {
        addimageArraystring.push(item);
      }
    });
    let addimageArray = await GetImagePath(addimageArrayObject, dispatch);
    addFloor.map(async (item) => {
      if (typeof item === "object") {
        floorImageArrayObject.push(item);
      } else {
        floorImageArrayString.push(item);
      }
    });
    let floorimageArray = await GetImagePath(floorImageArrayObject, dispatch);
    let propertyTypeArray = [];
    let outdoorFeaturesArray = [];
    let indoorFeaturesArray = [];
    let climateControlEnergyArray = [];
    form?.propertyType?.map((item) => {
      propertyTypeArray.push(item._id);
    });
    form?.outdoorFeatures?.map((item) => {
      outdoorFeaturesArray.push(item._id);
    });
    form?.indoorFeatures?.map((item) => {
      indoorFeaturesArray.push(item._id);
    });
    form?.climateControlEnergy?.map((item) => {
      climateControlEnergyArray.push(item._id);
    });
    // addressArray.push(form.address)

    let payload = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      email: form?.email,
      countryCode: form?.countryCode,
      mobile: form?.mobile,
      suburb: form?.suburb,
      city: form?.city,
      state: form?.state,
      zipCode: form?.zipCode,
      establishedProperty: form?.establishedProperty,
      buildYear: form?.buildYear,
      propertiesTitle: form?.propertiesTitle,
      address: form.address,
      price: form?.price,
      Unit: form?.Unit,
      Description: form?.Description,
      outdoorFeatures: outdoorFeaturesArray,
      indoorFeatures: indoorFeaturesArray,
      climateControlEnergy: climateControlEnergyArray,
      propertyType: propertyTypeArray,
      bedrooms:
        form?.bedrooms === "any"
          ? null
          : typeof form.bedrooms === "number"
          ? form.bedrooms
          : Number(form.bedrooms?.slice(0, 1)),
      bathrooms:
        form?.bathrooms === "any"
          ? null
          : typeof form.bathrooms === "number"
          ? form?.bathrooms
          : Number(form.bathrooms?.slice(0, 1)),
      carSpaces:
        form?.carSpaces === "any"
          ? null
          : typeof form.carSpaces === "number"
          ? form?.carSpaces
          : Number(form.carSpaces?.slice(0, 1)),
      landSize: form?.landSize,
      image: [...addimageArray, ...addimageArraystring],
      addFloorplan: [...floorimageArray, ...floorImageArrayString],
      postType: "SELLER",
      password: form?.password,
    };
    if (!isAuthenticated()) {
      setVisible(true);
    } else if (location?.pathname === "/seller-form") {
      dispatch(createBuyerAction(payload, () => setPage(3)));
    } else {
      dispatch(editPostAction(payload, id, () => setPage(3)));
    }
  };
  const showImage = () => {
    if (form?.image?.length > 0) {
      form?.image?.map((item) => {
        if (typeof item === "string") {
          return Constants.BASE_URL + `${item}`;
        } else {
          return window.URL.createObjectURL(item?.upload);
        }
      });
    }
  };
  const handleSendOtpRequest = () => {
    const payload = {
      countryCode: form?.countryCode,
      mobile: form?.mobile,
    };
    dispatch(mobileSendOtp(payload)).then((res) => {
      if (res.success === true) {
        setVisible(false);
        setOtpVerification(true);
      }
    });
  };
  const getNumericValue = (value) => {
    return value.replace(/\D/g, "");
  };
  const hadleChangeOtp = (e) => {
    const numericValue = getNumericValue(e);

    setOtp(numericValue);
    setError({
      ...error,
      otp: null,
    });
  };
  const handleVerifyOtp = () => {
    if (otp === "" || otp === undefined) {
      setError({
        ...error,
        otp: otp === "" || otp === undefined ? "Please Enter OTP" : error.otp,
      });
      return false;
    } else {
      let payload = {
        otpCode: otp,
        countryCode: form?.countryCode,
        mobile: form?.mobile,
      };
      dispatch(verifyOTP(payload)).then(async (res) => {
        if (res.success === true) {
          let array = [];
          let addFloor = [];
          // let addressArray = []
          form.image?.forEach((item) => {
            array.push(item.upload || item);
          });
          form.addFloorplan?.forEach((item) => {
            addFloor.push(item.upload);
          });
          let addimageArrayObject = [];
          let addimageArraystring = [];
          let floorImageArrayObject = [];
          let floorImageArrayString = [];
          array.map(async (item) => {
            if (typeof item === "object") {
              addimageArrayObject.push(item);
            } else {
              addimageArraystring.push(item);
            }
          });
          let addimageArray = await GetImagePath(addimageArrayObject, dispatch);
          addFloor.map(async (item) => {
            if (typeof item === "object") {
              floorImageArrayObject.push(item);
            } else {
              floorImageArrayString.push(item);
            }
          });
          let floorimageArray = await GetImagePath(
            floorImageArrayObject,
            dispatch
          );
          let propertyTypeArray = [];
          let outdoorFeaturesArray = [];
          let indoorFeaturesArray = [];
          let climateControlEnergyArray = [];
          form?.propertyType?.map((item) => {
            propertyTypeArray.push(item._id);
          });
          form?.outdoorFeatures?.map((item) => {
            outdoorFeaturesArray.push(item._id);
          });
          form?.indoorFeatures?.map((item) => {
            indoorFeaturesArray.push(item._id);
          });
          form?.climateControlEnergy?.map((item) => {
            climateControlEnergyArray.push(item._id);
          });
          // addressArray.push(form.address)

          let payload = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryCode: form?.countryCode,
            mobile: form?.mobile,
            suburb: form?.suburb,
            city: form?.city,
            state: form?.state,
            zipCode: form?.zipCode,
            establishedProperty: form?.establishedProperty,
            buildYear: form?.buildYear,
            propertiesTitle: form?.propertiesTitle,
            address: form.address,
            price: form?.price,
            Unit: form?.Unit,
            Description: form?.Description,
            outdoorFeatures: outdoorFeaturesArray,
            indoorFeatures: indoorFeaturesArray,
            climateControlEnergy: climateControlEnergyArray,
            propertyType: propertyTypeArray,
            bedrooms:
              form?.bedrooms === "any"
                ? null
                : typeof form.bedrooms === "number"
                ? form.bedrooms
                : Number(form.bedrooms?.slice(0, 1)),
            bathrooms:
              form?.bathrooms === "any"
                ? null
                : typeof form.bathrooms === "number"
                ? form?.bathrooms
                : Number(form.bathrooms?.slice(0, 1)),
            carSpaces:
              form?.carSpaces === "any"
                ? null
                : typeof form.carSpaces === "number"
                ? form?.carSpaces
                : Number(form.carSpaces?.slice(0, 1)),
            landSize: form?.landSize,
            image: [...addimageArray, ...addimageArraystring],
            addFloorplan: [...floorimageArray, ...floorImageArrayString],
            postType: "SELLER",
            password: form?.password,
          };
          if (location?.pathname === "/seller-form") {
            dispatch(createBuyerAction(payload, () => setPage(3)));
          } else {
            dispatch(editPostAction(payload, id, () => setPage(3)));
          }
          setOtpVerification(false);
        }
      });
      setOtpVerification(false);
    }
  };
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSendOtpRequest();
  };
  useEffect(() => {
    if (otpVerification === true) {
      setCounter(60);
    }
  }, [otpVerification]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <>
      <>
        <div className="renter-form-container pt-6 bg-green-light">
          <div className="py-7 w-full">
            <ContainerBox>
              <FormTopHeading
                heading="Confirmation details"
                description="​Please make sure all details are correct and then submit for verification "
              />
              <div>
                {emailCheck === false ? (
                  <h2 className="text-center mb-5 mt-0 p-0 m-0 lg:mb-8 md:mb-6 text-main">
                    Your Account has been created Successfully
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </ContainerBox>
            <ContentArea>
              <div className="grid p-0 m-0 ">
                <ConfirmContactDetails data={form} />

                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Property details
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={TitleIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.propertiesTitle}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={CalendarIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {typeof form?.buildYear === "object"
                        ? moment(form?.buildYear).format("YYYY")
                        : form?.buildYear}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={LocationIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.address[0]?.streetAddress}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12 mb-2">
                  <div className="flex align-items-center">
                    <img src={PriceIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      Asking Price ($) - {formatter.format(form?.price)}
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-12 mb-3">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Notes
                  </h2>
                  <p className="text-black font-medium p-0 m-0 line-height-3 font-semibold">
                    {form?.Description ? form?.Description : "N/A"}
                  </p>
                </div>

                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-lg p-0 m-0 font-bold">
                    Photos{" "}
                  </h2>
                </div>
                <div className="overflow-auto flex mb-3 w-full">
                  {form?.image?.length > 0 &&
                    form?.image?.map((item, index) => {
                      return (
                        <div className="col-12 md:col-2">
                          <div className="" style={{ height: "114px" }}>
                            <img
                              src={
                                typeof item?.upload === "string"
                                  ? Constants.BASE_URL + `${item?.upload}`
                                  : typeof item?.upload === "object" &&
                                    item?.upload !== null
                                  ? window.URL.createObjectURL(item?.upload)
                                  : Constants.BASE_URL + `${item}`
                              }
                              className="seller-image w-full h-full border-round-xl"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-lg p-0 m-0 font-bold">
                    Floorplan{" "}
                  </h2>
                </div>
                <div className="overflow-auto flex mb-3 w-full">
                  {form?.addFloorplan?.length > 0 &&
                    form?.addFloorplan?.map((item) => {
                      return item?.upload !== "" ? (
                        <div className="col-12 md:col-2">
                          <div className="" style={{ height: "114px" }}>
                            <img
                              src={
                                typeof item?.upload === "string"
                                  ? Constants.BASE_URL + `${item?.upload}`
                                  : typeof item?.upload === "object" &&
                                    item?.upload !== null
                                  ? window.URL.createObjectURL(item?.upload)
                                  : Constants.BASE_URL + `${item}`
                              }
                              className="seller-image w-full h-full border-round-xl"
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          N/A
                        </span>
                      );
                    })}
                </div>

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Property type  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Property type
                  </h2>
                </div>
                {form?.propertyType?.length > 0 &&
                  form?.propertyType?.map((item, index) => {
                    return (
                      <div className="col-12 md:col-6  mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.propertyType}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* bedrooms,bathrooms,carspace section */}
                <PropertySpaceDetail data={form} />

                {/* Land Size section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Land Size
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={LandIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.landSize?.min}㎡{" "}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* New or established property  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    New or established property
                  </h2>
                </div>
                <div className="col-12 md:col-6  mb-3">
                  <div className="flex align-items-center">
                    <img src={AllTypeIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black text-lg font-semibold">
                      {form?.establishedProperty === "established"
                        ? "Established"
                        : form?.establishedProperty === "new"
                        ? "New"
                        : form?.establishedProperty}
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Outdoor features  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Outdoor features
                  </h2>
                </div>
                {form?.outdoorFeatures?.length > 0 ? (
                  form?.outdoorFeatures?.map((item, index) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Indoor features  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Indoor features
                  </h2>
                </div>
                {form?.indoorFeatures?.length > 0 ? (
                  form?.indoorFeatures?.map((item) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Climate control & energy Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Climate control & energy
                  </h2>
                </div>
                {form?.climateControlEnergy?.length > 0 ? (
                  form?.climateControlEnergy?.map((item) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr p-0 m-0" />
                </div>
              </div>
              <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                <div
                  className="col-12 md:col-3 md:text-left lg:col-2 mb-3 "
                  onClick={gotoBtn}
                >
                  <div className="no-underline">
                    <ButtonComponent
                      label="BACK TO EDIT"
                      className="md:text-left bg-transparent border-0 text-black"
                      onClick={() => setPage(1)}
                    ></ButtonComponent>
                  </div>
                </div>
                <div className="col-12 md:col-5 lg:col-4 ">
                  <div className="no-underline lg:ml-5" onClick={gotoBtn}>
                    <ButtonComponent
                      onClick={() => handleSubmit()}
                      label={id ? "SAVE" : "SUBMIT FOR VERIFICATION"}
                      className="bg-main text-light-cyan  border-0 lg:w-9 lg:mx-6"
                      style={{ color: "rgba(143, 154, 153, 1)" }}
                    ></ButtonComponent>
                  </div>
                </div>
              </div>
            </ContentArea>
          </div>
        </div>
      </>
      <div className="bookProfile-otp">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium p-0 m-0">
                We will send you an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on this number
              </p>
            </div>
            <div className="grid p-0 m-0">
              <div className="col-12 md:col-12 p-0 m-0">
                <p className="text-lg font-semibold text-dark ml-1">
                  Enter your mobile number
                </p>
                <CustomPhoneInput
                  code={form?.countryCode}
                  setCode={setCode}
                  col={12}
                  value={form?.mobile}
                  disabled
                />
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0 ">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning  text-sm font-semibold lg:w-full md:w-full w-10 "
                  onClick={() => setVisible(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SEND CODE"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={() => {
                      handleSendOtpRequest();
                    }}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
        <Dialog
          visible={otpVerification}
          style={{ width: "100vw" }}
          onHide={() => setOtpVerification(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium ">
                We have sent an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on your number
              </p>
            </div>
            <div className="grid p-0 m-0 ">
              <div className="col-12 md:col-12 flex justify-content-center p-0 m-0">
                <div className="">
                  <OtpInput
                    value={otp}
                    onChange={(e) => hadleChangeOtp(e)}
                    numInputs={4}
                    inputStyle={{
                      width: "48px",
                      height: "48px",
                      border: "1px solid #8F9A99",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      margin: "10px",
                    }}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {error?.otp && (
                    <small className="p-error text-sm ml-2 mt-2">
                      {error?.otp}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-12 text-center  mb-4 mt-2 ">
                <p className="text-lg font-medium text-black text-center p-0 m-0">
                  {counter !== 0 ? (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <span className="text-main font-semibold text-lg">
                        {counter}
                      </span>
                      <span className="text-main font-semibold text-lg">s</span>
                    </>
                  ) : (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <cod
                        className="text-main font-semibold text-lg cursor-pointer"
                        onClick={() => resendOtp()}
                      >
                        Resend code
                      </cod>
                    </>
                  )}
                </p>
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning lg:w-full md:w-full w-10 text-sm font-semibold "
                  onClick={() => setOtpVerification(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SUBMIT"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={handleVerifyOtp}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
      </div>

      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
