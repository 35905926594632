import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { termconditionAction } from "../../redux/actions/profileAction";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
import { useEffect } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import { useNavigate } from "react-router-dom";
const TermCondition = ({ SetSelectProfile, selectProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //     dispatch(termconditionAction())
  // }, [])
  const termConditionData = useSelector(
    (state) => state.termCondition.termConditionProfile
  );
  return (
    <>
      <ContainerBox>
        <div className="pt-8 mt-4 mb-6">
          <h1
            className="font-bold "
            style={{ fontSize: "50px", fontFamily: "Inter", color: "#0F2137" }}
          >
            Terms & Conditions
          </h1>
          {/* <p className="font-normal text-xl" style={{color:"#343D48"}}>{termConditionData?.paragraphText}</p> */}
          {selectProfile === true ? (
            <div className="landing-page-modal">
              <CustomDialog
                visible={selectProfile}
                onHide={() => SetSelectProfile(false)}
              >
                <PickProfile
                  SetSelectProfile={SetSelectProfile}
                  selectProfile={selectProfile}
                />
              </CustomDialog>
            </div>
          ) : (
            ""
          )}
          <p>
            Thank you for visiting Our Website. Our Website is owned and
            operated by Aelmusty Pty Ltd ACN 671 632 330 trading as The Buyers
            Place. By accessing and/or using Our Website and Our Services, you
            agree to these Terms, which include our
            <cod
              className="text-main underline text-sm font-semibold cursor-pointer"
              onClick={() => {
                navigate("/privacy-policy");
                SetSelectProfile(false);
              }}
            >
              &nbsp;Privacy Policy
            </cod>
            . You should review our Privacy Policy and these Terms carefully and
            immediately cease using Our Website if you do not agree to these
            Terms.{" "}
          </p>
          <p>
            In these Terms, 'us', 'we' and 'our' means Aelmusty Pty Ltd ACN 671
            632 330 trading as The Buyers Place and ‘you’ and ‘your’ means you
            as a User of Our Website.
          </p>
          <p>
            The purpose of Our Website is to simplify the way Buyers and Sellers
            of property connect. We provide Connection Services, market Property
            Buying Services to Buyers and provide referrals to other property
            purchase related third party professional service providers. Our
            Website facilitates connections between Buyers and Sellers by
            allowing Buyers to publish a 'Buyer Brief' that highlights details
            of property they are looking to buy and also provides some
            information about themselves, allowing Sellers to search and view
            those details on the 'Buyers Brief'.
          </p>
          <p>
            We may, at our discretion, amend these Terms at any time by
            publishing any amended terms and conditions on Our Website, and such
            modifications will be effective as soon as they are posted.
          </p>
          <div>
            <h3>1. Registration</h3>
            <p>
              You must be a registered member to access certain features of Our
              Website.{" "}
            </p>
            <p>
              When you register and activate your account, you will provide us
              with personal information such as your name, email address,
              telephone number etc. You must ensure that this information is
              accurate and current. We will handle all personal information we
              collect in accordance with our{" "}
              <cod
                className="text-main underline text-sm font-semibold cursor-pointer"
                onClick={() => {
                  navigate("/privacy-policy");
                  SetSelectProfile(false);
                }}
              >
                &nbsp;Privacy Policy
              </cod>{" "}
            </p>
            <p>
              When you register and activate your account, we will provide you
              with a user name and password. You are responsible for keeping
              this user name and password secure and are responsible for all use
              and activity carried out under this user name.
            </p>
            <p>To create an account, you must be:</p>
            <ul className="terms-list">
              <li>at least 18 years of age;</li>
              <li>
                possess the legal right and ability to enter into a legally
                binding agreement with us; and
              </li>
              <li>
                agree and warrant to use the website in accordance with these
                Terms.]
              </li>
            </ul>
          </div>
          <div>
            <h3>2. Collection Notice </h3>
            <p>
              We collect personal information about you in order to process your
              registration, to market and provide Our Services to you and for
              purposes otherwise set out in our Privacy Policy at [insert web
              address].{" "}
            </p>
            <p>
              We may disclose that information to third parties that help us
              market and deliver Our Services (including information technology
              suppliers, communication suppliers and our business partners) or
              as required by law. If you do not provide this information, we may
              not be able to market or provide all of Our Services to you. We
              may also disclose your personal information to recipients that are
              located outside of Australia, including to reputable software and
              service providers who store data in various countries throughout
              the world.
            </p>
            <p>
              Our Privacy Policy explains: (i) how we store and use, and how you
              may access and correct your personal information; (ii) how you can
              lodge a complaint regarding the handling of your personal
              information; and (iii) how we will handle any complaint. If you
              would like any further information about our privacy policies or
              practices, please contact us at info@thebuyersplace.com.au.{" "}
            </p>
            <p>
              By providing your personal information to us, you consent to the
              collection, use, storage and disclosure of that information as
              described in the Privacy Policy and these Terms.{" "}
            </p>
          </div>
          <div>
            <h3>3. Accuracy, completeness and timeliness of information</h3>
            <p>
              The information on Our Website is not comprehensive and is
              intended to provide a summary of the subject matter covered. While
              we use all reasonable attempts to ensure the accuracy and
              completeness of the information on Our Website, to the extent
              permitted by law, including the Australian Consumer Law, we make
              no warranty regarding the information on this website. You should
              monitor any changes to the information contained on Our Website
            </p>
            <p>
              We are not liable to you or anyone else if interference with or
              damage to your computer systems occurs in connection with the use
              of Our Website or a linked website. You must take your own
              precautions to ensure that whatever you select for your use from
              Our Website is free of viruses or anything else (such as worms or
              Trojan horses) that may interfere with or damage the operations of
              your computer systems.
            </p>
            <p>
              We may, from time to time and without notice, change or add to Our
              Website (including the Terms) or the information, products or
              services described in it. However, we do not undertake to keep Our
              Website updated. We are not liable to you or anyone else if errors
              occur in the information on Our Website or if that information is
              not up-to-date
            </p>
          </div>
          <div>
            <h3>4. Promotions and competitions</h3>
            <p>
              For certain campaigns, promotions or contests, additional terms
              and conditions may apply. If you want to participate in such a
              campaign, promotion or contest, you need to agree to the relevant
              terms and conditions applicable to that campaign, promotion or
              contest. In case of any inconsistency between such terms and
              conditions and these Terms, those terms and conditions will
              prevail.
            </p>
          </div>
          <div>
            <h3>5. Linked sites</h3>
            <p>
              Our Website may contain links to websites operated by third
              parties. Those links are provided for convenience and may not
              remain current or be maintained. Unless expressly stated
              otherwise, we do not endorse and are not responsible for the
              content on those linked websites and have no control over or
              rights in those linked websites.{" "}
            </p>
          </div>
          <div>
            <h3>6. Third party links and advertising</h3>
            <p>
              Our Website includes advertisements, hyperlinks and pointers to
              websites operated by third parties. Links to third party websites
              include, without limitation, links to our platform of some of our
              real estate agency customers. Those third party websites do not
              form part of Our Website and are not under our control or
              responsibility. When you link to those websites you leave Our
              Website and do so entirely at your own risk. We make no warranty
              as to the accuracy or reliability of the information contained on
              any third party websites, and we disclaim all liability and
              responsibility for any direct or indirect loss or damage which may
              be suffered by you through relying on anything contained on or
              omitted from such third party websites. A display of advertising
              does not imply an endorsement or recommendation by us.
            </p>
            <p>
              We may receive a referral fee and/or other financial benefits from
              a third party in connection with your purchase of products or
              services from those third parties following a referral from us.
            </p>
          </div>
          <div>
            <h3>7. Third Party Content</h3>
            <p>
              Our Website contains Third Party Content. Representations made in
              Third Party Content are representations by those third parties and
              not by us. We are not responsible for, do not endorse and make no
              representations either expressly or impliedly concerning Third
              Party Content. Third Party Content does not represent our views.
              You should always make your own enquiries before relying on Third
              Party Content.
            </p>
          </div>
          <div>
            <h3>8. Editorial content</h3>
            <p>
              Any editorial content or articles on Our Website are of a general
              nature only and do not consider your personal objectives,
              financial situation or particular needs. Editorial content should
              not be regarded as advice or relied upon by you or any other
              person and we recommend that you seek professional advice before
              acting on the content. For the avoidance of doubt, editorial
              content provided by third party authors that are not employed by
              us is deemed to be Third Party Content for the purposes of the
              section above.
            </p>
          </div>
          <div>
            <h3>9. Intellectual property rights</h3>
            <p>
              Unless otherwise indicated, we own or license from third parties
              all rights, title and interest (including copyright, designs,
              patents, trademarks and other intellectual property rights) in Our
              Website and Our Content.
            </p>
            <p>
              Your use of Our Website and use of and access to Our Content does
              not grant or transfer any rights, title or interest to you in
              relation to Our Website or Our Content. However we do grant you a
              licence to access Our Website and view Our Content on these Terms
              and, where applicable, as expressly authorised by us and/or our
              third party licensors.
            </p>
            <p>
              Any reproduction or redistribution of Our Website or Our Content
              is prohibited and may result in civil and criminal penalties. In
              addition, you must not copy Our Content to any other server,
              location or support for publication, reproduction or distribution
              is expressly prohibited.{" "}
            </p>
            <p>
              All other use, copying or reproduction of Our Website, Our Content
              or any part of it is prohibited, except to the extent permitted by
              law.{" "}
            </p>
          </div>
          <div>
            <h3>10. No commercial use</h3>
            <p>
              Our Website is for your personal, non-commercial use only. You may
              not modify, copy, distribute, transmit, display, perform,
              reproduce, publish, license, commercially exploit, create
              derivative works from, transfer, or sell any Content, software,
              products or services contained within Our Website. You may not use
              Our Website, or any of its Content, to further any commercial
              purpose, including any advertising or advertising revenue
              generation activity on your own website.
            </p>
          </div>
          <div>
            <h3>11. Unacceptable activity</h3>
            <p>
              You must not do any act that we would deem to be inappropriate, is
              unlawful or is prohibited by any laws applicable to our website,
              including but not limited to:
            </p>
            <ul className="terms-list">
              <li>
                being respectful and courteous at all times in your dealings
                with our staff and other users of Our Website;
              </li>
              <li>
                any act that would constitute a breach of either the privacy
                (including uploading private or personal information without an
                individual's consent) or any other of the legal rights of
                individuals;
              </li>
              <li>
                using Our Website to defame or libel us, our employees or other
                individuals;
              </li>
              <li>
                uploading files that contain viruses that may cause damage to
                our property or the property of other individuals;
              </li>
              <li>
                posting or transmitting to Our Website any non-authorised
                material including, but not limited to, material that is, in our
                opinion, likely to cause annoyance, or which is defamatory,
                racist, obscene, threatening, pornographic or otherwise or which
                is detrimental to or in violation of our systems or a third
                party's systems or network security.{" "}
              </li>
            </ul>
            <p>
              If we allow you to post any information to Our Website, we have
              the right to take down this information at our sole discretion and
              without notice.
            </p>
          </div>
          <div>
            <h3>12. Buyer Briefs</h3>
            <p>
              We may at any time, without liability to you, remove, alter or
              disable access to your Buyer Brief in our sole discretion without
              prior notice to you. Without limiting the previous sentence, we
              may remove or disable access to your Buyer Brief if we consider
              that:
            </p>
            <ul className="terms-list">
              <li>your Buyer Brief is in breach of any law or regulation;</li>
              <li>
                your Buyer Brief infringes the intellectual property rights of
                any third party;
              </li>
              <li>
                it is required to do so by a regulatory body or any relevant
                authority pursuant to an interim or final take-down notice;
              </li>
              <li>
                your Buyer Brief is :
                <ul>
                  <li>misleading or deceptive;</li>
                  <li>
                    inappropriate having regard to the purpose of our platform;
                  </li>
                  <li>likely to cause offence;</li>
                  <li>materially incorrect;</li>
                  <li>obscene</li>
                  <li>defamatory</li>
                  <li>otherwise unlawful; or</li>
                  <li>
                    corrupted, due to the presence of a virus or other disabling
                    code
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              You retain all of your ownership rights in your Buyer Brief. We
              are under no obligation to treat your Buyer Brief as proprietary
              information.
            </p>
            <p>
              To the extent that your Buyer Brief (or any part of it) is
              proprietary in nature, you grant us a worldwide, nonexclusive,
              royalty-free, perpetual, transferable and irrevocable licence to
              use, reproduce, modify, adapt, translate, distribute, publish,
              create derivative works from and display and publicly perform your
              Buyer Brief throughout the world in any medium, whether currently
              in existence or not. You also grant each User a nonexclusive
              licence to use, reproduce, adapt, translate, distribute, prepare
              derivative works of, display and perform your Buyer Brief as
              permitted by us and these Terms.
            </p>
            <p>
              You grant us, in respect of your Buyer Brief the right to use your
              name or the name you submit with the Buyer Brief, and, the right
              to represent and warrant that:
            </p>
            <ul className="terms-list">
              <li>
                you own and control all of the rights to your Buyer Brief; or
              </li>
              <li>
                you have the lawful right including all necessary licences,
                rights, consents, and permissions to use and authorise us to
                display the Buyer Brief.
              </li>
            </ul>
            <p>
              For any Buyer Brief that you may retain moral rights in, you
              declare that:
            </p>
            <ul className="terms-list">
              <li>
                you do not require that any personally identifying information
                be used in connection with your Buyer Brief, or any derivative
                work, upgrade or update of your Buyer Brief; and
              </li>
              <li>
                You understand that when accessing Our Website, you may be
                exposed to the User Content of other Users. You acknowledge and
                agree that we do not have control of and is not responsible for
                User Content. You release us from any claims that you could
                assert against us relating to or in connection with User
                Content.
              </li>
            </ul>
            <p>You represent and warrant that:</p>
            <ul className="terms-list">
              <li>
                you have the lawful right including all necessary licences,
                rights, consents, and permissions to use and authorise us to
                display your Buyer Brief; and
              </li>
              <li>
                no part of your Buyer Brief:
                <ul>
                  <li>
                    infringes the intellectual property rights of any third
                    party, and you agree to pay all royalties, fees or other
                    monies payable by reason of your Buyer Profile;
                  </li>
                  <li>is misleading or deceptive;</li>
                  <li>is materially incorrect;</li>
                  <li>is likely to cause offence;</li>
                  <li>
                    is directly or indirectly involve the advertising or
                    marketing of any products or services;
                  </li>
                  <li>
                    is obscene, including pornographic, hateful, racially or
                    ethnically offensive material;
                  </li>
                  <li>is defamatory;</li>
                  <li>
                    is otherwise unlawful or encourage unlawful conduct; or
                  </li>
                  <li>
                    is otherwise inappropriate having regard to the purpose of
                    Our Website.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h3>13. Warranties and disclaimers</h3>
            <p>
              To the maximum extent permitted by law, including the Australian
              Consumer Law, we make no warranties or representations about Our
              Website or Our Content, including but not limited to warranties or
              representations that they will be complete, accurate or
              up-to-date, that access will be uninterrupted or error-free or
              free from viruses, or that Our Website will be secure. We reserve
              the right to restrict, suspend or terminate without notice your
              access to Our Website, Our Content, or any feature of Our Website
              at any time without notice and we will not be responsible for any
              loss, cost, damage or liability that may arise as a result.
            </p>
          </div>
          <div>
            <h3>14. Liability</h3>
            <p>
              To the maximum extent permitted by law, including the Australian
              Consumer Law, in no event shall we be liable for any direct and
              indirect loss, damage or expense – irrespective of the manner in
              which it occurs – which may be suffered due to your use of Our
              Website and/or the information or materials contained on it, or as
              a result of the inaccessibility of Our Website and/or the fact
              that certain information or materials contained on it are
              incorrect, incomplete or not up-to-date.
            </p>
          </div>
          <div>
            <h3>15. Jurisdiction and governing law</h3>
            <p>
              Your use of Our Website and these Terms are governed by the law of
              New South Wales and you submit to the non-exclusive jurisdiction
              of the courts exercising jurisdiction in New South Wales.
            </p>
          </div>
          <div>
            <h3>16. Definitions</h3>
            <p>In these Terms:</p>
            <p>
              <span className="font-bold">Buyer</span> means a person who is
              interested in buying residential property
            </p>
            <p>
              <span className="font-bold">Buyer Brief</span> means details of a
              Buyer and details of the residential property that that Buyer is
              interested in purchasing in the form of data, text, video, still
              images, audio or other material uploaded by a Buyer and hosted,
              shared, published, posted or stored on Our Website.
            </p>
            <p>
              <span className="font-bold">Connection Services</span> means
              services related to the facilitation of connections between Buyers
              and Sellers and includes allow Buyers to post Buyer Briefs.
            </p>
            <p>
              <span className="font-bold">Our Content</span> means any
              information including data, text, video, still images, audio or
              other material that we host, share, publish, post, store or upload
              on Our Website. Our Content does not include Buyer Briefs, User
              Content or Third Party Content.
            </p>
            <p>
              <span className="font-bold">Our Services</span> means the services
              we offer via Our Website including the Property Buying Services
              and the Connection Services.
            </p>
            <p>
              <span className="font-bold">Property Buying Services</span> means
              services that are directly or indirectly related to buying
              residential property including but not limited to mortgage broking
              services, buyer’s agency services, utilities connection and
              disconnection services, insurance, removal services, budgeting
              services etc.
            </p>
            <p>
              <span className="font-bold">Seller</span> means a person who is
              interested in selling residential property.
            </p>
            <p>
              <span className="font-bold">Terms</span> means these terms and
              conditions.
            </p>
            <p>
              <span className="font-bold">Third Party Content</span> means
              content provided to us by other parties.
            </p>
            <p>
              <span className="font-bold">User</span> means a person who access
              this website.
            </p>
            <p>
              <span className="font-bold">User Content</span> means any
              information including data, text, video, still images, audio or
              other material that we allow users of Our Website to host, share,
              publish, post, store or upload on Our Website as permitted under
              these Terms.
            </p>
          </div>
        </div>
      </ContainerBox>
    </>
  );
};

export default TermCondition;
