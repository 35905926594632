import { types } from "../types";
import api from "../../services/api";
import Constants from "../../services/constant";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";



export const myProfileAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", Constants.END_POINT.MYPROFILE)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.MY_PROFILE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const updateProfileAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("put", Constants.END_POINT.UPDATEPROFILE, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.UPDATE_PROFILE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const changePasswordAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.CHANGEPASSWORD, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_PASSWORD,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}


export const createContactAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.CREATECONTACT, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CREATE_CONTACT,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
        return res

    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const termconditionAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", Constants.END_POINT.TERMCONDITION)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.TERM_CONDITION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}
export const PrivacyPolicyAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", Constants.END_POINT.PRIVACYPOLICY)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.PRIVACY_POLICY,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}
