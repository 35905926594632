import React, { useEffect, useState } from "react";
import { Routes, Route, HashRouter, useLocation } from "react-router-dom";
import { Index as Layout } from "../layout";
import Home from "../views/home";
import SignUp from "../pages/signup";
import SignIn from "../pages/login";
import Forgot from "../pages/forgot";
import ResetPassword from "../pages/forgot/ResetPassword";
import SetPassword from "../pages/forgot/SetPassword";
import { BuyerForm } from "../views/profile-forms/buyer-form/BuyerForm";
import { ConfirmDetails } from "../views/profile-forms/buyer-form/ConfirmDetails";
import ProfilePreview from "../views/profile-forms/buyer-form/ProfilePreview";
import SuccessfullProfile from "../views/profile-forms/buyer-form/SuccessfullProfile";
import { SellerForm } from "../views/profile-forms/seller-form/SellerForm";
import { SellerConfirmDetails } from "../views/profile-forms/seller-form/SellerConfirmDetails";
import SellerSuccessfullProfile from "../views/profile-forms/seller-form/SellerSuccessfullProfile";
import { RenterForm } from "../views/profile-forms/renter-forms/RenterForms";
import { RenterConfirmDetails } from "../views/profile-forms/renter-forms/RenterConfirmDetails";
import RenterSuccessfullProfile from "../views/profile-forms/renter-forms/RenterSuccessfullProfile";
import { SellerMoreInfo } from "../views/profile-forms/seller-form/SellerMoreInfo";
import { BuyerMoreInfo } from "../views/profile-forms/buyer-form/BuyerMoreInfo";
import { RenterMoreInfo } from "../views/profile-forms/renter-forms/RenterMoreInfo";
import PricingPlan from "../views/home/PricingPlan";
import AllPost from "../views/all-post/AllPost";
import Loader from "../shared/Loader";
import ToastContainer from "../shared/ToastContainer";
import Siderbar from "../components/sidebar/Siderbar";
import Profile from "../views/user-profile-page/Profile";
import Post from "../views/user-profile-page/Post";
import ChangePassword from "../views/user-profile-page/ChangePassword";
import Properties from "../views/user-profile-page/Properties";
import Subscription from "../views/user-profile-page/Subscription";
import EditProfile from "../views/user-profile-page/EditProfile";
import NewPassword from "../views/user-profile-page/NewPassword";
import TermCondition from "../pages/TermCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CreateBrief from "../views/user-profile-page/CreateBrief";
import Aboutus from "../pages/Aboutus/aboutus";
import { Contact } from "../pages/Contactus/contactus";

export default function Index() {
  const forgetUrl = "/new-password/:token";
  const [selectProfile, SetSelectProfile] = useState();

  function External() {
    window.location.href = "https://www.thebuyersplace.com.au/";
    return null;
  }

  return (
    <div>
      <Loader />
      <ToastContainer />
      <Layout SetSelectProfile={SetSelectProfile} selectProfile={selectProfile}>
        <Routes>
          <Route
            path="*"
            element={<External />}
            // element={
            //   <Home
            //     SetSelectProfile={SetSelectProfile}
            //     selectProfile={selectProfile}
            //   />
            // }
          />
          <Route
            path="/sign-up"
            element={
              <SignUp
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/sign-in"
            element={
              <SignIn
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Forgot
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/reset-password"
            element={
              <ResetPassword
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path={forgetUrl}
            element={
              <SetPassword
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/buyer-form"
            element={
              <BuyerForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/buyer-form/:id"
            element={
              <BuyerForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/renter-form"
            element={
              <RenterForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/renter-form/:id"
            element={
              <RenterForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/confirm-details"
            element={
              <ConfirmDetails
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/seller-form"
            element={
              <SellerForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/seller-form/:id"
            element={
              <SellerForm
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/profile-preview"
            element={
              <ProfilePreview
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/successfull-profile"
            element={
              <SuccessfullProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/seller-confirmation-details"
            element={
              <SellerConfirmDetails
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/seller-successfull-message"
            element={
              <SellerSuccessfullProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/renter-confirm-details"
            element={
              <RenterConfirmDetails
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/renter-successfull-message"
            element={
              <RenterSuccessfullProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/all-post"
            element={
              <AllPost
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/seller-details/:id"
            element={
              <SellerMoreInfo
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/buyer-details/:id"
            element={
              <BuyerMoreInfo
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/renter-details/:id"
            element={
              <RenterMoreInfo
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route path="/pricing-plan" element={<PricingPlan />} />
          <Route path="/sidebar" element={<Siderbar />} />
          <Route
            path="/create-brief"
            element={
              <CreateBrief
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/post"
            element={
              <Post
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/change-password"
            element={
              <ChangePassword
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/my-properties"
            element={
              <Properties
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/my-subscription"
            element={
              <Subscription
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/term-condition"
            element={
              <TermCondition
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/about-us"
            element={
              <Aboutus
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
          <Route
            path="/contact-us"
            element={
              <Contact
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            }
          />
        </Routes>
      </Layout>
    </div>
  );
}
