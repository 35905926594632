import React from "react";
import { ContainerBox } from "./ContainerBox";

export const FormTopHeading = ({ heading, description }) => {
  return (
    <ContainerBox>
      <div className="grid justify-content-center  p-0 m-0">
        <div className="lg:col-12 md:col-12 md:col-12 text-center">
          <h1
            className="heading_text font-semibold text-dark p-0 m-0 mb-3"
            style={{ fontSize: "56px" }}
          >
            {heading}
          </h1>
          <p className="text-lg text-black line-height-3 font-medium p-0 m-0 mb-3">{description}</p>
        </div>
      </div>
    </ContainerBox>
  );
};
