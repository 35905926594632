import React, { useEffect, useState } from "react";
import GoogleIcon from "../../assets/icons/google.png";
import LoginImg from "../../assets/images/login-banner.png";
import {
  ButtonOutlined,
  SocialLoginButton,
} from "../../components/buttons/button";
import { ContainerBox } from "../../shared/ContainerBox";
import {
  CustomInput,
  CustomPassword,
  CustomPhoneInput,
} from "../../components/input/AllInput";
import {
  googlelogin,
  signupAction,
  socialLogin,
} from "../../redux/actions/signupAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidation";
import { useLocation } from "react-router-dom";
import { googleLoginAction } from "../../redux/actions/socialAction";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
import { emailValidation } from "../../utils/regex";
import { customPasswordCheck } from "../../utils/javascript";
import { useGoogleLogin } from "@react-oauth/google";

export default function SignUp({ SetSelectProfile, selectProfile }) {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryCode: "",
    mobile: "",
    suburb: "",
    streetAddress: "",
    role: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "mobile") {
      if (isNaN(value) == false) {
        const formErrors = allValidations(name, value, form);
        setForm((prev) => ({ ...prev, [name]: value, formErrors }));
      }
    } else {
      const formErrors = allValidations(name, value, form);
      setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };
  const [code, setCode] = useState("+61");
  useEffect(() => {
    if (code) {
      setForm({
        ...form,
        countryCode: code,
      });
    } else {
      setForm({
        ...form,
        countryCode: "+61",
      });
    }
  }, [code, form.mobile]);
  useEffect(() => {
    setForm({
      ...form,
      role: data,
    });
  }, [data]);
  const formErrorCheck = () => {
    const valid = customPasswordCheck(form?.password);
    let formErrors = {};
    if (form?.firstName === "" || form?.firstName === undefined) {
      formErrors.firstName = "Please Enter First Name";
    }
    if (form.lastName === "" || form.lastName === undefined) {
      formErrors.lastName = "Please Enter Last Name";
    }
    if (form.email === "" || form.email === undefined) {
      formErrors.email = "Please Enter Email";
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
    }
    if (form.mobile === "" || form.mobile === undefined) {
      formErrors.mobile = "Please Enter Valid Mobile Number";
    } else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only";
    }
    if (form.streetAddress === "") {
      formErrors.streetAddress = "Please Enter Full Address";
    }
    if (form.suburb === "") {
      formErrors.suburb = "Please Enter Full Address";
    }
    if (form?.password === "") {
      formErrors.password = "Please Enter Password";
    } else if (valid) {
      formErrors.password = valid;
    }
    if (form?.confirmPassword === "") {
      formErrors.confirmPassword = "Please Enter Confirm Password";
    } else if (form?.password !== form?.confirmPassword) {
      formErrors.confirmPassword = "Password and Confirm Password are not same";
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (!formErrorCheck()) {
      return false;
    } else {
      dispatch(signupAction(form, () => navigate("/profile")));
    }
  };

  const responseGoogle = (response) => {
    let obj = {
      email: response.profileObj.email,
      socialId: response.profileObj.googleId,
      loginType: "google",
    };
    localStorage.setItem("realState", JSON.stringify(response.tokenId));
    dispatch(googleLoginAction(obj, () => navigate("/")));
  };
  const responseFacebook = (response) => {};
  const componentClicked = () => {};
  const handleChangeAddress = (address) => {
    setShow(true);
    const formErrors = allValidations("suburb", address, form);
    setForm({
      ...form,
      suburb: address,
      streetAddress: address,
      formErrors,
    });
  };
  const handleSelect = (address) => {
    let obj = {
      state: "",
      city: "",
      zipCode: "",
      suburb: "",
    };
    geocodeByAddress(address)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.zipCode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),

          setForm({
            ...form,
            state: obj.state,
            zipCode: obj.zipCode,
            suburb: address?.includes(obj?.zipCode)
              ? address
              : address + " " + obj?.zipCode,
            streetAddress: address?.includes(obj?.zipCode)
              ? address
              : address + " " + obj?.zipCode,
            city: obj.city,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (respose) => {
      dispatch(googlelogin(respose)).then((res) => {
        if (res.data.sub) {
          let payload = {
            email: res.data.email,
            socialId: res.data.sub,
            loginType: "google",
            firstName: res.data.given_name,
            lastName: res.data.family_name,
          };
          dispatch(socialLogin(payload)).then((res) => {
            if (res) {
              navigate("/profile");
            } else {
              navigate("/sign-in");
            }
          });
        }
      });
    },
  });

  return (
    <>
      <div className="sign-up_container">
        <div className=" bg-light-gray pt-8  ">
          <ContainerBox>
            <div className="grid p-0 m-0  lg:py-7 md:py-7 py-5  mr-2 ml-2 ">
              <div
                className="col-12 md:col-6 p-0 lg:blcok md:block hidden"
                style={{ maxHeight: "1190px" }}
              >
                <img
                  src={LoginImg}
                  height={1193}
                  alt=""
                  className="w-full sign_up_img h-h-6rem lg:h-full"
                ></img>
              </div>
              <div className="col-12 md:col-6 bg-light">
                <div className="sign-up content ">
                  <h1 className="text-center text-4xl text-dark font-semibold mb-5 ">
                    Sign Up
                  </h1>
                  <div className="grid p-2 lg:p-4 md:p-3 ">
                    <CustomInput
                      placeholder="First name"
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      required
                      errorMessage={form?.formErrors?.firstName}
                    />
                    <CustomInput
                      placeholder="Last name"
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      required
                      errorMessage={form?.formErrors?.lastName}
                    />
                    <CustomInput
                      placeholder="Email"
                      label="Email"
                      col="12"
                      name="email"
                      onChange={handleChange}
                      required
                      errorMessage={form?.formErrors?.email}
                    />
                    <CustomPhoneInput
                      col="12"
                      extraClassName="contact mb-3"
                      label="Phone"
                      placeholder="Number"
                      name="mobile"
                      onChange={handleChange}
                      setCode={setCode}
                      code={code}
                      required
                      errorMessage={form?.formErrors?.mobile}
                    />
                    <div className="col-12 md:col-12 lg:col-12 p-0 m-0">
                      <PlacesAutocomplete
                        value={form?.suburb}
                        onChange={handleChangeAddress}
                        onSelect={handleSelect}
                        searchOptions={{
                          componentRestrictions: { country: ["au"] },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="relative w-full">
                            <CustomInput
                              label="Full Address"
                              col="12"
                              required
                              errorMessage={form?.formErrors?.streetAddress}
                              value={form?.suburb}
                              {...getInputProps({
                                placeholder: "Full Address",
                              })}
                            />
                            {show === true ? (
                              <div className="autocomplete-dropdown-container absolute top-50 bg-white z-2 p-0 mt-5 shadow-1  mb-2 ">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                                    : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span className="">
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>

                    <CustomPassword
                      label="Password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      required
                      errorMessage={form?.formErrors?.password}
                    />
                    <CustomPassword
                      label="Confirm Password"
                      placeholder="Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      required
                      disabled={form.password.length === 0 ? true : false}
                      errorMessage={form?.formErrors?.confirmPassword}
                    />

                    <ButtonOutlined
                      label="SIGN UP"
                      className="mx-1 mb-1"
                      onClick={handleSubmit}
                    />
                    <div className="w-full px-1 my-4">
                      <hr
                        className="mb-5"
                        style={{ color: "rgba(143, 154, 153, 1)" }}
                      />
                      <SocialLoginButton
                        onClick={googleLoginHandler}
                        src={GoogleIcon}
                        label="CONTINUE WITH GOOGLE"
                        className=" bg-transparent font-semibold border-noround text-center text-dark mx-1"
                      />
                      {/* <SocialLoginButton
                        src={AppleIcon}
                        label="CONTINUE WITH APPLE"
                        className="bg-dark mx-1 text-gray-light"
                      /> */}
                      {/* <FacebookLogin
                      appId="1107060386930680"
                      fields="name,email,picture"
                      onClick={componentClicked}
                      callback={responseFacebook}
                      icon="fa-facebook"
                    /> */}
                      {/* <SocialLoginButton
                        src={FacebookIcon}
                        label="CONTINUE WITH FACEBOOK"
                        className=" bg-blue-light mx-1 text-gray-light "

                      /> */}
                      <div className="col-12 md:col-12">
                        <p className="text-center font-medium text-dark">
                          Already have an account?
                          <cod
                            className="text-main ml-1 text-lg font-medium cursor-pointer"
                            onClick={() => {
                              navigate("/sign-in");
                              gotoBtn();
                            }}
                          >
                            Login here
                          </cod>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContainerBox>
        </div>
        {selectProfile === true ? (
          <div className="landing-page-modal">
            <CustomDialog
              header={
                <h2
                  className="mt-0 font-semibold text-dark mt-3 ml-3"
                  style={{ fontSize: "32px" }}
                >
                  Pick a Profile to Create a Brief
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                </h2>
              }
              visible={selectProfile}
              onHide={() => SetSelectProfile(false)}
            >
              <PickProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            </CustomDialog>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
