import React, { useState, useEffect } from "react";
import { Index } from "../../../layout";
import ContentArea from "../../../shared/ContentArea";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import BedroomsIcon from "../../../assets/icons/bad (1).png";
import BathroomsIcon from "../../../assets/icons/tablelamp.png";
import CarIcon from "../../../assets/icons/car.png";
import CalendarIcon from "../../../assets/icons/calendar.png";
import FurnishedIcon from "../../../assets/icons/Furnished and Pets considered.png";
import { FormTopHeading } from "../../../shared/FormTopHeading";
import { ConfirmContactDetails } from "../CommonFeild";
import { createBuyerAction } from "../../../redux/actions/buyerAction";
import { useDispatch } from "react-redux";
import { mobileSendOtp, verifyOTP } from "../../../redux/actions/buyerAction";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editPostAction } from "../../../redux/actions/postAction";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { Dialog } from "primereact/dialog";
import OtpInput from "react-otp-input";
import { CustomPhoneInput } from "../../../components/input/AllInput";
import { isAuthenticated } from "../../../services/authService";
import PriceIcon from "../../../assets/icons/price.png";
export const RenterConfirmDetails = ({
  form,
  setPage,
  SetSelectProfile,
  selectProfile,
  emailCheck,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [otp, setOtp] = useState("");
  const [code, setCode] = useState("+61");
  const [error, setError] = useState({
    otp: "",
  });
  const navigate = useNavigate();
  const [counter, setCounter] = useState();
  const [visible, setVisible] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const handleSubmit = () => {
    let propertyTypeArray = [];
    let outdoorFeaturesArray = [];
    let indoorFeaturesArray = [];
    let climateControlEnergyArray = [];
    form?.propertyType?.map((item) => {
      propertyTypeArray.push(item._id);
    });
    form?.outdoorFeatures?.map((item) => {
      outdoorFeaturesArray.push(item._id);
    });
    form?.indoorFeatures?.map((item) => {
      indoorFeaturesArray.push(item._id);
    });
    form?.climateControlEnergy?.map((item) => {
      climateControlEnergyArray.push(item._id);
    });

    let payload = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      email: form?.email,
      countryCode: form?.countryCode,
      mobile: form?.mobile,
      suburb: form?.suburb,
      streetAddress: form?.streetAddress,
      city: form?.city,
      state: form?.state,
      zipCode: form?.zipCode,
      propertyType: propertyTypeArray,
      priceRange: form.priceRange,
      bedrooms:
        form?.bedrooms === "any"
          ? null
          : typeof form.bedrooms === "number"
          ? form.bedrooms
          : Number(form.bedrooms?.slice(0, 1)),
      bathrooms:
        form?.bathrooms === "any"
          ? null
          : typeof form.bathrooms === "number"
          ? form.bathrooms
          : Number(form.bathrooms?.slice(0, 1)),
      carSpaces:
        form?.carSpaces === "any"
          ? null
          : typeof form.carSpaces == "number"
          ? form.carSpaces
          : Number(form.carSpaces?.slice(0, 1)),
      purchaseTimeFrame: form?.purchaseTimeFrame,
      propertyRequierments: form?.propertyRequierments,
      outdoorFeatures: outdoorFeaturesArray,
      indoorFeatures: indoorFeaturesArray,
      climateControlEnergy: climateControlEnergyArray,
      postType: "RENTER",
      note: form?.note,
      address: form?.address,
      propertyMustHave: form?.propertyMustHave,
      password: form?.password,
    };
    if (!isAuthenticated()) {
      setVisible(true);
    } else if (location.pathname === "/renter-form") {
      dispatch(createBuyerAction(payload, () => setPage(3)));
    } else {
      dispatch(editPostAction(payload, id, () => setPage(3)));
    }
  };
  const handleSendOtpRequest = () => {
    const payload = {
      countryCode: form?.countryCode,
      mobile: form?.mobile,
    };
    dispatch(mobileSendOtp(payload)).then((res) => {
      if (res.success === true) {
        setVisible(false);
        setOtpVerification(true);
      }
    });
  };

  const getNumericValue = (value) => {
    return value.replace(/\D/g, "");
  };
  const hadleChangeOtp = (e) => {
    const numericValue = getNumericValue(e);

    setOtp(numericValue);
    setError({
      ...error,
      otp: null,
    });
  };
  const handleVerifyOtp = () => {
    if (otp === "" || otp === undefined) {
      setError({
        ...error,
        otp: otp === "" || otp === undefined ? "Please Enter OTP" : error.otp,
      });
      return false;
    } else {
      let payload = {
        otpCode: otp,
        countryCode: form?.countryCode,
        mobile: form?.mobile,
      };
      dispatch(verifyOTP(payload)).then(async (res) => {
        if (res.success === true) {
          let propertyTypeArray = [];
          let outdoorFeaturesArray = [];
          let indoorFeaturesArray = [];
          let climateControlEnergyArray = [];
          form?.propertyType?.map((item) => {
            propertyTypeArray.push(item._id);
          });
          form?.outdoorFeatures?.map((item) => {
            outdoorFeaturesArray.push(item._id);
          });
          form?.indoorFeatures?.map((item) => {
            indoorFeaturesArray.push(item._id);
          });
          form?.climateControlEnergy?.map((item) => {
            climateControlEnergyArray.push(item._id);
          });

          let payload = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryCode: form?.countryCode,
            mobile: form?.mobile,
            suburb: form?.suburb,
            streetAddress: form?.streetAddress,
            city: form?.city,
            state: form?.state,
            zipCode: form?.zipCode,
            propertyType: propertyTypeArray,
            priceRange: form.priceRange,
            bedrooms:
              form?.bedrooms === "any"
                ? null
                : typeof form.bedrooms === "number"
                ? form.bedrooms
                : Number(form.bedrooms?.slice(0, 1)),
            bathrooms:
              form?.bathrooms === "any"
                ? null
                : typeof form.bathrooms === "number"
                ? form.bathrooms
                : Number(form.bathrooms?.slice(0, 1)),
            carSpaces:
              form?.carSpaces === "any"
                ? null
                : typeof form.carSpaces == "number"
                ? form.carSpaces
                : Number(form.carSpaces?.slice(0, 1)),
            purchaseTimeFrame: form?.purchaseTimeFrame,
            propertyRequierments: form?.propertyRequierments,
            outdoorFeatures: outdoorFeaturesArray,
            indoorFeatures: indoorFeaturesArray,
            climateControlEnergy: climateControlEnergyArray,
            postType: "RENTER",
            note: form?.note,
            address: form?.address,
            propertyMustHave: form?.propertyMustHave,
            password: form?.password,
          };
          if (location.pathname === "/renter-form") {
            dispatch(createBuyerAction(payload, () => setPage(3)));
          } else {
            dispatch(editPostAction(payload, id, () => setPage(3)));
          }
          setOtpVerification(false);
        }
      });
      setOtpVerification(false);
    }
  };
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
    handleSendOtpRequest();
  };
  useEffect(() => {
    if (otpVerification === true) {
      setCounter(60);
    }
  }, [otpVerification]);
  return (
    <>
      <>
        <div className="renter-confirm-details-container  bg-green-light pt-6 ">
          <div className="py-7 w-full">
            <ContainerBox>
              <FormTopHeading
                heading="Confirmation details"
                description="​Please make sure all details are correct and then submit for verification  "
              />
              <div>
                {emailCheck === false ? (
                  <h2 className="text-center mb-5 mt-0 p-0 m-0 lg:mb-8 md:mb-6 text-main">
                    Your Account has been created Successfully
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </ContainerBox>
            <ContentArea>
              <div className="grid p-0 m-0 ">
                <ConfirmContactDetails data={form} />

                {/* Property type  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Property type
                  </h2>
                </div>
                {form?.propertyType?.length > 0 &&
                  form?.propertyType?.map((item, index) => {
                    return (
                      <div className="col-12 md:col-6  mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.propertyType}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Price
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={PriceIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.priceRange?.min && form?.priceRange?.max
                        ? `$${form?.priceRange?.min} - $${form?.priceRange?.max}`
                        : form?.priceRange?.min
                        ? `$${form?.priceRange?.min}`
                        : form?.priceRange?.max
                        ? `$${form?.priceRange?.max}`
                        : null}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* bedrooms,bathrooms,carspace section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Bedrooms
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={BedroomsIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.bedrooms === null ? "any" : form?.bedrooms}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Bathrooms Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Bathrooms
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img
                      src={BathroomsIcon}
                      width={20}
                      height={20}
                      alt=""
                    ></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.bathrooms === null ? "any" : form?.bathrooms}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Car spaces Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Car spaces
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center">
                    <img src={CarIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold">
                      {form?.carSpaces === null ? "any" : form?.carSpaces}
                    </span>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* New or established property  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Ready to Rent
                  </h2>
                </div>
                <div className="col-12 md:col-6  mb-3">
                  <div className="flex align-items-center">
                    <img src={CalendarIcon} width={20} height={20} alt=""></img>
                    <span className="ml-3 font-medium text-black font-semibold ">
                      {form?.purchaseTimeFrame}
                    </span>
                  </div>
                </div>

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* New or established property  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Property requierments
                  </h2>
                </div>
                {form?.propertyRequierments?.furnished === "furnished" &&
                form?.propertyRequierments?.petsConsidered ===
                  "petsConsidered" ? (
                  <>
                    <div className="col-12 md:col-6  mb-3">
                      <div className="flex align-items-center ">
                        <img
                          src={FurnishedIcon}
                          width={20}
                          height={20}
                          alt=""
                        ></img>

                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          Furnished
                        </span>
                      </div>
                    </div>
                    <div className="col-12 md:col-6  mb-3">
                      <div className="flex align-items-center ">
                        <img
                          src={FurnishedIcon}
                          width={20}
                          height={20}
                          alt=""
                        ></img>

                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          Pets Considered
                        </span>
                      </div>
                    </div>
                  </>
                ) : form?.propertyRequierments?.furnished === "furnished" &&
                  form?.propertyRequierments?.petsConsidered === null ? (
                  <>
                    {" "}
                    <div className="col-12 md:col-6  mb-3">
                      <div className="flex align-items-center ">
                        <img
                          src={FurnishedIcon}
                          width={20}
                          height={20}
                          alt=""
                        ></img>

                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          Furnished
                        </span>
                      </div>
                    </div>
                  </>
                ) : form?.propertyRequierments?.furnished === null &&
                  form?.propertyRequierments?.petsConsidered ===
                    "petsConsidered" ? (
                  <div className="col-12 md:col-6  mb-3">
                    <div className="flex align-items-center ">
                      <img
                        src={FurnishedIcon}
                        width={20}
                        height={20}
                        alt=""
                      ></img>

                      <span className="ml-3 font-medium text-black text-lg font-semibold">
                        Pets Considered
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Outdoor features Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Outdoor features
                  </h2>
                </div>
                {form?.outdoorFeatures?.length > 0 ? (
                  form?.outdoorFeatures?.map((item, index) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-2 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Indoor features  Section*/}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Indoor features
                  </h2>
                </div>
                {form?.indoorFeatures?.length > 0 ? (
                  form?.indoorFeatures?.map((item) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-2 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}

                <div className="col-12 md:col-12">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Climate control & energy Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Climate control & energy
                  </h2>
                </div>
                {form?.climateControlEnergy?.length > 0 ? (
                  form?.climateControlEnergy?.map((item) => {
                    return (
                      <div className="col-12 md:col-6 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.title}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="ml-2 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                )}
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                <div className="col-12 md:col-12">
                  <h2 className=" text-dark font-medium text-2xl font-bold">
                    Property Must Have
                  </h2>
                </div>
                <div className="col-12 md:col-12">
                  <div className="flex align-items-center p-0 m-0">
                    <p className="p-0 m-0 text-black text-lg font-semibold">
                      {form?.propertyMustHave?.min}
                    </p>
                  </div>
                </div>
                <div className="col-12 md:col-12">
                  <div className="flex align-items-center p-0 m-0">
                    <p className="p-0 m-0 text-black text-lg font-semibold">
                      {form?.propertyMustHave?.max}
                    </p>
                  </div>
                </div>
                {form?.propertyMustHave?.min?.length == 0 &&
                form?.propertyMustHave?.max?.length == 0 ? (
                  <span className="ml-2 font-medium text-black text-lg font-semibold">
                    N/A
                  </span>
                ) : null}

                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr p-0 m-0" />
                </div>

                {/* Notes Section */}
                <div className="col-12 md:col-12">
                  <h2 className=" text-dark  text-2xl p-0 m-0 font-bold">
                    Notes
                  </h2>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <div className="flex align-items-center p-0 m-0">
                    <p className="p-0 m-0 text-black text-lg line-height-3 font-semibold">
                      {form?.note?.length > 0 ? form?.note : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr p-0 m-0" />
                </div>
              </div>
              <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                <div
                  className="col-12 md:col-3 md:text-left lg:col-2 mb-3 "
                  onClick={gotoBtn}
                >
                  <div className="no-underline">
                    <ButtonComponent
                      label="BACK TO EDIT"
                      className="md:text-left bg-transparent border-0 text-black"
                      onClick={() => setPage(1)}
                    ></ButtonComponent>
                  </div>
                </div>
                <div className="col-12 md:col-5 lg:col-4 submit-verification">
                  <div className="no-underline lg:ml-5" onClick={gotoBtn}>
                    <ButtonComponent
                      onClick={() => handleSubmit()}
                      label="SUBMIT FOR VERIFICATION"
                      className="bg-main text-light-cyan  border-0 lg:w-9 lg:mx-6"
                      style={{ color: "rgba(143, 154, 153, 1)" }}
                    ></ButtonComponent>
                  </div>
                </div>
              </div>
            </ContentArea>
          </div>
        </div>
      </>

      <div className="bookProfile-otp">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium p-0 m-0">
                We will send you an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on this number
              </p>
            </div>
            <div className="grid p-0 m-0">
              <div className="col-12 md:col-12 p-0 m-0">
                <p className="text-lg font-semibold text-dark ml-1">
                  Enter your mobile number
                </p>
                <CustomPhoneInput
                  code={form?.countryCode}
                  setCode={setCode}
                  col={12}
                  value={form?.mobile}
                  disabled
                />
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0 ">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning  text-sm font-semibold lg:w-full md:w-full w-10 "
                  onClick={() => setVisible(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SEND CODE"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={() => {
                      handleSendOtpRequest();
                    }}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
        <Dialog
          visible={otpVerification}
          style={{ width: "100vw" }}
          onHide={() => setOtpVerification(false)}
          className="bookProfile-otp lg:w-4 md:w-8 w-11"
        >
          <p className="m-0">
            <div className="text-center mb-3">
              <h1 className="text-4xl text-dark font-semibold">
                OTP Verification
              </h1>
              <p className="text-black text-lg font-medium ">
                We have sent an{" "}
                <cod className="font-semibold text-dark text-lg">
                  One Time Password
                </cod>{" "}
                on your number
              </p>
            </div>
            <div className="grid p-0 m-0 ">
              <div className="col-12 md:col-12 flex justify-content-center p-0 m-0">
                <div className="">
                  <OtpInput
                    value={otp}
                    onChange={(e) => hadleChangeOtp(e)}
                    numInputs={4}
                    inputStyle={{
                      width: "48px",
                      height: "48px",
                      border: "1px solid #8F9A99",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      margin: "10px",
                    }}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {error?.otp && (
                    <small className="p-error text-sm ml-2 mt-2">
                      {error?.otp}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-12 text-center  mb-4 mt-2 ">
                <p className="text-lg font-medium text-black text-center p-0 m-0">
                  {counter !== 0 ? (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <span className="text-main font-semibold text-lg">
                        {counter}
                      </span>
                      <span className="text-main font-semibold text-lg">s</span>
                    </>
                  ) : (
                    <>
                      <span> Don’t received the code?</span>{" "}
                      <cod
                        className="text-main font-semibold text-lg cursor-pointer"
                        onClick={() => resendOtp()}
                      >
                        Resend code
                      </cod>
                    </>
                  )}
                </p>
              </div>
              <div className="flex align-items-center col-12 md:col-12 p-0 m-0">
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white text-warning border-warning lg:w-full md:w-full w-10 text-sm font-semibold "
                  onClick={() => setOtpVerification(false)}
                />
                <span className="lg:ml-5 md:ml-5 ml-2 w-full ">
                  <ButtonComponent
                    label="SUBMIT"
                    className=" text-sm font-semibold text-light-cyan w-full lg:px-5 md:px-5 px-2"
                    onClick={handleVerifyOtp}
                  />
                </span>
              </div>
            </div>
          </p>
        </Dialog>
      </div>

      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
