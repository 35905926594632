import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { authenticate, isAuthenticated } from "../../services/authService";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";


export const buyerGeneralInfo = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("get", Constants.END_POINT.GERENALPROPERTYINFO)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GENERAL_PROPERTY_INFO,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const createBuyerAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.CREATEBUYERFORM, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CREATE_BUYER_FORM,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        if (res.message.includes("E11000")) {
            dispatch(hideLoaderAction())
            dispatch(showToast({ severity: "error", summary: "Email allready Exist" }))
        } else {
            dispatch(hideLoaderAction())
            dispatch(showToast({ severity: "error", summary: res.message }))
        }
    }
}

export const enquieryMessageAction = (data, id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", `${Constants.END_POINT.ENQUIERYMESSAGE}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ENQUIERY_MESSAGE,
                payload: res.message
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        if (res.message === "Unauthorized Access!...") {
            dispatch(hideLoaderAction())
            dispatch(showToast({ severity: "error", summary: "Please Login" }))
        } else {
            dispatch(hideLoaderAction())
            dispatch(showToast({ severity: "error", summary: res.message }))
        }
    }
}

export const mobileSendOtp = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.REQUESTOTP, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.REQUEST_OTP,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
        return res;
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const verifyOTP = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.SENDOTP, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VERIFY_OTP,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
        return res;
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const createNewsLetter = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await api("post", Constants.END_POINT.CREATENEWSLETTER, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CREATE_NEWS_LETTER,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }))
        return res;
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}