import React from 'react'
import { Card } from 'primereact/card';
export default function CardComponent ({children,header, cardsize}) {
  return (
    <>
    <Card header={header} className={`card shadow-1  justify-content-center align-items-center ${cardsize}`} >
      {children}
      </Card>
    </>
  )
}