import React, { useRef, useState } from "react";
import LogoImg from "../assets/images/Logo.png";
import ButtonComponent, { ButtonOutlined } from "../components/buttons/button";
import { ContainerBox } from "../shared/ContainerBox";
import { CustomInput } from "../components/input/AllInput";
import ArrowIcon from "../assets/icons/top-arrow.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { createNewsLetter } from "../redux/actions/buyerAction";
import { useDispatch } from "react-redux";
import { emailValidation } from "../utils/regex";
import { allValidations } from "../utils/formValidation";
import { showToast } from "../redux/actions/toastAction";
import { isAuthenticated } from "../services/authService";
export default function Footer() {
  const dispatch = useDispatch();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [form, setForm] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const formErrorsCheck = () => {
    let formErrors = {};
    if (form?.email === "" || form?.email === undefined) {
      formErrors.email = "Please Enter Email";
      dispatch(showToast({ severity: "error", summary: formErrors.email }));
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
      dispatch(showToast({ severity: "error", summary: formErrors.email }));
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };

  const handleSubscribe = () => {
    if (!formErrorsCheck()) {
      return false;
    } else {
      dispatch(createNewsLetter(form));
      setForm({
        email: "",
      });
    }
  };
  const handleSubmit = () => {
    if (isAuthenticated()) {
      navigate("/profile");
    } else {
      navigate("/sign-in");
    }
  };
  const handleChange = (e) => {
    const formErrors = allValidations("email", e?.target?.value, form);
    setForm({
      ...form,
      email: e.target.value,
      formErrors,
    });
  };
  return (
    <>
      <div className="footer_container bg-light">
        <ContainerBox>
          <div className="grid justify-content-between p-0 m-0 footer_content mb-5 ">
            <div className="col-12 lg:col-4 ">
              <Link to="/" className="no-underline" onClick={gotoBtn}>
                <img
                  src={LogoImg}
                  alt=""
                  width={229}
                  height={56}
                  className="  md:mt-5 mt-5"
                />
              </Link>
              {/* <p className=" footer-text font-medium text-black">
                Powered By Leverage Listings. Lic no: 1220693
              </p> */}
            </div>
            <div className="footer-link col-5 lg:col-2 lg:mt-5  ">
              <div className="">
                <h1 className="font-medium text-dark text-xl cursor-pointer ">
                  Quick link
                </h1>
                <div className="text-lg font-medium text-black ">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/");
                      gotoBtn();
                    }}
                  >
                    Home
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/buyer-form");
                      gotoBtn();
                    }}
                  >
                    Buy
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/seller-form");
                      gotoBtn();
                    }}
                  >
                    Sell
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/renter-form");
                      gotoBtn();
                    }}
                  >
                    Rent
                  </p>
                  {/* <p className="cursor-pointer">Partners</p> */}
                </div>
              </div>
            </div>
            <div className=" footer-link col-5 lg:col-2  lg:mt-5 ">
              <div className="">
                <h1 className="font-medium text-dark text-xl cursor-pointer">
                  Support
                </h1>
                <div className="text-lg font-medium text-black">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/about-us");
                      gotoBtn();
                    }}
                  >
                    About us
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/contact-us");
                      gotoBtn();
                    }}
                  >
                    Contact us
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/privacy-policy");
                      gotoBtn();
                    }}
                  >
                    Privacy Policy
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/term-condition");
                      gotoBtn();
                    }}
                  >
                    Terms & Conditions
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-link  col-12 lg:col-4 lg:mt-5  ">
              <div className="footer-email-sbuscribe-button w-full">
                <h1 className="font-medium text-dark text-xl p-0 m-0 text-center">
                  Newsletter
                </h1>
                <div className="grid justify-content-center w-full p-0 m-0 ">
                  <div className="col-12 md:col-12 lg:col-12 ">
                    <p className=" FOOTER-BUTTON flex border-1 w-full border-main overflow-auto border-round-xl bg-white">
                      <CustomInput
                        className="border-noround p-3  border-1 subscribe-button border-0 "
                        placeholder="ENTER YOUR EMAIL"
                        extraClassName="w-7"
                        name="email"
                        value={form?.email}
                        onChange={handleChange}
                      />
                      <span className="w-full SUBSCRIBE">
                        <ButtonComponent
                          label="SUBSCRIBE"
                          className=" SUBSCRIBE  border-main SUBSCRIBE w-full p-3 text-light font-semibold"
                          onClick={handleSubscribe}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* 
           for-phone-screen */}
            {/* <div className="w-full lg:hidden md:block">
              <h1 className="font-medium text-dark text-xl p-0 m-0 text-center">Newsletter</h1>
              <div className="grid justify-content-center w-full p-0 m-0 ">
                <div className="col-12 md:col-12 lg:col-12 ">
                  <p className=" FOOTER-BUTTON flex border-1 w-full border-main overflow-auto border-round-xl bg-white" >
                    <CustomInput
                      className="border-noround p-3  border-1 subscribe-button border-0 "
                      placeholder="ENTER YOUR EMAIL"
                      extraClassName="w-7"
                      name="email"
                      value={form?.email}
                      onChange={handleChange}
                    />
                    <span className="w-full SUBSCRIBE">

                      <ButtonComponent
                        label="SUBSCRIBE"
                        className=" SUBSCRIBE  border-main SUBSCRIBE w-full p-3 text-light font-semibold"
                        onClick={handleSubscribe}
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div className="footer-link  col-12 lg:col-4 lg:mt-5  ">
              <div className="">
                <h1 className="font-medium text-dark text-xl">Head Office</h1>
                <div className="text-lg font-medium text-black mb-4">
                  <p>Sydney, NSW 2000</p>
                  <p>Sydney, NSW 2000</p>
                </div>
              </div>


              <h1 className="font-medium text-dark text-xl p-0 m-0">Newsletter</h1>
              <div className="grid justify-content-center ">
                <div className="col-12 md:col-12 lg:col-12 ">
                  <p className=" FOOTER-BUTTON flex border-1 w-full border-main overflow-auto border-round-xl bg-white" style={{ height: "57px" }}>
                    <CustomInput

                      className="border-noround p-3  border-1 subscribe-button border-0 "
                      placeholder="ENTER YOUR EMAIL"
                      extraClassName="w-7"
                    />
                    <span className="w-full SUBSCRIBE">

                      <ButtonComponent
                        label="SUBSCRIBE"
                        className=" SUBSCRIBE border-2 border-main SUBSCRIBE w-full p-3 text-light font-semibold"
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div
            className=" lg:mb-7 md:mb-7 mb-5 mr-2 ml-2 grid p-0 m-0 p-2 bg-dark  flex align-items-center justify-content-between "
            style={{ borderRadius: "20px" }}
          >
            <div className=" col-12 lg:col-6">
              <h1
                className="ultimate-connection-text font-medium md:font-semibold text-gray-light  font-semibold mx-3"
                style={{ fontSize: "32px" }}
              >
                The ultimate real estate connection.{" "}
              </h1>
              <p
                className="text-lg font-medium mx-3"
                style={{ color: "rgba(204, 209, 209, 1)" }}
              >
                Making your property journey easy by changing the way Buyers,
                Renters and Sellers connect
              </p>
            </div>
            <div className="col-12 lg:col-4  md:col-12 text-center">
              <ButtonOutlined
                label="MAKE YOUR PROPERTY CONNECTION"
                className=" make-property-button text-light border-white text-base  font-semibold w-11"
                onClick={handleSubmit}
              ></ButtonOutlined>
            </div>
          </div>
          <div className=" footer-arrow-section grid p-0 m-0 align-items-center">
            <div className="col-12 md:col-11 text-center">
              <p className="footer-copy-right text-black font-medium mb-5">
                © copyright - The Buyers Place - 2023
              </p>
            </div>
            <div
              className="col-12 md:col-1 lg:col-1 sm:col-12  arrow-image"
              onClick={gotoBtn}
            >
              <img
                src={ArrowIcon}
                height={48}
                width={48}
                alt=""
                className="lg:mb-5 md:mb-5 cursor-pointer"
              ></img>
            </div>
          </div>
        </ContainerBox>
      </div>
    </>
  );
}
