import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDebounce } from "primereact/hooks";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allPostAction } from "../../redux/actions/postAction";
import { allValidations } from "../../utils/formValidation";
import _debounce from "lodash/debounce";
export const AllPostCommon = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // let data = location.state;
  const recent = useRef(null);
  const [priceVisible, setPriceVisible] = useState(false);
  const [bedRoomsVisible, setBedRoomsVisible] = useState(false);
  const [profileType, setProfileType] = useState("");
  const [filter, setFilter] = useState({
    bedrooms: "",
    profileType: "",
    priceRange: {},
  });
  const [price, setPrice] = useState({
    min: "",
    max: "",
  });
  const [name, setName] = useState("");
  const [recentCheck, setRecentCheck] = useState(false);
  const [inputValue, debouncedValue, setInputValue] = useDebounce("", 500);
  useEffect(() => {
    if (recentCheck === true) {
      dispatch(
        allPostAction({
          sort: "recent",
          search: inputValue,
          profileType: profileType,
          priceRange: price,
          bedrooms: bedRoomsVisible,
        })
      );
      setName("Recent");
      recent.current.toggle(false);
    } else {
      dispatch(
        allPostAction({
          search: inputValue,
          profileType: profileType,
          priceRange: price,
          bedrooms: bedRoomsVisible,
        })
      );
      setName("Old");
      recent.current.toggle(false);
    }
  }, [recentCheck]);
  useEffect(() => {
    dispatch(
      allPostAction({
        search: inputValue,
        profileType: profileType,
        priceRange: price,
        bedrooms: bedRoomsVisible,
      })
    );
  }, []);
  let data = useSelector((state) => state.allPost.allPostProfile);
  const handleDebounceFn = (payload) => {
    dispatch(allPostAction(payload));
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleSearchChange = (e) => {
    setInputValue(e.target.value);
    debounceFn({
      search: e?.target?.value,
      profileType: profileType,
      priceRange: price,
      bedrooms: bedRoomsVisible,
    });
  };
  useEffect(() => {
    if (inputValue == "") {
      dispatch(
        allPostAction({
          search: inputValue,
          profileType: profileType,
          priceRange: price,
          bedrooms: bedRoomsVisible,
        })
      );
    }
  }, [inputValue]);
  const handleProfileType = (e) => {
    setProfileType(e?.target?.value);
    if (e?.target?.value === "ALL") {
      dispatch(
        allPostAction({
          profileType: "",
          priceRange: price,
          bedrooms: bedRoomsVisible,
          search: inputValue,
        })
      );
    } else {
      dispatch(
        allPostAction({
          profileType: e?.target?.value,
          priceRange: price,
          bedrooms: bedRoomsVisible,
          search: inputValue,
        })
      );
    }
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const formErrors = allValidations(name, value, price);
    if (name === "min") {
      setPrice({
        ...price,
        [name]: value,
        formErrors,
      });
    } else {
      setPrice({
        ...price,
        [name]: value,
        formErrors,
      });
    }
  };
  const minPriceRange = [
    { value: null, label: "Any" },
    { value: 50000, label: "$50,000" },
    { value: 75000, label: "$75,000" },
    { value: 100000, label: "$100,000" },
    { value: 125000, label: "$125,000" },
    { value: 150000, label: "$150,000" },
    { value: 175000, label: "$175,000" },
    { value: 200000, label: "$200,000" },
    { value: 225000, label: "$225,000" },
    { value: 250000, label: "$250,000" },
    { value: 275000, label: "$275,000" },
    { value: 300000, label: "$300,000" },
    { value: 325000, label: "$325,000" },
    { value: 350000, label: "$350,000" },
    { value: 375000, label: "$375,000" },
    { value: 400000, label: "$400,000" },
    { value: 425000, label: "$425,000" },
    { value: 450000, label: "$450,000" },
    { value: 475000, label: "$475,000" },
    { value: 500000, label: "$500,000" },
    { value: 525000, label: "$525,000" },
    { value: 550000, label: "$550,000" },
    { value: 600000, label: "$600,000" },
    { value: 650000, label: "$650,000" },
    { value: 700000, label: "$700,000" },
    { value: 750000, label: "$750,000" },
    { value: 800000, label: "$800,000" },
    { value: 850000, label: "$850,000" },
    { value: 900000, label: "$900,000" },
    { value: 950000, label: "$950,000" },
    { value: 1000000, label: "$1,000,000" },
    { value: 1100000, label: "$1,100,000" },
    { value: 1200000, label: "$1,200,000" },
    { value: 1300000, label: "$1,300,000" },
    { value: 1400000, label: "$1,400,000" },
    { value: 1500000, label: "$1,500,000" },
    { value: 1600000, label: "$1,600,000" },
    { value: 1700000, label: "$1,700,000" },
    { value: 1800000, label: "$1,800,000" },
    { value: 1900000, label: "$1,900,000" },
    { value: 2000000, label: "$2,000,000" },
    { value: 2250000, label: "$2,250,000" },
    { value: 2500000, label: "$2,500,000" },
    { value: 2750000, label: "$2,750,000" },
    { value: 3000000, label: "$3,000,000" },
    { value: 3500000, label: "$3,500,000" },
    { value: 4000000, label: "$4,000,000" },
    { value: 4500000, label: "$4,500,000" },
    { value: 5000000, label: "$5,000,000" },
    { value: 6000000, label: "$6,000,000" },
    { value: 7000000, label: "$7,000,000" },
    { value: 8000000, label: "$8,000,000" },
    { value: 9000000, label: "$9,000,000" },
    { value: 10000000, label: "$10,000,000" },
    { value: 12000000, label: "$12,000,000" },
    { value: 15000000, label: "$15,000,000" },
  ];
  const priceFormCheck = () => {
    let formErrors = {};
    if (price.min === "") {
      formErrors.min = "Please Select";
    }
    if (price.max === "") {
      formErrors.max = "Please Select";
    }
    if (Object.keys(formErrors).length === 0) {
      return true;
    } else {
      setPrice({ ...price, formErrors });
      return false;
    }
  };
  useEffect(() => {
    if (priceVisible === false) {
      setPrice({
        min: "",
        max: "",
      });
    }
  }, [priceVisible]);
  const handlePriceSearch = () => {
    if (!priceFormCheck()) {
      return false;
    } else {
      dispatch(
        allPostAction({
          profileType: profileType,
          priceRange: price,
          bedrooms: bedRoomsVisible,
          search: inputValue,
        })
      ).then(() => {
        setPriceVisible(false);
      });
    }
  };
  const profileTypeArray = ["ALL", "BUYER", "RENTER", "SELLER"];
  const bedRoomsArray = [
    { value: "", label: "Any" },
    { value: 1, label: "1+" },
    { value: 2, label: "2+" },
    { value: 3, label: "3+" },
    { value: 4, label: "4+" },
    { value: 5, label: "5+" },
  ];
  const handleBeddRooms = (e) => {
    setBedRoomsVisible(e?.target?.value);
    dispatch(
      allPostAction({
        profileType: profileType,
        bedrooms: e?.target?.value,
        priceRange: price,
        search: inputValue,
      })
    );
  };
  return {
    data,
    handleSearchChange,
    inputValue,
    setRecentCheck,
    priceVisible,
    setPriceVisible,
    handleProfileType,
    minPriceRange,
    setProfileType,
    profileType,
    handlePriceChange,
    price,
    handlePriceSearch,
    profileTypeArray,
    setBedRoomsVisible,
    bedRoomsVisible,
    bedRoomsArray,
    handleBeddRooms,
    setFilter,
    name,
    recent,
  };
};
