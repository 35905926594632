
import React, { useState } from 'react'
import { allValidations } from '../../utils/formValidation';
import { useDispatch } from 'react-redux';
import { changePasswordAction } from '../../redux/actions/profileAction';
import { customPasswordCheck } from '../../utils/javascript';
export const NewPassword = () => {
    const dispatch = useDispatch()
    const [showButton, setShowButton] = useState(false)
    const [password, setPassword] = useState({
        password: "",
        newPassword: "",
        oldPassword: ""
    })
    const handleChangePassword = (e) => {
        setShowButton(true)
        const { name, value } = e.target
        const formErrors = allValidations(name, value, password)
        setPassword({
            ...password,
            [name]: value,
            formErrors
        })
    }
    const formCheck = () => {
        const valid = customPasswordCheck(password?.password)
        const validNew = customPasswordCheck(password?.oldPassword)
        let formErrors = {}
        if (password.oldPassword === "") {
            formErrors.oldPassword = "Please Enter New Password"
        }
        else if (validNew) {
            formErrors.oldPassword = validNew
        }
        if (password.password === "") {
            formErrors.password = "Please Enter Old Password"
        }
        else if (valid) {
            formErrors.password = valid
        }
        if (password.newPassword === "") {
            formErrors.newPassword = "Please Enter Confirm Password"
        }
        else if(password.oldPassword !== password.newPassword){
            formErrors.newPassword = "Password and Confirm Password are not same"
          }
        if (Object.keys(formErrors).length == 0) {
            return true
        }
        else {

            setPassword({ ...password, formErrors })
            return false
        }
    }
    const handleSubmit = () => {
        if (!formCheck()) {
            return false
        } else {
            dispatch(changePasswordAction(password))
        }
        setPassword({
            password: "",
            newPassword: "",
            oldPassword: ""
        })
        setShowButton(false)
    }
    const handleCancel = () => {
        setPassword({
            password: "",
            newPassword: "",
            oldPassword: ""
        })
    }
    return {
        password, setPassword, handleChangePassword, handleSubmit, handleCancel, showButton
    }

}