import { combineReducers } from "redux";
import { checkEmailReducer, forgetPasswordReducer, resetPasswordReducer, signinReducer, signupReducer } from "./signupReducer";
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import { socailLoginReducer } from "./socialReducer";
import { buyerGeneralInfoReducer, createBuyerReducer, enquieryMessageReducer, mobileSendOtpReducer, newsLetterReducer, verifyOtpReducer } from "./buyerReducer";
import { SINGLEUPLOADFILEReducer, sellerUploadImageReducer } from "./sellerReducer";
import { allPostReducer, deletePostReducer, editProfileReducer, postDetailReducer, postSavedUnsavedReducer, viewProfileReducer } from "./allPostReducer";
import { changePasswordReducer, createContactReducer, myProfileReducer, privacyPolicyReducer, termConditionReducer, updateProfileReducer } from "./profileReducer";

export default combineReducers({
    signup: signupReducer,
    loader: loaderReducer,
    toast: toastReducer,
    signin: signinReducer,
    forgetPassword: forgetPasswordReducer,
    resetPassword: resetPasswordReducer,
    socialLogin: socailLoginReducer,
    buyergeneralInfo: buyerGeneralInfoReducer,
    createBuyer: createBuyerReducer,
    uploadFile: sellerUploadImageReducer,
    allPost: allPostReducer,
    postDetail: postDetailReducer,
    enquieryMessage: enquieryMessageReducer,
    postSavedUnSaved: postSavedUnsavedReducer,
    myProfileData: myProfileReducer,
    updateProfileData: updateProfileReducer,
    singleUploadImage: SINGLEUPLOADFILEReducer,
    changePassword: changePasswordReducer,
    deletePost: deletePostReducer,
    editPost: editProfileReducer,
    viewPost: viewProfileReducer,
    contact: createContactReducer,
    termCondition: termConditionReducer,
    privacy: privacyPolicyReducer,
    emailCheck: checkEmailReducer,
    mobileSendOtp: mobileSendOtpReducer,
    verifyOtp: verifyOtpReducer,
    newLetter: newsLetterReducer
})