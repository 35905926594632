import React, { useState } from "react";
import UserIcon from "../../assets/icons/user-name.png";
import CallingIcon from "../../assets/icons/calling.png";
import MessageIcon from "../../assets/icons/Message.png";
import LocationIcon from "../../assets/icons/loaction.png";
import BedroomsIcon from "../../assets/icons/bad (1).png";
import BathroomsIcon from "../../assets/icons/tablelamp.png";
import CarIcon from "../../assets/icons/car.png";
import { ButtonOutlined } from "../../components/buttons/button";
import Logoimg from "../../assets/images/Logo.png";
import Constants from "../../services/constant";
import { allValidations } from "../../utils/formValidation";
import {
  CustomCheckbox,
  CustomInput,
  CustomPassword,
  CustomPhoneInput,
  CustomRadioButtons,
  CustomTextArea,
} from "../../components/input/AllInput";
import { useEffect } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { isAuthenticated } from "../../services/authService";

export const ContactDetail = ({
  handleChange,
  show,
  token,
  emailCheck,
  form,
  address,
  navigate,
  setForm,
  code,
  setCode,
  handleSelectAddressSuburb,
  handleChangeSuburb,
  handlechangeStreetAddress,
  handleSelectStreetAddress,
}) => {
  return (
    <>
      <h2 className="ml-1 text-dark col-12 font-medium text-2xl font-bold">
        Contact details
      </h2>
      <div className="col-12 md:col-6">
        <CustomInput
          label="First name"
          placeholder="First Name"
          name="firstName"
          extraClassName="w-full"
          // extraClassName={form.firstName?.length > 0 ? "w-full" : "border-hightlighted w-full"}
          value={form?.firstName}
          onChange={handleChange}
          errorMessage={form?.formErrors?.firstName}
          required
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
      <div className="col-12 md:col-6">
        <CustomInput
          label="Last name"
          placeholder="Last Name"
          name="lastName"
          extraClassName="w-full "
          value={form?.lastName}
          onChange={handleChange}
          required
          errorMessage={form?.formErrors?.lastName}
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
      <div className="col-12 md:col-6">
        <CustomInput
          label="Email"
          placeholder="Email"
          name="email"
          extraClassName="w-full"
          value={form?.email}
          onChange={handleChange}
          errorMessage={form?.formErrors?.email}
          required
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
        {/* {emailCheck === true && token === null ? <p className="p-0 m-0 text-red-600 font-normal text-sm ml-2 mb-3" onClick={() => navigate("/sign-in")}>Email  already exist <cod className="text-main font-semibold text-base">Login Here</cod></p> : ""} */}
      </div>
      <div className="col-12 md:col-6">
        <CustomPhoneInput
          extraClassName="contact w-full "
          label="Phone"
          required
          name="mobile"
          value={form?.mobile?.toString()}
          onChange={handleChange}
          code={code}
          setCode={setCode}
          errorMessage={form?.formErrors?.mobile}
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
      {emailCheck === false && !isAuthenticated() ? (
        <div className="grid w-full p-0 m-0">
          <div className="col-12 md:col-6">
            <CustomPassword
              label="Password"
              placeholder="Password"
              name="password"
              value={form?.password}
              onChange={handleChange}
              required
              errorMessage={form?.formErrors?.password}
              col={12}
            />
          </div>
          <div className="col-12 md:col-6">
            <CustomPassword
              col={12}
              label="Confirm Password"
              placeholder="Password"
              name="confirmPassword"
              onChange={handleChange}
              required
              value={form?.confirmPassword}
              disabled={form.password?.length === 0 ? true : false}
              errorMessage={form?.formErrors?.confirmPassword}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={`col-12 md:col-6 ${
          form?.formErrors?.password?.length > 0 ||
          form?.formErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <PlacesAutocomplete
          value={address || form?.suburb}
          onChange={handleChangeSuburb}
          onSelect={handleSelectAddressSuburb}
          searchOptions={{ componentRestrictions: { country: ["au"] } }}
          disabled={form?.formErrors?.password?.length > 0 ? true : false}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <>
              <CustomInput
                extraClassName="w-full"
                label="Full Address"
                required
                value={address || form?.suburb}
                disabled={form?.formErrors?.password?.length > 0 ? true : false}
                errorMessage={form?.formErrors?.suburb}
                {...getInputProps({
                  placeholder: "Enter Full Address",
                  // className: "location-search-input w-full p-3"
                })}
              />
              {show === true ? (
                <div className="autocomplete-dropdown-container absolute top-500 bg-white z-2 p-0 shadow-1  mb-2 ">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                      : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span className="">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </PlacesAutocomplete>
      </div>
      {/* <div className="col-12 md:col-6">
        <CustomInput
          label="State"
          placeholder="State"
          extraClassName="w-full mb-0"
          name="State"
          value={form?.state}
          disabled
          required
        />
      </div>
      <div className="col-12 md:col-6">
        <CustomInputNumber
          label="Post code"
          placeholder="Post code"
          extraClassName="w-full mb-0"
          name="Pose code"
          value={form?.zipCode}
          disabled
          required
        />
      </div> */}
    </>
  );
};

export const NewEstablishedProperty = ({ form, setForm, handleChange }) => {
  const [ingredient, setIngredient] = useState("");
  return (
    <>
      <div className="col-12">
        <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
          New or Established property<code className="p-error text-sm">*</code>
        </h2>
        {form?.formErrors?.establishedProperty && (
          <small className="p-error text-sm ml-2">
            {form?.formErrors?.establishedProperty}
          </small>
        )}
      </div>

      {/* <div className="col-12 md:col-3 lg:col-2 lg:w-2">
        <CustomRadioButtons
          optionLabel="Any"
          extraClassName="w-auto"
          id="types"
          name="establishedProperty"
          value="allTypes"
          onChange={handleChange}
          checked={form?.establishedProperty === "allTypes"}
          errorMessage={form?.formErrors?.establishedProperty}
        />
      </div> */}
      <div className="col-12 md:col-3 lg:col-2 lg:w-2">
        <CustomRadioButtons
          optionLabel="New"
          extraClassName="w-auto"
          id="New"
          name="establishedProperty"
          value="new"
          onChange={handleChange}
          checked={form?.establishedProperty === "new"}
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
      <div className="col-12 md:col-3 lg:col-2 lg:w-2">
        <CustomRadioButtons
          optionLabel="Established"
          extraClassName="w-auto"
          id="Established"
          name="establishedProperty"
          value="established"
          onChange={handleChange}
          checked={form?.establishedProperty === "established"}
          disabled={
            form?.formErrors?.password?.length > 0 ||
            form?.formErrors?.confirmPassword?.length > 0
              ? true
              : false
          }
        />
      </div>
    </>
  );
};

export const PropertyType = ({
  PropertyList,
  check,
  form,
  handleTypeChange,
}) => {
  return (
    <>
      <div className="col-12">
        <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
          Property type<code className="p-error text-sm ">*</code>
          <br />
        </h2>
        {form?.formErrors?.propertyType && (
          <small className="p-error text-sm ml-1">
            {form?.formErrors?.propertyType}
          </small>
        )}
      </div>
      <>
        {PropertyList?.propertyTypes?.length > 0 &&
          PropertyList?.propertyTypes?.map((item) => {
            return (
              <>
                {item?.forProfile?.includes("SELLER") ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.propertyType}
                      id={item?.propertyType}
                      name="propertyType"
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={check?.some((type) =>
                        item._id === type._id ? true : false
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
      </>
    </>
  );
};
export const PropertyDetail = ({
  form,
  handleRoomChange,
  highlight,
  bathRoomHightlight,
  carHightlight,
}) => {
  return (
    <>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Bedrooms<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          form?.formErrors?.password?.length > 0 ||
          form?.formErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={`font-medium text-lg cursor-pointer text-black  md:px-5 p-0 m-0 lg:ml-1 lg:px-5 p-3 font-bold ${
              highlight === "0+"
                ? "room-hightlight  bg-light-skin  "
                : " bg-light-skin  "
            }`}
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>
          <p
            label="1+"
            className={
              highlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              highlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              highlight === "3+"
                ? " font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              highlight === "4+"
                ? " font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              highlight === "5+"
                ? " font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bedrooms"
            onClick={(e) => handleRoomChange("bedrooms", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {form?.formErrors?.bedrooms && (
          <small className="p-error text-sm  ml-2">
            {form?.formErrors?.bedrooms}
          </small>
        )}
      </div>
      <div className="col-12 md:col-12 lg:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Bathrooms<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          form?.formErrors?.password?.length > 0 ||
          form?.formErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={
              bathRoomHightlight === "0+"
                ? "font-bold room-hightlight p-0 m-0 lg:ml-1 md:px-5 lg:px-5 p-3 bg-light-skin text-black font-medium text-lg cursor-pointer"
                : "font-bold lg:ml-1 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg md:px-5 p-0 m-0 cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>

          <p
            label="1+"
            className={
              bathRoomHightlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              bathRoomHightlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              bathRoomHightlight === "3+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              bathRoomHightlight === "4+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              bathRoomHightlight === "5+"
                ? "font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lgcursor-pointer"
                : " font-bold lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="bathrooms"
            onClick={(e) => handleRoomChange("bathrooms", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {form?.formErrors?.bathrooms && (
          <small className="p-error text-sm ml-2">
            {form?.formErrors?.bathrooms}
          </small>
        )}
      </div>
      <div className="col-12 md:col-12 mb-3">
        <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
      </div>
      <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0">
        Car spaces<code className="p-error text-sm">*</code>
      </h2>
      <div
        className={`col-12 md:col-12 lg:col-12 ${
          form?.formErrors?.password?.length > 0 ||
          form?.formErrors?.confirmPassword?.length > 0
            ? "pointer-events-none"
            : ""
        } `}
      >
        <div className=" flex align-items-center justify-content-between lg:w-5 md:w-10 ">
          <p
            label="0+"
            className={
              carHightlight === "0+"
                ? "font-bold room-hightlight p-0 m-0 lg:ml-1 md:px-5 lg:px-5 p-3 bg-light-skin text-black font-medium text-lg cursor-pointer"
                : "font-bold lg:ml-1 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg md:px-5 p-0 m-0 cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "0+")}
            style={{ borderRadius: "10px" }}
          >
            0
          </p>

          <p
            label="1+"
            className={
              carHightlight === "1+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : " font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "1+")}
            style={{ borderRadius: "10px" }}
          >
            1+
          </p>

          <p
            label="2+"
            className={
              carHightlight === "2+"
                ? "font-bold room-hightlight  lg:ml-5  md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : "font-bold   lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "2+")}
            style={{ borderRadius: "10px" }}
          >
            2+
          </p>

          <p
            label="3+"
            className={
              carHightlight === "3+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold  lg:ml-5 md:px-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "3+")}
            style={{ borderRadius: "10px" }}
          >
            3+
          </p>

          <p
            label="4+"
            className={
              carHightlight === "4+"
                ? "font-bold room-hightlight md:px-5 lg:ml-5 lg:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
                : " font-bold lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "4+")}
            style={{ borderRadius: "10px" }}
          >
            4+
          </p>

          <p
            label="5+"
            className={
              carHightlight === "5+"
                ? "font-bold room-hightlight  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer "
                : "font-bold  lg:ml-5 lg:px-5 md:px-5 p-3 bg-light-skin text-black  font-medium text-lg cursor-pointer"
            }
            name="carSpaces"
            onClick={(e) => handleRoomChange("carSpaces", "5+")}
            style={{ borderRadius: "10px" }}
          >
            5+
          </p>
        </div>
        {form?.formErrors?.carSpaces && (
          <small className="p-error text-sm ml-2">
            {form?.formErrors?.carSpaces}
          </small>
        )}
      </div>
    </>
  );
};

export const PropertySpaceDetail = ({ data }) => {
  return (
    <>
      <div className="col-12 md:col-12">
        <h2 className=" text-dark font-medium text-2xl font-bold">Bedrooms</h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <div className="flex align-items-center">
          <img src={BedroomsIcon} width={20} height={20} alt=""></img>
          <span className="ml-3 font-medium text-black font-semibold">
            {data?.bedrooms === null ? "any" : data?.bedrooms}
          </span>
        </div>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>

      {/* Bathrooms Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-dark font-medium text-2xl font-bold">Bathrooms</h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <div className="flex align-items-center">
          <img src={BathroomsIcon} width={20} height={20} alt=""></img>
          <span className="ml-3 font-medium text-black font-semibold">
            {data?.bathrooms === null ? "any" : data?.bathrooms}
          </span>
        </div>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>

      {/* Car spaces Section */}
      <div className="col-12 md:col-12">
        <h2 className=" text-dark font-medium text-2xl font-bold">
          Car spaces
        </h2>
      </div>
      <div className="col-12 md:col-12 mb-3">
        <div className="flex align-items-center">
          <img src={CarIcon} width={20} height={20} alt=""></img>
          <span className="ml-3 font-medium text-black font-semibold">
            {data?.carSpaces === null ? "any" : data?.carSpaces}
          </span>
        </div>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};

export const OutdoorFeatures = ({ PropertyList, form, setForm, id }) => {
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setForm({
      ...form,
      outdoorFeatures: selectType,
    });
  };
  useEffect(() => {
    if (id || form?.outdoorFeatures) {
      setChecked(form?.outdoorFeatures);
    }
  }, [id, form?.outdoorFeatures]);
  return (
    <>
      {/* <Accordion activeIndex={0}>
        <AccordionTab
          className="accordin"
          header={
            
          }
        > */}
      <span className=" ml-1 vertical-align-middle text-2xl text-dark  block md:hidden mb-3 font-bold ">
        Outdoor features
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold  flex align-items-start"
          style={{ marginLeft: "4px" }}
        >
          Outdoor features
        </span>
        <span
          className="text-main mr-1 text-lg font-medium"
          onClick={handleClick}
        >
          {showMore == true
            ? "See fewer outdoor features"
            : "See More outdoor features"}{" "}
          &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            return (
              <>
                {item?.forFeature === "OUTDOOR" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name={item?.title}
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />{" "}
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className={
          "text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1 "
        }
        onClick={handleClick}
      >
        {showMore == true
          ? "See fewer outdoor features"
          : "See More outdoor features"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore == true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};

export const IndoorFeatures = ({ PropertyList, form, setForm, id }) => {
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setForm({
      ...form,
      indoorFeatures: selectType,
    });
  };
  useEffect(() => {
    if (id || form?.indoorFeatures) {
      setChecked(form?.indoorFeatures);
    }
  }, [id, form?.indoorFeatures]);
  return (
    <>
      {/* <Accordion activeIndex={0}>
        <AccordionTab
          className="accordin"
          header={
            
          }
        > */}
      <span className=" vertical-align-middle text-2xl text-dark font-bold block md:hidden mb-3 ml-1">
        Indoor Features
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold flex align-items-start "
          style={{ marginLeft: "4px" }}
        >
          Indoor features
        </span>
        <span
          className="text-main mr-1 text-lg font-medium"
          onClick={handleClick}
        >
          {showMore == true
            ? "See fewer indoor features"
            : "See More indoor features"}{" "}
          &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            return (
              <>
                {item?.forFeature === "INDOOR" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name="features"
                      value={item}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      onChange={handleTypeChange}
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className="text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1"
        onClick={handleClick}
      >
        {showMore == true
          ? "See fewer indoor features"
          : "See More indoor features"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore == true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};

export const PropertTypeFeature = ({ postData }) => {
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  return (
    <>
      <h2 className=" text-dark font-semibold text-2xl">Property Type</h2>
      <div
        className={`grid overflow-hidden ${
          showMore ? "show-all " : "show-min"
        }`}
      >
        {postData?.propertyType?.length > 0 &&
          postData?.propertyType?.map((item, index) => {
            return (
              <div className="md:col-6 md:col-6 md:mb-5">
                <img
                  src={Constants.BASE_URL + `${item.icon}`}
                  width={20}
                  height={20}
                  alt=""
                ></img>
                <span className="ml-3 font-medium text-black text-lg ">
                  {item?.propertyType}
                </span>
              </div>
            );
          })}
      </div>
      {postData?.propertyType?.length > 2 ? (
        <span
          className=" buyer-property-feature-see-more  text-main  w-full md:w-auto flex align-items-center  text-lg font-medium mb-5 mt-2 "
          onClick={handleClick}
        >
          See more features &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer mr-2 ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      ) : null}
      <hr className="buyer-hr m-0 mt-1 mb-3" />
    </>
  );
};

export const PropertyfeaturesContent = ({ postData, outdoor }) => {
  const [check, setChecked] = useState();
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  return (
    <>
      <div className=" align-items-center  justify-content-between w-full ">
        <p className=" vertical-align-middle text-2xl text-dark font-medium  ">
          Property features
        </p>
      </div>

      <div
        className={`grid overflow-hidden ${
          showMore ? "show-all " : "show-min"
        }`}
      >
        {outdoor?.length > 0 ? (
          outdoor?.length > 0 &&
          outdoor.map((item) => {
            return (
              <div className="col-12 md:col-6 lg:col-6  mb-2 ">
                <div className="flex align-items-center">
                  <img
                    src={Constants.BASE_URL + `${item?.icon}`}
                    width={24}
                    height={24}
                    alt=""
                  ></img>
                  <span className="ml-3 font-medium text-black text-base">
                    {item?.title}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <span className="ml-3 font-medium text-black text-base">N/A</span>
        )}
      </div>
      {outdoor?.length > 2 ? (
        <span
          className="mt-3  buyer-property-feature-see-more  text-main  w-full md:w-auto flex align-items-center  text-lg font-medium mb-5 mt-2 "
          onClick={handleClick}
        >
          See more features &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer mr-2 ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      ) : null}
    </>
  );
};

export const PropertySummary = ({ outdoor }) => {
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  return (
    <>
      <div className=" align-items-center  justify-content-between w-full  ">
        <p className=" vertical-align-middle text-2xl text-dark font-medium  ">
          Property Summery
        </p>
      </div>
      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {outdoor?.length > 0 &&
          outdoor?.map((item, index) => {
            return (
              <div className="col-12 md:col-6 lg:col-6 mb-2 text-lg">
                <p className="p-0 m-0 text-black font-medium ">
                  {item?.title} = <cod className="text-dark text-lg">Yes</cod>
                </p>
              </div>
            );
          })}
      </div>
      <span
        className=" property-summary-see-more text-main  w-full md:w-auto flex align-items-center  text-lg font-medium mt-2 mb-5"
        onClick={handleClick}
      >
        See more &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore == true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
    </>
  );
};

export const SellerDescription = ({ postData }) => {
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };

  return (
    <>
      <div className=" align-items-center  justify-content-between w-full  ">
        <h2 className=" vertical-align-middle text-2xl text-dark font-medium  ">
          Property Description
        </h2>
      </div>
      <div
        className={`grid overflow-hidden mb-3 ${
          showMore ? "show-all" : "show-min"
        }`}
      >
        <div className="col-12 md:col-12 lg:col-12">
          <p
            className="p-0 m-0 text-black font-medium text-base "
            style={{ lineHeight: "23px" }}
          >
            {postData?.Description === "" || postData?.Description === null
              ? "N/A"
              : postData?.Description}
          </p>
        </div>
      </div>
      {postData?.Description?.length > 100 ? (
        <span
          className=" seller-description-see-more text-main w-full md:w-auto flex align-items-center  text-lg font-medium mt-2 mb-5"
          onClick={handleClick}
        >
          See more &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer mr-2 ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      ) : null}
    </>
  );
};

export const Form = ({
  form,
  setForm,
  handleSubmit,
  code,
  setCode,
  postData,
  props,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formErrors = allValidations(name, value, form);
    setForm((prev) => ({ ...prev, [name]: value, formErrors }));
  };
  useEffect(() => {
    setForm({
      ...form,
      country: code,
    });
  }, [code]);
  function callPhoneNumber(phoneNumber) {
    window.location.href = "tel:" + phoneNumber;
  }

  return (
    <>
      <div
        className="bg-light lg:p-5 md:p-3 p-2 lg:ml-3 md:ml-1  "
        style={{ borderRadius: "10px" }}
      >
        <div className="text-center ">
          <img
            src={Logoimg}
            alt=""
            height={56}
            width={229}
            className="lg:mt-3"
          />
        </div>
        <p className="font-medium text-xl text-black text-center mb-4 ">
          {postData?.postType === "SELLER"
            ? "Seller Id"
            : postData?.postType === "BUYER"
            ? "Buyer Id"
            : "Renter Id"}{" "}
          :{" "}
          <cod className="whatsapp font-medium text-dark">
            {postData?.postType === "SELLER"
              ? `S ${postData?.id}`
              : postData?.postType === "BUYER"
              ? `B ${postData?.id}`
              : `R ${postData?.id}`}
          </cod>{" "}
        </p>
        <p className="text-lg text-dark font-medium text-center">
          Send us a message
        </p>
        {/* <p className="font-medium text-xl text-dark text-center ">Send message to Agent by <b>WhatsApp</b></p> */}
        <div
          className=" flex justify-content-center col-12  cursor-pointer"
          onClick={() => callPhoneNumber("0483 91 38 38")}
        >
          <p className="border-1 flex align-items-center border-main p-3 p-0 m-0  border-round-xl w-full lg:w-6 md:w-full justify-content-center">
            {/* <img src={WhatsappIcon} height={24} width={24} alt="" /> */}
            <i className="pi pi-phone" style={{ marginRight: "5px" }}></i>
            <i className="pi pi-envelope"></i>
            <span className="whatsapp ml-2 font-medium text-black text-base">
              0483 91 38 38
            </span>
          </p>
        </div>
        <p className="text-dark font-medium text-2xl text-center">
          Or Submit a enquiry to Agent
        </p>
        <CustomInput
          placeholder="Full Name"
          inputClassName=""
          extraClassName="w-full "
          name="fullName"
          value={form?.fullName}
          onChange={handleChange}
          errorMessage={form?.formErrors?.fullName}
        />
        <CustomInput
          placeholder="Email"
          extraClassName="w-full"
          name="email"
          value={form?.email}
          onChange={handleChange}
          errorMessage={form?.formErrors?.email}
        />
        <CustomPhoneInput
          extraClassName="contact w-full "
          name="mobile"
          placeholder="Number"
          value={form?.mobile}
          onChange={handleChange}
          setCode={setCode}
          code={code}
          errorMessage={form?.formErrors?.mobile}
        />
        <CustomTextArea
          col="12"
          className="input_textarea w-full bg-transparent  p-3  mb-3"
          placeholder="Your Message (optional)"
          name="message"
          value={form?.message}
          onChange={handleChange}
        />
        <div className="button ml-1 mr-1">
          <ButtonOutlined
            col="12"
            label="SEND ENQUIRY "
            className="font-semibold  "
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export const ClimateControl = ({ PropertyList, form, setForm, id }) => {
  const [check, setChecked] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  const handleTypeChange = (e) => {
    let selectType = [...check];
    if (e.checked) {
      selectType.push(e.value);
    } else {
      selectType = selectType.filter((type) => type._id !== e.value._id);
    }
    setChecked(selectType);
    setForm({
      ...form,
      climateControlEnergy: selectType,
    });
  };
  useEffect(() => {
    if (id || form?.climateControlEnergy) {
      setChecked(form?.climateControlEnergy);
    }
  }, [id, form?.climateControlEnergy]);
  return (
    <>
      <span className=" vertical-align-middle text-2xl text-dark font-bold block md:hidden mb-3 ml-1 ">
        Climate control & energy
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-bold  flex align-items-start"
          style={{ marginLeft: "4px" }}
        >
          Climate control & energy
        </span>
        {form?.formErrors?.climateControlEnergy && (
          <small className="p-error text-sm">
            {form?.formErrors?.climateControlEnergy}
          </small>
        )}
        <span
          className="text-main mr-1 text-lg font-medium"
          onClick={handleClick}
        >
          {showMore == true
            ? "See fewer climate control & energy"
            : "See More climate control & energy"}{" "}
          &nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        {PropertyList?.propertyFeature?.length > 0 &&
          PropertyList?.propertyFeature?.map((item) => {
            return (
              <>
                {item?.forFeature === "CLIMATECONTROLENERGY" ? (
                  <div className="col-12 md:col-6">
                    <CustomCheckbox
                      optionLabel={item?.title}
                      id={item?.title}
                      name="features"
                      value={item}
                      onChange={handleTypeChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        check?.some((type) => type._id === item._id)
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <span
        className="text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1"
        onClick={handleClick}
      >
        {showMore == true
          ? "See fewer climate control & energy"
          : "See More climate control & energy"}{" "}
        &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore == true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};

export const RenterClimateControl = () => {
  const [check, setChecked] = useState();
  const [showMore, setShowMore] = useState(false);

  const handleClick = (event) => {
    setShowMore((current) => !current);
  };
  return (
    <>
      {/* <Accordion activeIndex={0}>
        <AccordionTab
          className="accordin"
          header={
            
          }
        > */}
      <span className=" vertical-align-middle text-2xl text-dark font-medium block md:hidden mb-3 ml-1 ">
        Climate control & energy
      </span>
      <div className=" align-items-center  justify-content-between w-full mb-3 hidden md:flex">
        <span
          className=" vertical-align-middle text-2xl text-dark font-medium  "
          style={{ marginLeft: "4px" }}
        >
          Climate control & energy
        </span>
        <span
          className="text-main mr-1 text-lg font-medium"
          onClick={handleClick}
        >
          See more climate control & energy&nbsp;
          <i
            className={`pi pi-chevron-down cursor-pointer mr-2 ${
              showMore == true ? "rotate-180" : ""
            }`}
          ></i>
        </span>
      </div>

      <div
        className={`grid overflow-hidden ${showMore ? "show-all" : "show-min"}`}
      >
        <div className="col-12 md:col-6 lg:col-6 ">
          <CustomCheckbox
            optionLabel="Air conditioning"
            id="Air conditioning"
            name="features"
            value="Air conditioning"
            onChange={(e) => setChecked(e.value)}
            checked={check === "Air conditioning"}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-6 ">
          <CustomCheckbox
            optionLabel="Solar panels"
            id="Solar panels"
            name="features"
            value="Solar panels"
            onChange={(e) => setChecked(e.value)}
            checked={check === "Solar panels"}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-6 ">
          <CustomCheckbox
            optionLabel="Heating"
            id="Heating"
            name="features"
            value="Heating"
            onChange={(e) => setChecked(e.value)}
            checked={check === "Heating"}
          />
        </div>
        <div className="col-12 md:col-6  lg:col-6 ">
          <CustomCheckbox
            optionLabel="High energy efficincy"
            id="High energy efficincy"
            name="features"
            value="High energy efficincy"
            onChange={(e) => setChecked(e.value)}
            checked={check === "High energy efficincy"}
          />
        </div>
        <div className="col-12 md:col-6  lg:col-6 ">
          <CustomCheckbox
            optionLabel="Water tank"
            id="Water tank"
            name="features"
            value="Water tank"
            onChange={(e) => setChecked(e.value)}
            checked={check === "Water tank"}
          />
        </div>
        <div className="col-12 md:col-6  lg:col-6 ">
          <CustomCheckbox
            optionLabel="Solar hot water"
            id="Solar hot water"
            name="features"
            value="Solar hot water"
            onChange={(e) => setChecked(e.value)}
            checked={check === "Solar hot water"}
          />
        </div>
      </div>
      <span
        className="text-main mr-2 w-full md:w-auto flex md:hidden justify-content-between text-lg font-medium ml-1"
        onClick={handleClick}
      >
        See more climate control & energy &nbsp;
        <i
          className={`pi pi-chevron-down cursor-pointer mr-2 ${
            showMore == true ? "rotate-180" : ""
          }`}
        ></i>
      </span>
      {/* </AccordionTab>
      </Accordion> */}
    </>
  );
};

export const ConfirmContactDetails = ({ data }) => {
  const [check, setChecked] = useState();
  return (
    <>
      <div className="grid p-0 m-0 ">
        {/* Contact-Details Section */}
        <div className="col-12 md:col-12">
          <h2 className=" text-dark font-bold text-2xl">Contact details</h2>
        </div>
        <div className="col-12 md:col-12 mb-3">
          <div className="flex align-items-center">
            <img src={UserIcon} width={20} height={20} alt=""></img>
            <span className="ml-3 font-medium text-black font-semibold">
              {data?.firstName} {data?.lastName}
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 mb-3">
          <div className="flex align-items-center">
            <img src={MessageIcon} width={20} height={20} alt=""></img>
            <span className="ml-3 font-medium text-black font-semibold">
              {data?.email}
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 mb-3">
          <div className="flex align-items-center">
            <img src={CallingIcon} width={20} height={20} alt=""></img>
            <span className="ml-3 font-medium text-black font-semibold">
              {data?.countryCode} {data?.mobile}
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 mb-3">
          <div className="flex align-items-center">
            <img src={LocationIcon} width={20} height={20} alt=""></img>
            <span className="ml-3 font-medium text-black font-semibold">
              {data?.suburb}
            </span>
          </div>
        </div>
      </div>
      <div className="col-12 md:col-12">
        <hr className="buyer-hr p-0 m-0" />
      </div>
    </>
  );
};
