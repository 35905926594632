import React, { useState } from "react";
import AppleIcon from "../../assets/icons/apple.png";
import FacebookIcon from "../../assets/icons/Facebook.png";
import GoogleIcon from "../../assets/icons/google.png";
import SignInImg from "../../assets/images/sign_in.png";
import { Link } from "react-router-dom";
import {
  ButtonOutlined,
  SocialLoginButton,
} from "../../components/buttons/button";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { CustomInput, CustomPassword } from "../../components/input/AllInput";
import Loginfunction from "./loginfunction";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { PickModalProfile } from "../../components/modal/PickModalProfile";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
export default function SignIn({ SetSelectProfile, selectProfile }) {
  const navigate = useNavigate();
  const [SelectRole, setSelectRole] = useState(false);
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const { handleSubmit, form, handleChange, googleLoginHandler } =
    Loginfunction();
  //commit
  //commit
  return (
    <>
      <div className="login_container">
        <div className="  bg-light-gray pt-8 ">
          <ContainerBox>
            <div className="grid p-0 m-0 flex justify-content-around lg:py-6 md:py-6 py-5 mr-2 ml-2">
              <div className="col-12 md:col-6 p-0 lg:blcok md:block hidden ">
                <img
                  src={SignInImg}
                  height={900}
                  alt=""
                  className=" w-full  h-h-6rem lg:h-full login_image"
                ></img>
              </div>
              <div className="col-12 md:col-6 bg-light p-0">
                <h1 className="text-center text-4xl text-dark font-semibold mb-5">
                  Sign In
                </h1>
                <div className="grid col-12 m-auto  lg:p-5 mt-3 justify-content-center flex">
                  <CustomInput
                    type="Email"
                    placeholder="Email"
                    label="Email"
                    col="12"
                    onChange={handleChange}
                    name="email"
                    errorMessage={form?.formErrors?.email}
                    required
                  />
                  <CustomPassword
                    col="12"
                    label="Password"
                    placeholder="Password"
                    onChange={handleChange}
                    name="loginPassword"
                    errorMessage={form?.formErrors?.loginPassword}
                    required
                  />
                  <div className="px-1 w-full ">
                    <ButtonOutlined
                      label="SIGN IN"
                      onClick={() => {
                        handleSubmit();
                        gotoBtn();
                      }}
                    />
                  </div>
                  <div className="w-full px-1 my-4">
                    <hr
                      className=""
                      style={{ color: "rgba(143, 154, 153, 1)" }}
                    />
                  </div>
                  <div className="w-full px-1">
                    <SocialLoginButton
                      onClick={googleLoginHandler}
                      src={GoogleIcon}
                      label="CONTINUE WITH GOOGLE"
                      className=" bg-transparent font-semibold border-noround text-center text-dark"
                    />
                  </div>
                  {/* <div className="w-full px-1">
                    <SocialLoginButton
                      src={AppleIcon}
                      label="CONTINUE WITH APPLE"
                      className="bg-dark text-gray-light"
                    />
                  </div> */}
                  {/* <div className="w-full px-1">
                    <SocialLoginButton
                      src={FacebookIcon}
                      label="CONTINUE WITH FACEBOOK"
                      className="bg-blue-light text-gray-light"
                    />
                  </div> */}

                  <div className="col-12 md:col-11 mb-3">
                    <Link
                      to="/forgot-password"
                      className="no-underline text-center font-medium text-dark"
                    >
                      <p
                        className="text-dark font-medium text-lg"
                        onClick={gotoBtn}
                      >
                        Forgot your password?
                      </p>
                    </Link>
                    <p className="text-center font-medium text-dark">
                      Don't have an account?{" "}
                      <cod
                        className="text-main font-medium text-lg cursor-pointer "
                        onClick={() => {
                          gotoBtn();
                          setSelectRole(true);
                        }}
                      >
                        Sign Up
                      </cod>
                    </p>
                  </div>
                </div>
              </div>
              {SelectRole === true ? (
                <div className="profile-modal">
                  <Dialog
                    style={{ minHeight: "100px", maxWidth: "615px" }}
                    visible={SelectRole}
                    onHide={() => setSelectRole(false)}
                    className="profile-modal w-full"
                    closable={true}
                  >
                    <PickModalProfile
                      SelectRole={SelectRole}
                      setSelectRole={setSelectRole}
                    />
                  </Dialog>
                </div>
              ) : (
                ""
              )}
            </div>
          </ContainerBox>
        </div>
        {selectProfile === true ? (
          <div className="landing-page-modal">
            <CustomDialog
              header={
                <h2
                  className="mt-0 font-semibold text-dark mt-3 ml-3"
                  style={{ fontSize: "32px" }}
                >
                  Pick a Profile to Create a Brief
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                </h2>
              }
              visible={selectProfile}
              onHide={() => SetSelectProfile(false)}
            >
              <PickProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            </CustomDialog>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
