import { types } from "../types";
import Constants from "../../services/constant";
import api from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { DeleteApi } from "../../services/forgetApi";

export const allPostAction = (data) => async (dispatch) => {
  const res = await api("post", Constants.END_POINT.AllPOST, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ALL_POST,
        payload: res.data.reverse(),
      });
    }
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const postDetailAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", `${Constants.END_POINT.POSTDETAIL}/${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.POST_DETAIL,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const postSavedUnSavedAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", `${Constants.END_POINT.SAVEDUNSAVEDPOST}/${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_POST_STATUS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    if (res.message === "Unauthorized Access!...") {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  }
};

export const getpostSavedAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.GETSAVEDPOST);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SAVED_UNSAVED_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    if (res.message === "Unauthorized Access!...") {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  }
};

export const deletePostAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await DeleteApi(
    "delete",
    `${Constants.END_POINT.DELETEPOST}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editPostAction = (data, id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("put", `${Constants.END_POINT.UPDATEPOST}/${id}`, data);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.UPDATE_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewPostAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", `${Constants.END_POINT.VIEWPOST}/${id}`);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_POST,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    return res;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
