import React from "react";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import Siderbar from "../../components/sidebar/Siderbar";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { CustomPassword } from "../../components/input/AllInput";
import { Link, useNavigate } from "react-router-dom";
import { NewPassword } from "./NewPassword";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import { isAuthenticated } from "../../services/authService";

const ChangePassword = ({ SetSelectProfile, selectProfile }) => {
  const {
    password,
    handleChangePassword,
    handleSubmit,
    handleCancel,
    showButton,
  } = NewPassword();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  return (
    <>
      <>
        <div className="user-profile-container pt-8 mt-4">
          <ContainerBox>
            <div className="grid sidebar-container lg:py-6 md:py-6 p-0 m-0  flex justify-content-between ">
              <div className="col-12 md:col-4 lg:col-3 sidebar border-round-lg">
                <Siderbar />
              </div>
              <div className="col-12 md:col-8 lg:col-9 ">
                <div className="lg:ml-5">
                  <h2 className="text-dark font-semibold text-4xl p-0 m-0 mb-5 mt-2">
                    {" "}
                    Change Password
                  </h2>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <CustomPassword
                        col="12"
                        label="Old Password"
                        placeholder="Password"
                        value={password.password}
                        name="password"
                        onChange={handleChangePassword}
                        errorMessage={password?.formErrors?.password}
                      ></CustomPassword>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <CustomPassword
                        col="12"
                        label="New Password"
                        placeholder="Password"
                        value={password.oldPassword}
                        name="oldPassword"
                        onChange={handleChangePassword}
                        errorMessage={password?.formErrors?.oldPassword}
                      ></CustomPassword>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <CustomPassword
                        col="12"
                        label="Confirm Password"
                        placeholder="Password"
                        value={password.newPassword}
                        onChange={handleChangePassword}
                        name="newPassword"
                        errorMessage={password?.formErrors?.newPassword}
                      ></CustomPassword>
                    </div>
                  </div>
                  <div className={showButton === false ? "hidden" : "block "}>
                    <div className=" grid mb-3">
                      <div
                        className="col-12 md:col-6"
                        onClick={() => handleSubmit()}
                      >
                        <ButtonComponent
                          label="CHANGE PASSWORD"
                          className="text-light-cyan"
                        ></ButtonComponent>
                      </div>
                    </div>
                    <div className="grid mb-3">
                      <div
                        className="col-12 md:col-6"
                        onClick={() => handleCancel()}
                      >
                        <ButtonOutlined label="CANCEL"></ButtonOutlined>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <main>{Children}</main> */}
          </ContainerBox>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {!isAuthenticated() ? (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                ) : (
                  ""
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ChangePassword;
