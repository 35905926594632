import React, { useRef, useState } from "react";
import { ContainerBox } from "../../shared/ContainerBox";
import Video from "../../assets/images/Frame 1000002945.png";
import Certified from "../../assets/images/Certified.png";
import OurStory from "../../assets/images/our-Story.png";
import OurCommunity from "../../assets/images/our-community.png";
import OurPartners from "../../assets/images/our-partners.png";
import Rating from "../../assets/images/Stars.png";
import { Input } from "antd";
import { ProductService } from "./ProductService";
import ButtonComponent from "../../components/buttons/button";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastAction";
import { emailValidation } from "../../utils/regex";
import { allValidations } from "../../utils/formValidation";
import { createNewsLetter } from "../../redux/actions/buyerAction";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
import Videos from "../../assets/video/video.mp4";
import RealEstateVideo from "../../assets/video/video.mp4";
import OverlayImage from "../../assets/images/banner-Image.jpeg";
import playIcon from "../../assets/icons/playicon.png";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="pi pi-arrow-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="pi pi-arrow-left"></i>
    </div>
  );
}

const Aboutus = ({ SetSelectProfile, selectProfile }) => {
  const [form, setForm] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const formErrorsCheck = () => {
    let formErrors = {};
    if (form?.email === "" || form?.email === undefined) {
      formErrors.email = "Please Enter Email";
      dispatch(showToast({ severity: "error", summary: formErrors.email }));
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
      dispatch(showToast({ severity: "error", summary: formErrors.email }));
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubscribe = () => {
    if (!formErrorsCheck()) {
      return false;
    } else {
      dispatch(createNewsLetter(form)).then((res) => {
        if (res.success === true) {
          setForm({
            email: "",
          });
        }
      });
    }
  };
  const handleChange = (e) => {
    const formErrors = allValidations("email", e?.target?.value, form);
    setForm({
      ...form,
      email: e.target.value,
      formErrors,
    });
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <>
      <div className=" pt-4 lg:pt-8 md:pt-8  mt-4  bg-light">
        <ContainerBox>
          <div className="col-12 about-section flex justify-content-center flex-wrap pt-4 md:py-4">
            <div className="col-12 lg:col-12 ">
              <h1 className="text-56 text-dark font-semibold text-center title">
                Welcome to The Buyers Place
              </h1>
              <h3 className="font-medium text-dark text-lg my-3">
                Discover a revolution in real estate with Australia's first-ever
                platform exclusively designed for Property Buyers.
              </h3>
              <h1 className="text-lg text-dark font-normal my-3 ">
                The Buyers Place is rewriting the rules of property purchase by
                flipping the traditional search process on its head.
              </h1>
              <h3 className="font-medium text-dark text-lg my-3">
                No longer do buyers have to spend countless hours searching for
                property the traditional way. Now, with The Buyers Place,
                sellers actively seek out buyers instead.
              </h3>
              <h1 className="text-lg text-dark font-normal my-3">
                It's a game-changer that simplifies the entire property purchase
                process.
              </h1>
              <h3 className="font-medium text-dark text-lg my-3">
                Buyers create a Brief detailing their ideal property, and
                sellers or property owners can then find and connect with
                matching buyers easily!
              </h3>
              <h1 className="text-lg text-dark font-normal my-3 ">
                The Buyers Place is all about simplifying the property journey,
                making it a hassle-free experience.
              </h1>
              <h3 className="font-medium text-dark text-lg my-3">
                We have also built trusted relationships with industry-related
                experts so that we can assist you at any stage of your property
                journey. Whether it's navigating finances or ensuring a smooth
                selling process, your seamless property journey starts here.
              </h3>
            </div>
            <div className="col-12 lg:col-6 mt-2">
              <div className="relative">
                {/* {setVideo === true ? ( */}
                {!isPlaying ? (
                  <img
                    src={OverlayImage}
                    alt=""
                    className="absolute w-full h-full"
                  />
                ) : null}

                {/* ) : null} */}

                <video
                  ref={videoRef}
                  src={RealEstateVideo}
                  autoPlay={isPlaying}
                  className="w-full h-full"
                  controls={true}
                  onClick={() => setIsPlaying(false)}
                ></video>
                {!isPlaying ? (
                  <button className="play-btn" onClick={togglePlay}>
                    <img
                      src={playIcon}
                      alt=""
                      className="text-center ml-1 mt-1"
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="col-12 flex  justify-content-center md:py-4">
            <div className="col-12 lg:col-8">
              <img
                src={Certified}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div> */}
          {/* <div className="col-12 lg:col-5">
              <img
                src={OurStory}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div> */}
          {/* <div className="col-12 lg:col-12 ml-auto flex flex-column justify-content-center text-center p-0">
            <h1 className="text-lg text-dark font-normal mb-2 ">
              The Buyers Place is rewriting the rules of property purchase by
              flipping the traditional search process on its head.
            </h1>
            <h3 className="font-medium text-dark text-lg mt-2 mb-0">
              No longer do buyers have to spend countless hours searching for
              property the traditional way. Now, with The Buyers Place, sellers
              actively seek out buyers instead.
            </h3>
          </div> */}
          {/* <div className="col-12 flex flex-wrap	lg:flex-row p-0 ">
            <div className="col-12 lg:col-12 mr-auto flex flex-column justify-content-center p-0 text-center">
              <h1 className="text-lg text-dark font-normal mb-2 mt-2">
                It's a game-changer that simplifies the entire property purchase
                process.
              </h1>
              <h3 className="font-medium text-dark text-lg mt-2 mb-0">
                Buyers create a Brief detailing their ideal property, and
                sellers or property owners can then find and connect with
                matching buyers easily!
              </h3>
            </div> */}
          {/* <div className="col-12 lg:col-5 ">
              <img
                src={OurCommunity}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div> */}
          {/* </div> */}
          {/* <div className="col-12 lg:col-5">
              <img
                src={OurPartners}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div> */}
          {/* <div className="col-12 lg:col-12 lg:ml-auto flex flex-column justify-content-center text-center p-0 mb-4">
            <h1 className="text-lg text-dark font-normal mb-2 mt-2 ">
              The Buyers Place is all about simplifying the property journey,
              making it a hassle-free experience.
            </h1>
            <h3 className="font-medium text-dark text-lg mt-2 mb-0">
              We have also built trusted relationships with industry-related
              experts so that we can assist you at any stage of your property
              journey. Whether it's navigating finances or ensuring a smooth
              selling process, your seamless property journey starts here.
            </h3>
          </div> */}
          {/* <div className="col-12 pt-0 pb-8 lg:py-4">
            <div className=" ml-auto flex flex-column">
              <h1 className="text-lg text-dark font-semibold m-0 md:mb-0">
                Customer testimonials
              </h1>
              <h3 className="font-medium text-black text-lg ">
                Here’s what our customers have to say about their car rental
                booking experience.
              </h3>
              <div className="card about-carousel lg:my-8">
                <Slider {...settings}>
                  {ProductService.map((item) => {
                    return (
                      <div>
                        <div className="lg:pb-8 pt-3 flex flex-column h-full justify-content-between ">
                          <div>
                            <img src={Rating} alt="" />
                          </div>
                          <h3 className="font-medium text-black text-lg pr-3">
                            {item.heading}
                          </h3>
                          <div className="">
                            <h4
                              className="mb-0 font-medium"
                              style={{ color: "#443936" }}
                            >
                              {item.name}
                            </h4>
                            <small className="text-sm">{item.date}</small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div> */}
          <div className="col-12 bg-white p-3 border-round-3xl">
            <div className=" flex flex-column text-center  justify-content-center align-items-center">
              <h1 className="text-56 text-dark font-semibold mb-0">
                Subscribe to our newsletter
              </h1>
              <h3 className="font-medium text-black text-lg ">
                Sign up and stay updated on our special offers and best deals.
              </h3>
            </div>
            <div className="  col-12 lg:col-4 lg:mt-5 w-full ">
              <div className=" footer-email-sbuscribe-button w-full  flex justify-content-center align-items-center ">
                <div className="w-full ">
                  <div className="flex align-items-center justify-content-center w-full ">
                    <p className=" FOOTER-BUTTON block md:flex  w-full md:w-6  lg:w-6  md:border-1 border-gray-400 border-round-xl border-round-xl md:overflow-hidden  p-0">
                      <Input
                        className=" border-1 md:border-none p-2 border-round-lg mb-3 md:m-0 md:px-2  "
                        placeholder="Please enter your email address"
                        extraClassName="mb-0 "
                        onChange={(e) => handleChange(e)}
                        value={form?.email}
                      />
                      <ButtonComponent
                        label="SUBSCRIBE"
                        className=" p-3 text-light border-none font-semibold "
                        onClick={handleSubscribe}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Aboutus;
