import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ButtonOutlined } from "../../components/buttons/button";
import { CustomInput } from "../../components/input/AllInput";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import { forgetPasswordAction } from "../../redux/actions/signupAction";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidation";
import { showFormErrors } from "../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
export default function Forgot({ SetSelectProfile, selectProfile }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formErrors = allValidations(name, value, form);
    setForm((prev) => ({ ...prev, [name]: value, formErrors }));
  };
  const handleSubmit = () => {
    if (showFormErrors(form, setForm)) {
      dispatch(forgetPasswordAction(form, () => navigate("/reset-password")));
    }
  };
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  return (
    <>
      <>
        <div className="forgot-container pt-8 ">
          <div
            className=" py-6 "
            style={{ backgroundColor: "rgba(244, 247, 247, 1)" }}
          >
            <ContainerBox>
              <div className="grid p-0 m-0 flex justify-content-center bg-light ml-2 mr-2 ">
                <div className="col-12 md:col-6 ">
                  <h1 className="font-semibold text-dark text-4xl text-center">
                    Forgot Password
                  </h1>
                  <CustomInput
                    type="Email"
                    placeholder="Email"
                    label="Email"
                    col="12"
                    name="email"
                    onChange={handleChange}
                    errorMessage={form?.formErrors?.email}
                    required
                  />
                  <Link
                    to=""
                    className="no-underline w-full"
                    onClick={handleSubmit}
                  >
                    {/* <ButtonOutlined label="RESET PASSWORD" className="" /> */}
                    <div className="px-1 w-full ">
                      <ButtonOutlined label="RESET PASSWORD" />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="grid p-0 m-0 flex justify-content-center bg-light ml-2 mr-2 ">
                <div className="col-12 md:col-12 text-center mb-5">
                  <p className="font-medium text-dark">
                    Already have an account?
                    <Link
                      to="/sign-in"
                      className="no-underline"
                      onClick={gotoBtn}
                    >
                      <cod className="text-main ml-1 font-medium text-lg">
                        Log In
                      </cod>
                    </Link>
                  </p>
                </div>
              </div>
            </ContainerBox>
          </div>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
