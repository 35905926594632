import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileAction, updateProfileAction, changePasswordAction } from "../../redux/actions/profileAction";
import { useState } from "react";
import { allValidations } from "../../utils/formValidation";
import { singleFileAction } from "../../redux/actions/selerAction";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { showFormErrors } from "../../utils/commonFunction";
import { emailValidation } from "../../utils/regex";
export const CommonField = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(myProfileAction())
    }, [])
    const [code, setCode] = useState("61+");
    const [showButton, setShowButton] = useState(false)
    const [visible, setVisible] = useState(false);
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobile: "",
        image: "",
        suburb: "",
        streetAddress: "",
        role: "",
        zipCode: "",
        state: ""

    })
    const myProfileData = useSelector((state) => state.myProfileData.myProfile)
    useEffect(() => {
        if (myProfileData) {
            setForm({
                ...form,
                firstName: myProfileData?.firstName,
                lastName: myProfileData?.lastName,
                email: myProfileData?.email,
                countryCode: myProfileData?.countryCode,
                mobile: myProfileData?.mobile,
                image: myProfileData?.image,
                suburb: myProfileData?.suburb,
                role: myProfileData?.role,
                streetAddress: myProfileData?.streetAddress,
                zipCode: myProfileData?.zipCode,
                state: myProfileData?.state
            })
            setCode(myProfileData?.countryCode)
        }
    }, [myProfileData])
    const handleChange = (e) => {
        setShowButton(true)
        const { name, value } = e.target
        const formErrors = allValidations(name, value, form)
        setForm((prev) => {
            return {
                ...prev,
                [name]: value, formErrors
            }
        })
    }
    useEffect(() => {
        dispatch(singleFileAction(form.image))
    }, [form?.image])
    const getImagePath = useSelector((state) => state.singleUploadImage.singleUploadProfile)
    const profileFormCheck = () => {
        let formErrors = {}
        if (form.firstName === "") {
            formErrors.firstName = "Please Enter First Name"

        }
        if (form.lastName === "") {
            formErrors.lastName = "Please Enter Last Name"

        }
        if (form.email === "") {
            formErrors.email = "Please Enter Email"

        }
        else if (!emailValidation(form.email)) {
            formErrors.email = `Please enter valid email!`;
        }
        if (form.mobile === "") {
            formErrors.mobile = "Please Enter Mobile Number"

        }
        else if(isNaN(form.mobile)==true){
            formErrors.mobile = "Please Enter Digit Only"
          }
        if (form.suburb === "") {
            formErrors.suburb = "Please Enter Street Address"
        } if (Object.keys(formErrors).length == 0) {
            return true
        }
        else {

            setForm({ ...form, formErrors })
            return false
        }
    }
    const handleSubmit = async () => {
        let payload = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryCode: form?.countryCode,
            mobile: form?.mobile,
            image: getImagePath?.file,
            suburb: form?.suburb,
            streetAddress: form?.streetAddress,
            role: form?.role,
            zipCode: form?.zipCode,
            state: form?.state

        }
        if (!profileFormCheck()) {
            return false
        } else {
            dispatch(updateProfileAction(payload,()=>{
                dispatch(myProfileAction())
                setVisible(true)
            }))
        }
        setShowButton(false)
    }
    const handlechangeAddress = (newAddress) => {
        setShowButton(true)
        setForm({
            ...form,
            suburb: newAddress
        })
    }
    const handleSelectAddress = (newAddress) => {
        const formErrors = allValidations("suburb", newAddress, form)
        setShowButton(true)
        let obj = {
            state: "",
            city: "",
            zipcode: "",
            suburb: ""
        }
        geocodeByAddress(newAddress)
            .then((results) => getLatLng((results[0]?.address_components?.length > 0 && results[0]?.address_components?.map
                ((item) => {
                    if (item?.types?.includes("administrative_area_level_1")) {
                        obj.state = item.long_name

                    } else if (item?.types?.includes("postal_code")) {
                        obj.zipcode = item.long_name

                    } else if (item?.types?.includes('locality')) {
                        obj.city = item.long_name
                    }

                })

            ),
                setForm({
                    ...form,
                    state: obj.state,
                    zipCode: obj.zipcode,
                    suburb: newAddress + " " +  obj.zipcode,
                    streetAddress: newAddress + " " +  obj.zipcode,
                    city: obj.city,
                    formErrors
                })
            ))
            .then((latLng) => console.log("Success", latLng))
            .catch((error) => console.error("Error", error));
    }
    useEffect(() => {
        if (code) {
            setForm({
                ...form,
                countryCode: code
            })
        } else {
            setForm({
                ...form,
                countryCode: "+61"
            })
        }
    }, [code, form.mobile])
    const handleCancel = () => {
        setForm({
            ...form,
            firstName: myProfileData?.firstName,
            lastName: myProfileData?.lastName,
            email: myProfileData?.email,
            countryCode: myProfileData?.countryCode,
            mobile: myProfileData?.mobile,
            image: myProfileData?.image,
            suburb: myProfileData?.suburb,
            role: myProfileData?.role,
            streetAddress: myProfileData?.streetAddress,
            zipCode: myProfileData?.zipCode,
            state: myProfileData?.state
        })
    }
    return { myProfileData, form, setForm, handleChange, handleSubmit, visible, setVisible, handlechangeAddress,  handleSelectAddress, code, handleCancel, setCode, setShowButton, showButton }
}