import React, { useState } from "react";
import { Dialog } from "primereact/dialog";

export default function CustomDialog({ children, onHide, header, ...props }) {
  const [selectProfile, SetSelectProfile] = useState();
  const Defaultheader = () => {
    return (
      <h2
        className="mt-0 font-semibold text-dark mt-3 ml-3"
        style={{ fontSize: "32px" }}
      >
        Pick a Profile to Create a Brief
      </h2>
    );
  };
  return (
    <div className="dailog">
      <Dialog
        className="lg:w-5 md:w-12 w-11 landing-page-dailog border-round-lg "
        header={header ? header : Defaultheader}
        onHide={onHide}
        dismissableMask={true}
        closable={true}
        draggable={false}
        resizable={false}
        position={"center"}
        style={{ minHeight: "100px", maxWidth: "615px" }}
        {...props}
      >
        <div className="">{children}</div>
      </Dialog>
    </div>
  );
}
