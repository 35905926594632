import React from "react";

import { Button } from "primereact/button";

export default function ButtonComponent({
  onClick,
  label,
  loading,
  onChange,
  style,
  icon,
  iconPos,
  className,
  emailCheck, form,
  token,
  ...props
}) {
  return (
    <Button label={label} style={style} className={`bg-main border-main  w-full  p-3 font-semibold  ${className}`} onClick={onClick} loading={loading} {...props}  />
  )
}
export function ButtonOutlined({ onClick, label, Link, className, loading, ...props }) {
  return (
    <Button
      label={label}
      className={`border-button-component font-semibold border-noround  p-3  text-center text-main w-full ${className}`}
      onClick={onClick}
      loading={loading}
      Link={Link}
      {...props}
    />
  );
}
export const SocialLoginButton = ({
  col,
  onClick,
  loading,
  src,
  label,
  className,
  ...props
}) => {
  return (
    <Button
      className={`p-3 mb-4 w-full ${className}`}
      onClick={onClick}
      loading={loading}
      {...props}
      style={{ borderColor: "rgba(204, 209, 209, 1)" }}
    >
      <img src={src} height={24} width={24} alt=""></img>
      <span className="ml-auto mr-auto font-semibold">{label}</span>
    </Button>
  );
};
