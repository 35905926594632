import React, { useEffect, useState } from "react";
import {
  CustomInput,
  CustomPhoneInput,
  CustomTextArea,
} from "../../components/input/AllInput";
import { ButtonOutlined } from "../../components/buttons/button";
import { ContainerBox } from "../../shared/ContainerBox";
import { useLocation, useNavigate } from "react-router-dom";
import HomeContent from "../../views/home/HomeContent";
import Whatsapp from "../../assets/images/whatsapp.png";
import Atrate from "../../assets/images/atrate.png";
import Loaction from "../../assets/images/locationgray.png";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";

export const Contact = ({ SetSelectProfile, selectProfile }) => {
  const navigate = useNavigate();
  const Location = useLocation();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [verifiedData, setVerifiedData] = useState([]);
  const { allPostData, form, handleChangeData, code, setCode, handleSubmit } =
    HomeContent();

  useEffect(() => {
    let array = [];
    allPostData?.length > 0 &&
      allPostData?.map((item) => {
        if (item.isVerified === "VERIFIED") {
          array.push(item);
        }
      });
    setVerifiedData(array);
  }, [allPostData]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [Location]);
  return (
    <>
      <div className=" pt-8  bg-light">
        <ContainerBox>
          <div className="flex flex-wrap mr-2 ml-2 p-0 m-0  pb-7 ">
            <div className="col-12 md:col-6  bg-light pt-8  ">
              <div className=" p-4 border-round-3xl bg-white">
                <h1 className=" font-semibold mt-0 ">Contact Us</h1>

                <div className="flex align-items-center ml-2 mb-7 ">
                  <span className="text-black">
                    8 am to 7 pm AEST ( Monday - Friday){" "}
                  </span>
                </div>
                <div className="flex align-items-center ">
                  <span className="p-0 m-0 flex justify-content-center   align-items-center border-round-xl pr-3">
                    <img
                      src={Whatsapp}
                      height={28}
                      width={28}
                      alt=""
                      className=""
                    ></img>
                  </span>
                  <span className="text-black">0483 91 38 38</span>
                </div>
                <div className="flex align-items-center">
                  <span className=" p-0 m-0 flex justify-content-center align-items-center border-round-xl pr-3 py-3">
                    <img
                      src={Atrate}
                      height={28}
                      width={28}
                      alt=""
                      className=""
                    ></img>
                  </span>
                  <span className="text-black underline">
                    info@thebuyersplace.com.au
                  </span>
                </div>
                <div className="flex align-items-center">
                  <span className=" p-0 m-0 flex justify-content-center  align-items-center border-round-xl pr-3">
                    <img
                      src={Loaction}
                      height={28}
                      width={28}
                      alt=""
                      className=""
                    ></img>
                  </span>
                  <span className="text-black">
                    135-153 New South Head Road, Edgecliff 2027
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 pt-8     ">
              <div className="bg-white border-round-3xl p-4">
                <h1 className="font-semibold mt-0">Send us a Message</h1>
                <h3 className="font-medium text-black text-lg">
                  Let us know how we can help you .
                </h3>
                <div className="grid mt-5">
                  <CustomInput
                    label={"Full Name"}
                    placeholder="Full Name"
                    col="12"
                    extraClassName="input_text"
                    name="fullName"
                    value={form?.fullName}
                    onChange={handleChangeData}
                    errorMessage={form?.formErrors?.fullName}
                  />
                  <CustomInput
                    label={"Email"}
                    placeholder="Email "
                    type="email"
                    col="12"
                    name="email"
                    value={form?.email}
                    onChange={handleChangeData}
                    errorMessage={form?.formErrors?.email}
                  />
                  <CustomPhoneInput
                    label={"Phone"}
                    col="12"
                    placeholder="Number"
                    setCode={setCode}
                    code={code}
                    name="mobile"
                    onChange={handleChangeData}
                    value={form?.mobile}
                    errorMessage={form?.formErrors?.mobile}
                  />
                  <CustomTextArea
                    label={"Your message"}
                    col="12"
                    className="input_textarea w-full bg-transparent border-noround p-3 "
                    placeholder="Your Message (optional)"
                    name="message"
                    value={form?.message}
                    onChange={handleChangeData}
                    errorMessage={form?.formErrors?.message}
                  />
                  <p>
                    We take your privacy seriously. See our Privacy Policy,
                    Collection Statement and Third Party Disclaimer{" "}
                    <span
                      onClick={() => navigate("/privacy-policy")}
                      className="font-medium underline cursor-pointer "
                      style={{ color: "rgba(0, 33, 166, 1)" }}
                    >
                      here
                    </span>
                  </p>
                  <ButtonOutlined
                    col="12"
                    label="SUBMIT "
                    className="font-semibold mr-1 ml-1 mt-3  "
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
