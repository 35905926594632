import { types } from "../types";

const initialState = {
    myProfile: {},
    updateProfile: {},
    changePasswordProfile: {},
    contactProfile: {},
    termConditionProfile: {},
    PrivacyProfile: []
}
export const myProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MY_PROFILE:
            return {
                ...state,
                myProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const updateProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_PROFILE:
            return {
                ...state,
                updateProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const createContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_CONTACT:
            return {
                ...state,
                contactProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const termConditionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TERM_CONDITION:
            return {
                ...state,
                termConditionProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const privacyPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PRIVACY_POLICY:
            return {
                ...state,
                PrivacyProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}