import React, { useEffect, useState } from "react";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import Siderbar from "../../components/sidebar/Siderbar";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { CustomPassword } from "../../components/input/AllInput";
import { Link, useNavigate } from "react-router-dom";
import { NewPassword } from "./NewPassword";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../home/PickProfile";
import ProfileImage from "../../assets/images/briefProfile.png";
import CheckIcon from "../../assets/icons/Checkmark.png";

import HomeContent from "../home/HomeContent";
import { isAuthenticated } from "../../services/authService";
import { Card, Checkbox } from "antd";

const CreateBrief = ({ SetSelectProfile, selectProfile }) => {
  const { chooseprofile } = HomeContent();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const handleClick = (item) => {
    if (checked === true) {
      navigate(item.link);
      SetSelectProfile(false);
      gotoBtn();
    } else {
      setCheckBox(true);
    }
  };
  useEffect(() => {
    if (checked === true) {
      setCheckBox(false);
    }
  }, [checked]);
  return (
    <>
      <>
        <div className="user-profile-container pt-8 mt-4">
          <ContainerBox>
            <div className="grid sidebar-container lg:py-6 md:py-6 p-0 m-0  flex justify-content-between ">
              <div className="col-12 md:col-4 lg:col-3 sidebar border-round-lg">
                <Siderbar />
              </div>
              <div className="col-12 md:col-8 lg:col-9 ">
                <div className=" bg-green-light lg:ml-5 md:ml-3 ">
                  <h1
                    className="heading_text text-dark fon-semibold  mb-5"
                    style={{ fontSize: "32px" }}
                  >
                    Pick your Profile to Create a Brief
                  </h1>
                  <div className="bg-lightest-gray text-center">
                    <img src={ProfileImage} alt="" className="w-full" />
                  </div>
                  <ul className="p-0 mb-4 " style={{ fontFamily: "Raleway" }}>
                    <li
                      className="text-lg font-medium flex align-items-center mb-4 text-black Raleway"
                      style={{ fontFamily: "Raleway" }}
                    >
                      <img src={CheckIcon} alt="" width={20} />
                      <span className="font-medium ml-2 text-dark  text-lg">
                        Step 1.
                      </span>{" "}
                      &nbsp; Complete your profile details
                    </li>
                    <li className="text-lg font-medium  flex align-items-center mb-4 text-black ">
                      <img src={CheckIcon} alt="" width={20} />
                      <span className="font-medium ml-2 text-dark  text-lg">
                        Step 2.
                      </span>{" "}
                      &nbsp; Get verified
                    </li>
                    <li className="text-lg font-medium  flex align-items-center mb-4 text-black ">
                      <img src={CheckIcon} alt="" width={20} />
                      <span className="font-medium ml-2 text-dark  text-lg">
                        Step 3.
                      </span>{" "}
                      &nbsp; Your profile goes live
                    </li>
                    <li className="text-lg  font-medium  flex align-items-center mb-5 text-black ">
                      <img src={CheckIcon} alt="" width={20} />
                      <span className="font-medium ml-2 text-dark  text-lg">
                        Step 4.
                      </span>{" "}
                      &nbsp; Find the perfect place
                    </li>
                  </ul>
                  <div className="flex md:align-items-center">
                    <Checkbox
                      onChange={(e) => setChecked(e?.target?.checked)}
                      checked={checked}
                    ></Checkbox>
                    <span className="mb-3 text-black font-medium text-base md:mt-3 ml-2">
                      By proceeding, you agree to our{" "}
                      <cod
                        className="text-main underline text-sm font-semibold cursor-pointer"
                        onClick={() => {
                          navigate("/privacy-policy");
                          SetSelectProfile(false);
                          gotoBtn();
                        }}
                      >
                        &nbsp;Privacy Policy
                      </cod>
                    </span>
                  </div>
                  {checkbox === true ? (
                    <small className="p-error text-sm ml-2">
                      Please Accept Privacy Policy
                    </small>
                  ) : (
                    ""
                  )}
                  <div className=" pic-profile-modal grid mb-3">
                    {/* onClick={() => { SetSelectProfile(false); gotoBtn() }} */}
                    {chooseprofile?.map((item, index) => {
                      return (
                        <div className="lg:col-4 col-12 select-profile-buyer h-full">
                          <p
                            className="no-underline pic-profile text-black"
                            onClick={() => handleClick(item)}
                          >
                            <Card
                              className=" text-center shadow-2 justify-content-center p-3 profile-select cursor-pointer h-full p-0 m-0 "
                              style={{ borderRadius: "10px" }}
                            >
                              <img
                                src={item.img}
                                alt=""
                                width={48}
                                height={48}
                                className="text-center"
                              />
                              <p className="lg:p-2 md:p-2 p-0 m-0 text-lg font-medium  ">
                                {item.heading}
                              </p>
                            </Card>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {/* 
     <ButtonOutlined label="CONTINUE " onClick={handleSubmit} /> */}
                </div>
              </div>
            </div>
            {/* <main>{Children}</main> */}
          </ContainerBox>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {!isAuthenticated() ? (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                ) : (
                  ""
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default CreateBrief;
