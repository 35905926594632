import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import profileImg from "../../assets/icons/profile-image.png";
import { Dialog } from "primereact/dialog";
import LogoutIcon from "../../assets/icons/logout.png";
import ButtonComponent, { ButtonOutlined } from "../buttons/button";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { myProfileAction } from "../../redux/actions/profileAction";
import { useDispatch, useSelector } from "react-redux";
import EmployeeIcon from "../../assets/images/employeeImage.jpg";
import Constants from "../../services/constant";
const Siderbar = () => {
  const [visible, setVisible] = useState(false);
  const [sidebar, setSiderbar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("realState");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    navigate("/");
  };
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const NavigateToCreate = () => navigate(`/create-brief`);

  const NavigateToAllPost = () => navigate(`/all-post`);

  const menuItem = [
    {
      path: "/profile",
      name: "Profile",
      icon: <i className="pi pi-angle-right"></i>,
    },
    {
      path: "/change-password",
      name: "Change Password",
      icon: <i className="pi pi-angle-right"></i>,
    },
    {
      path: "/my-properties",
      name: "My Saved Properties ",
      icon: <i className="pi pi-angle-right"></i>,
    },
    {
      path: "/post",
      name: " My Briefs ",
      icon: <i className="pi pi-angle-right"></i>,
    },
    {
      path: "/my-subscription",
      name: "My Subscription ",
      icon: <i className="pi pi-angle-right"></i>,
    },
  ];

  useEffect(() => {
    dispatch(myProfileAction());
  }, []);
  const getUserData = useSelector((state) => state.myProfileData.myProfile);
  return (
    <>
      {/* for mobile screen */}
      <Sidebar
        visible={sidebar}
        onHide={() => setSiderbar(false)}
        className="block lg:hidden md:hidden"
      >
        <div className=" sidebar-container  p-0 m-0 ">
          <div className=" sidebar lg:shadow-2 md:shadow-2 border-round-lg">
            <div className="top_section p-4">
              <img
                src={
                  getUserData?.image
                    ? Constants.BASE_URL + `${getUserData?.image}`
                    : EmployeeIcon
                }
                alt=""
                width={120}
                height={120}
                className="ml-2 mb-2"
                style={{ borderRadius: "60px" }}
              ></img>
              <p className="text-dark font-medium text-xl ml-2 mb-3 p-0 m-0">{`${getUserData?.firstName} ${getUserData?.lastName}`}</p>
              <div className="view-all-brief-btn ">
                <ButtonComponent
                  label="VIEW ALL BRIEFS"
                  className="mb-4 mt-2  bg-main "
                  onClick={NavigateToAllPost}
                ></ButtonComponent>
                <ButtonOutlined
                  label="CREATE A BRIEF"
                  className="mb-5  bg-white text-main  "
                  onClick={NavigateToCreate}
                ></ButtonOutlined>
              </div>

              {menuItem.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link no-underline"
                >
                  {/* <div className='icon'>{item.icon}</div> */}
                  <div className="link_text  ">
                    <p className="border-round-lg  p-0 m-0 flex align-items-center justify-content-between  text-black px-3 font-medium text-base">
                      {item.name}
                      <span>
                        <p className="">{item.icon}</p>
                      </span>
                    </p>
                  </div>
                </NavLink>
              ))}
              <hr className=""></hr>
              <p
                className="text-black px-3 text-base font-medium  cursor-pointer"
                onClick={() => setVisible(true)}
              >
                Sign Out
              </p>
            </div>
          </div>
        </div>
      </Sidebar>
      {/* <img src={profileToogleImg} width={48} height={48} alt="" className='' onClick={() => setSidebar(true)}/> */}
      <i
        className="pi pi-arrow-right profile-arrow-button lg:hidden md:hidden  border-1 border-300 p-2 text-main border-round-md"
        style={{ fontSize: "1.3rem" }}
        onClick={() => setSiderbar(true)}
      ></i>

      {/* for laptop,tablet,desktop screen */}
      <div className=" sidebar-container  p-0 m-0 hidden lg:block md:block">
        <div className=" sidebar shadow-2 border-round-lg">
          <div className="top_section p-4">
            <img
              src={
                getUserData?.image
                  ? Constants.BASE_URL + `${getUserData?.image}`
                  : EmployeeIcon
              }
              alt=""
              width={120}
              height={120}
              className="ml-2 mb-2"
              style={{ borderRadius: "60px" }}
            ></img>
            <p className="text-dark font-medium text-xl ml-3 mb-3 p-0 m-0">{`${getUserData?.firstName} ${getUserData?.lastName}`}</p>
            <div className="view-all-brief-btn ">
              <ButtonComponent
                label="VIEW ALL BRIEFS"
                className="mb-4 mt-2  bg-main "
                onClick={NavigateToAllPost}
              ></ButtonComponent>
              <ButtonOutlined
                label="CREATE A BRIEF"
                className="mb-5  bg-white text-main  "
                onClick={NavigateToCreate}
              ></ButtonOutlined>
            </div>
            {menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link no-underline"
                activeclassName="active-link bg-main"
              >
                {/* <div className='icon'>{item.icon}</div> */}
                <div className="link_text  ">
                  <p className="border-round-lg  p-0 m-0 flex align-items-center justify-content-between  text-black px-3 font-medium text-base">
                    {item.name}
                    <span>
                      <p className="">{item.icon}</p>
                    </span>
                  </p>
                </div>
              </NavLink>
            ))}
            <hr className=""></hr>
            <p
              className="text-black px-3 text-base font-medium mb-5 cursor-pointer"
              onClick={() => setVisible(true)}
            >
              Sign Out
            </p>
          </div>
        </div>
      </div>

      {/* log out dailog */}
      <div className="sign-out-modal">
        <Dialog
          header={
            <>
              <img
                src={
                  getUserData?.image
                    ? Constants.BASE_URL + `${getUserData?.image}`
                    : EmployeeIcon
                }
                width={40}
                height={40}
                className=""
                style={{ borderRadius: "60px" }}
              ></img>
              <p className="font-medium text-lg text-dark ml-2">{`${getUserData?.firstName} ${getUserData?.lastName}`}</p>
            </>
          }
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="sign-out-modal lg:w-4 md:w-10 w-11"
        >
          <div className="m-0">
            <div className="text-center">
              <img src={LogoutIcon} alt="" className="mt-2" />
              <h1 className="text-dark font-semibold text-4xl ">Sign out</h1>
              <p className="font-medium text-black text-lg mb-5">
                Are you sure you want to Sign out?
              </p>
              <div className="button flex justify-content-between lg:justify-content-around md:justify-content-around">
                <span className="w-full">
                  <ButtonOutlined
                    label="NO, CANCEL"
                    onClick={() => setVisible(false)}
                    className="text-red-500 border-red-500"
                  ></ButtonOutlined>
                </span>
                <span className="w-full ml-3">
                  <ButtonComponent
                    label="YES, LOG ME OUT"
                    onClick={() => {
                      handleLogout();
                      gotoBtn();
                    }}
                  ></ButtonComponent>
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};
export default Siderbar;
