import React from "react";
import CheckMarkIcon from "../../assets/icons/checkmark-circle.png";
import { Index } from "../../layout";
import { ContainerBox } from "../../shared/ContainerBox";
import CustomDialog from "../../components/dialog/CustomDialog";
import { PickProfile } from "../../views/home/PickProfile";
import { useNavigate } from "react-router-dom";
export default function ResetPassword({ SetSelectProfile, selectProfile }) {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  return (
    <>
      <>
        <div className="reset-container">
          <div className="bg-light-gray py-6 mt-8">
            <ContainerBox>
              <div
                className="grid p-0 m-0 flex justify-content-center p-5 ml-2 mr-2 border-1"
                style={{
                  borderColor: "rgba(204, 209, 209, 1)",
                  borderRadius: "20px",
                  backgroundColor: "rgba(250, 255, 255, 1)",
                }}
              >
                <div className="col-12 md:col-12 text-center mb-5 ">
                  <img src={CheckMarkIcon} alt="" height={64} width={64} />
                  <h1 className="text-dark font-semibold text-4xl">
                    Reset password email sent
                  </h1>
                  <p className="text-lg font-medium text-black">
                    You should soon receive an email allowing you to reset your
                    password. Please make <br></br> sure to check your spam and
                    trash if you can’t find the email.
                  </p>
                </div>
              </div>
            </ContainerBox>
          </div>
        </div>
      </>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
