import { specialCharacters } from "./regex";

export const length = (obj) => obj?.length;

export const equal = (obj1, obj2 = 0) => obj1 === obj2;

export const values = (obj) => Object.values(obj);

export const keys = (obj) => Object.keys(obj);

export const entries = (obj) => Object.entries(obj);

export const notEqual = (obj1, obj2) => !equal(obj1, obj2);

export const customPasswordCheck = (value) => {
  const tempArr = [];
  const hasSpecialChar = specialCharacters(value);
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const checkLength = value.length >= 8;
  const hasNumber = /[0-9]/.test(value);

  if (
    !hasSpecialChar ||
    !hasUpperCase ||
    !hasLowerCase ||
    !hasNumber ||
    !checkLength
  ) {
    tempArr.push(
      "Password must has at least 8 characters that include at least 1 lowercase character , 1 uppercase characters , 1 number , and 1 special character in (!@#$%^&*)"
    );
  }

  return tempArr.join(", ");
};
