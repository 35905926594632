import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (window.localStorage.getItem("realState")) {
        return JSON.parse(window.localStorage.getItem("realState"));
    } else {
        return false;
    }
};
export const authenticate = (token, next) => {
    if (typeof window !== "undefined") {
        window.localStorage.setItem("realState", JSON.stringify(token));
        next();
    }
};
export const logout = (next) => {
    if (typeof window !== "undefined") {
        window.localStorage.clear();
        next();
    }
};