import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { allValidations } from "../../../utils/formValidation";
import { buyerGeneralInfo } from "../../../redux/actions/buyerAction";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { showToast } from "../../../redux/actions/toastAction";
import {
  viewPostAction,
  editPostAction,
} from "../../../redux/actions/postAction";
import { useParams, useLocation } from "react-router-dom";
import { myProfileAction } from "../../../redux/actions/profileAction";
import { emailCheckAction } from "../../../redux/actions/signupAction";
import _debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { emailValidation } from "../../../utils/regex";
import { customPasswordCheck } from "../../../utils/javascript";
import { isAuthenticated } from "../../../services/authService";
export const BuyerIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [address, setAddress] = useState("");
  const [code, setCode] = useState("+61");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState(null);
  const [check, setChecked] = useState([]);
  const [minRange, setMinRange] = useState(null);
  const [maxRange, setMaxRange] = useState(null);
  const [maxLand, setMaxLand] = useState(null);
  const [minProperty, setMinProperty] = useState("");
  const [selectAddress, setSelectAddress] = useState(false);
  const [selectSubhurb, setSelectSuburb] = useState(false);
  const [maxProperty, setMaxProperty] = useState("");
  const [minLand, setMinLand] = useState(null);
  const [highlight, setHightlight] = useState([]);
  const [bathRoomHightlight, setBathRoomHightlight] = useState([]);
  const [carHightlight, setCarHightlight] = useState([]);
  const [emailCheck, setEmailCheck] = useState();
  const [inputList, setInputList] = useState([
    {
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      location: { lat: "", long: "" },
    },
  ]);
  const { id } = useParams();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    suburb: "",
    zipCode: "",
    state: "",
    city: "",
    typeOfPurchase: "",
    establishedProperty: "",
    propertyType: [],
    priceRange: {},
    priceData: {},
    bedrooms: "",
    bathrooms: "",
    carSpaces: "",
    purchaseTimeFrame: "",
    landSize: {},
    address: [
      {
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        location: { lat: "", long: "" },
      },
    ],
    purchaseMethod: "",
    outdoorFeatures: [],
    indoorFeatures: [],
    climateControlEnergy: [],
    note: "",
    postType: "BUYER",
    financeDetails: { isArranged: "", depositAmount: "", preApproved: "" },
    propertyMustHave: {},
    password: "",
    confirmPassword: "",
  });

  const token = localStorage?.getItem("realState");
  const handleDebounceFn = (payload) => {
    dispatch(emailCheckAction(payload)).then((res) => {
      if (res.success === true) {
        setEmailCheck(true);
      } else if (res.success === false) {
        setEmailCheck(false);
      }
    });
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const handleChange = (e) => {
    let array = [];
    const { name, value } = e?.target;
    let formErrors = {};
    if (name === "minLand") {
      formErrors = allValidations("landSize", value, form);
    } else if (name === "minProperty" || name === "maxProperty") {
      formErrors = allValidations("propertyMustHave", value, form);
    } else {
      formErrors = allValidations(name, value, form);
    }
    if (name === "email") {
      setForm({
        ...form,
        email: value,
        formErrors,
      });
      let payload = { email: value };
      debounceFn(payload);
    } else if (name === "minProperty" || name === "maxProperty") {
      if (name === "minProperty") {
        setForm((prev) => {
          return {
            ...prev,
            propertyMustHave: {
              ...prev.propertyMustHave,
              min: value,
            },
            formErrors,
          };
        });
        setMinProperty(value);
      } else {
        setForm((prev) => {
          return {
            ...prev,
            propertyMustHave: {
              ...prev.propertyMustHave,
              max: value,
            },
            formErrors,
          };
        });
        setMaxProperty(value);
      }
    } else if (name === "minLand" || name === "maxLand") {
      if (name === "minLand") {
        setForm((prev) => {
          return {
            ...prev,
            landSize: {
              ...prev.landSize,
              min: value,
            },
            formErrors,
          };
        });
        setMinLand(value);
      } else {
        setForm((prev) => {
          return {
            ...prev,
            landSize: {
              ...prev.landSize,
              max: value,
            },
          };
        });
        setMaxLand(value);
      }
    } else if (name === "depositAmount") {
      setForm((prev) => {
        return {
          ...prev,
          financeDetails: {
            ...prev.financeDetails,
            depositAmount: value,
          },
          formErrors,
        };
      });
    } else if (name === "preApproved") {
      setForm((prev) => {
        return {
          ...prev,
          financeDetails: {
            ...prev.financeDetails,
            preApproved: value,
          },
          formErrors,
        };
      });
    } else if (name === "financeDetails") {
      if (value === true) {
        setForm((prev) => {
          return {
            ...prev,
            financeDetails: {
              ...prev.financeDetails,
              isArranged: value,
              depositAmount: "",
              preApproved: "",
            },
            formErrors,
          };
        });
      } else {
        setForm((prev) => {
          return {
            ...prev,
            financeDetails: {
              ...prev.financeDetails,
              isArranged: value,
              depositAmount: "",
              preApproved: "",
            },
            formErrors,
          };
        });
      }
    } else if (name === "propertyType") {
      array.push(value);
      setForm({
        ...form,
        propertyType: array,
        formErrors,
      });
    } else {
      setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  useEffect(() => {
    dispatch(buyerGeneralInfo());
  }, []);

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    const formErrors = allValidations("priceRange", value, form);
    if (name === "min") {
      setForm((prev) => {
        return {
          ...prev,
          priceRange: {
            ...prev.priceRange,
            min: value,
          },
          formErrors,
        };
      });
      setForm((prev) => {
        return {
          ...prev,
          priceData: {
            ...prev.priceData,
            min: value < 1000000 ? value / 1000 + "K" : value / 1000000 + "M",
          },
        };
      });
    } else {
      setForm((prev) => {
        return {
          ...prev,
          priceRange: {
            ...prev.priceRange,
            max: value,
          },
        };
      });
      setForm((prev) => {
        return {
          ...prev,
          priceData: {
            ...prev.priceData,
            max: value < 1000000 ? value / 1000 + "K" : value / 1000000 + "M",
          },
        };
      });
    }
  };
  const handleRoomChange = (name, value) => {
    const formErrors = allValidations(name, value, form);
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
        formErrors,
      };
    });
    if (name === "bedrooms") {
      setHightlight(value);
    } else if (name === "bathrooms") {
      setBathRoomHightlight(value);
    } else {
      setCarHightlight(value);
    }
  };
  const PropertyList = useSelector(
    (state) => state.buyergeneralInfo.generaInfo
  );
  const timeFrameDropdown = [
    { value: "Ready Now", label: "Ready Now" },
    { value: "1-3 months", label: "1-3 months" },
    { value: "3-6 months", label: "3-6 months" },
    { value: "6-12 months", label: "6-12 months" },
    { value: "12 months plus", label: "12 months plus" },
  ];
  const minLandSize = [
    "200 ㎡",
    "300 ㎡",
    "400 ㎡",
    "500 ㎡",
    "600 ㎡",
    "700 ㎡",
    "800 ㎡",
    "900 ㎡",
    "1,000 ㎡",
    "1,200 ㎡",
    "1,500 ㎡",
    "1,750 ㎡",
    "2,000 ㎡",
    "3,000 ㎡",
    "4,000 ㎡",
    "5,000 ㎡",
    "1 ha",
    "2 ha",
    "3 ha",
    "4 ha",
    "5 ha",
    "10 ha",
    "20 ha",
    "30 ha",
    "40 ha",
    "50 ha",
    "60 ha",
    "70 ha",
    "80 ha",
    "90 ha",
    "100 ha",
    "150 ha",
    "200 ha",
    "300 ha",
    "500 ha",
    "1000 ha",
    "3000 ha",
    "5000 ha",
    "10000 ha",
  ];
  const handleClear = () => {
    window.scrollTo({ top: 0, left: 0 });
    window.location.reload();
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobile: "",
      streetAddress: "",
      suburb: "",
      zipCode: "",
      propertyMustHave: {},
      state: "",
      city: "",
      typeOfPurchase: "",
      establishedProperty: "",
      propertyType: [],
      priceRange: {},
      bedrooms: "",
      bathrooms: "",
      carSpaces: "",
      purchaseTimeFrame: "",
      landSize: {},
      address: [
        {
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          location: { lat: "", long: "" },
        },
      ],
      purchaseMethod: "",
      outdoorFeatures: [],
      indoorFeatures: [],
      climateControlEnergy: [],
      note: "",
      postType: "BUYER",
      financeDetails: { isArranged: "", depositAmount: "", preApproved: "" },
    });
  };
  const formCheck = () => {
    const valid = customPasswordCheck(form?.password);
    let formErrors = {};
    // let data = true
    form?.address?.map((item) => {
      if (item?.streetAddress?.length === 0) {
        // data = false
        formErrors.address = "Please Enter Address";
      }
      if (selectSubhurb === false) {
        formErrors.address = "Please Enter Address";
      }
    });
    if (form.firstName === "" || form.firstName === undefined) {
      formErrors.firstName = "Please Enter First Name";
    }
    if (form.lastName === "" || form.lastName === undefined) {
      formErrors.lastName = "Please Enter Last Name";
    }
    if (form.email === "" || form.email === undefined) {
      formErrors.email = "Please Enter Email";
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
    }
    if (form.mobile === "" || form.mobile === undefined) {
      formErrors.mobile = "Please Enter Mobile Number";
    } else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only";
    }
    if (emailCheck === false && form?.password === "") {
      formErrors.password = "Please Enter Password";
    } else if (emailCheck === false && valid) {
      formErrors.password = valid;
    }
    if (emailCheck === false && form?.confirmPassword === "") {
      formErrors.confirmPassword = "Please Enter ConfirmPassword";
    } else if (
      emailCheck === false &&
      form?.password !== form?.confirmPassword
    ) {
      formErrors.confirmPassword = "Password and Confirm Password are not same";
    }
    if (form.suburb === "") {
      formErrors.suburb = "Please Enter Full Address";
    }
    if (selectAddress === false) {
      formErrors.suburb = "Please Enter Full Address";
    }
    if (form.typeOfPurchase === "") {
      formErrors.typeOfPurchase = "Please Select";
    }
    if (form.establishedProperty === "") {
      formErrors.establishedProperty = "Please Select";
    }
    if (form.propertyType?.length === 0) {
      formErrors.propertyType = "Please Select";
    }
    if (Object.keys(form?.priceRange).length === 0) {
      formErrors.priceRange = "Please Select";
    }
    if (form?.bedrooms === "") {
      formErrors.bedrooms = "Please Select";
    }
    if (form?.bathrooms === "") {
      formErrors.bathrooms = "Please Select";
    }
    if (form?.carSpaces === "") {
      formErrors.carSpaces = "Please Select";
    }
    if (Object.keys(form?.purchaseTimeFrame).length === 0) {
      formErrors.purchaseTimeFrame = "Please Select";
    }
    if (Object.keys(form?.landSize).length === 0) {
      formErrors.landSize = "Please Select";
    }
    if (Object.keys(form?.propertyMustHave).length === 0) {
      formErrors.propertyMustHave = "Please Enter";
    }
    if (form?.purchaseMethod === "") {
      formErrors.purchaseMethod = "Please Select";
    }
    if (
      form?.purchaseMethod === "FINANCE" &&
      form?.financeDetails?.isArranged === ""
    ) {
      formErrors.financeDetails = "Please Select";
    }
    if (
      (form?.purchaseMethod === "FINANCE" &&
        form?.financeDetails?.isArranged === true &&
        form?.financeDetails?.depositAmount === "") ||
      form?.financeDetails?.depositAmount === null
    ) {
      formErrors.depositAmount = "Please Enter Amount";
    }
    if (
      (form?.purchaseMethod === "FINANCE" &&
        form?.financeDetails?.isArranged === false &&
        form?.financeDetails?.depositAmount === "") ||
      form?.financeDetails?.depositAmount === null
    ) {
      formErrors.depositAmount = "Please Enter";
    }

    if (emailCheck === false && isAuthenticated()) {
      delete formErrors.confirmPassword;
      delete formErrors.password;
    }

    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (!formCheck()) {
      dispatch(
        showToast({ severity: "error", summary: "Required all fields" })
      );
      return false;
    } else if (token === null && form?.password === "") {
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else if (token === null && emailCheck === true) {
      dispatch(showToast({ severity: "error", summary: "Please Login" }));
    } else if (form?.password !== form?.confirmPassword) {
      dispatch(
        showToast({
          severity: "error",
          summary: "Password & ConfirmPassword can't match",
        })
      );
    } else {
      form.address = [...inputList];
      setForm({ ...form });
      setPage(2);
    }
  };
  useEffect(() => {
    if (code) {
      setForm({
        ...form,
        countryCode: code,
      });
    } else {
      setForm({
        ...form,
        countryCode: "+61",
      });
    }
  }, [code, form.mobile]);
  useEffect(() => {
    setCode(form?.countryCode);
  }, [form]);
  // useEffect(() => {
  //   if (dates) {
  //     const formErrors = allValidations("purchaseTimeFrame", dates, form);
  //     setForm({
  //       ...form,
  //       purchaseTimeFrame: {
  //         startDate: dates[0]?.toISOString(),
  //         endDate: dates[1]?.toISOString(),
  //       },
  //       formErrors,
  //     });
  //   }
  // }, [dates]);
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        location: { lat: "", long: "" },
      },
    ]);
  };
  const handleChangeAddress = (newAddress, index) => {
    setShow(true);
    setSelectSuburb(false);
    const list = [...inputList];
    list[index].streetAddress = newAddress;
    list[index].state = newAddress;
    setInputList([...list]);
  };
  const handleSelectAddress = (newAddress, index) => {
    setSelectSuburb(true);
    const list = [...inputList];
    let obj = {
      ...list[index],
      streetAddress: newAddress,
      state: "",
      city: "",
      zipCode: "",
      location: {
        lat: "",
        long: "",
      },
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.zipCode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),
          (obj.location.lat = results[0]?.geometry?.location.lng()),
          (obj.location.long = results[0]?.geometry?.location.lat()),
          (obj = {
            ...list[index],
            streetAddress:
              newAddress?.includes(obj?.zipCode) ||
              newAddress?.includes(obj.state)
                ? newAddress
                : newAddress + " " + obj?.zipCode + ", " + obj?.state,
            state: obj.state,
            city: obj.city,
            zipCode: obj.zipCode,
            location: {
              lat: obj.location.lat,
              long: obj.location.long,
            },
          }),
          list.splice(index, 1, obj),
          setInputList(list)
        )
      )

      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {
    const formErrors = allValidations("address", inputList, form);
    setForm({
      ...form,
      address: inputList,
      formErrors,
    });
  }, [inputList]);
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    setInputList(remove);
  };
  const handleChangeSuburb = (newAddress) => {
    setSelectAddress(false);
    setShow(true);
    // setAddress(newAddress);
    setForm({
      ...form,
      suburb: newAddress,
    });
  };
  const handleSelectAddressSuburb = (newAddress) => {
    setSelectAddress(true);
    const formErrors = allValidations("suburb", newAddress, form);
    // setAddress(newAddress);
    let obj = {
      state: "",
      city: "",
      zipcode: "",
      suburb: "",
    };
    geocodeByAddress(newAddress)
      .then((results) =>
        getLatLng(
          results[0]?.address_components?.length > 0 &&
            results[0]?.address_components?.map((item) => {
              if (item?.types?.includes("administrative_area_level_1")) {
                obj.state = item.long_name;
              } else if (item?.types?.includes("postal_code")) {
                obj.suburb = results[0]?.formatted_address;
                obj.zipcode = item.long_name;
              } else if (item?.types?.includes("locality")) {
                obj.city = item.long_name;
              }
            }),

          setForm({
            ...form,
            state: obj.state,
            zipCode: obj.zipcode,
            suburb: obj.suburb,
            city: obj.city,
            formErrors,
          })
        )
      )
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (token) {
      dispatch(myProfileAction());
    }
  }, [token]);
  const myProfileData = useSelector((state) => state.myProfileData.myProfile);
  useEffect(() => {
    if (myProfileData && id === undefined) {
      setForm({
        ...form,
        firstName: myProfileData?.firstName,
        lastName: myProfileData?.lastName,
        email: myProfileData?.email,
        countryCode: myProfileData?.countryCode,
        mobile: myProfileData?.mobile,
        // image: myProfileData?.image,
        suburb: myProfileData?.suburb,
        streetAddress: myProfileData?.streetAddress,
        zipCode: myProfileData?.zipCode,
        state: myProfileData?.state,
      });
      setCode(myProfileData?.countryCode);
      setSelectAddress(true);
    }
  }, [myProfileData, id]);
  useEffect(() => {
    if (id) {
      dispatch(viewPostAction(id)).then((res) => {
        if (res.success) {
          setSelectSuburb(true);
        }
      });
    }
  }, [id]);
  const viewPostData = useSelector((state) => state.viewPost.viewPostProfile);
  useEffect(() => {
    if (Object.values(viewPostData)?.length > 0) {
      setForm({
        ...form,
        firstName: viewPostData?.firstName,
        lastName: viewPostData?.lastName,
        email: viewPostData?.email,
        countryCode: viewPostData?.countryCode,
        mobile: viewPostData?.mobile,
        streetAddress: viewPostData?.streetAddress,
        suburb: viewPostData?.suburb,
        state: viewPostData?.state,
        zipCode: viewPostData?.zipCode,
        typeOfPurchase: viewPostData?.typeOfPurchase,
        establishedProperty: viewPostData?.establishedProperty,
        propertyType: viewPostData?.propertyType,
        priceRange: viewPostData?.priceRange,
        priceData: viewPostData?.priceRange,
        bedrooms: viewPostData?.bedrooms,
        bathrooms: viewPostData?.bathrooms,
        carSpaces: viewPostData?.carSpaces,
        purchaseTimeFrame: viewPostData?.purchaseTimeFrame,
        landSize: viewPostData?.landSize,
        address: viewPostData?.address,
        purchaseMethod: viewPostData?.purchaseMethod,
        financeDetails: viewPostData?.financeDetails,
        outdoorFeatures: viewPostData?.outdoorFeatures,
        indoorFeatures: viewPostData?.indoorFeatures,
        climateControlEnergy: viewPostData?.climateControlEnergy,
        note: viewPostData?.note,
        propertyMustHave: viewPostData?.propertyMustHave,
      });
      setInputList(viewPostData?.address);
      setHightlight(
        viewPostData.bedrooms === null
          ? "any"
          : `${viewPostData?.bedrooms?.toString()}+`
      );
      setBathRoomHightlight(
        viewPostData?.bathrooms === null
          ? "any"
          : `${viewPostData?.bathrooms?.toString()}+`
      );
      setCarHightlight(
        viewPostData?.carSpaces === null
          ? "any"
          : `${viewPostData?.carSpaces?.toString()}+`
      );
      setMinLand(viewPostData?.landSize?.min);
      setMaxLand(viewPostData?.landSize?.max);
      setMinProperty(viewPostData?.propertyMustHave?.min);
      setMaxProperty(viewPostData?.propertyMustHave?.max);
      setChecked(viewPostData?.propertyType);
      setCode(viewPostData?.countryCode);
      setSelectAddress(true);
    }
  }, [viewPostData]);
  useEffect(() => {
    if (!id) {
      setForm({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "+61",
        mobile: "",
        streetAddress: "",
        suburb: "",
        zipCode: "",
        state: "",
        city: "",
        typeOfPurchase: "",
        establishedProperty: "",
        propertyType: [],
        priceRange: {},
        priceData: {},
        bedrooms: "",
        bathrooms: "",
        carSpaces: "",
        purchaseTimeFrame: "",
        landSize: {},
        address: [
          {
            streetAddress: "",
            city: "",
            state: "",
            zipCode: "",
            location: { lat: "", long: "" },
          },
        ],
        purchaseMethod: "",
        outdoorFeatures: [],
        indoorFeatures: [],
        climateControlEnergy: [],
        note: "",
        postType: "BUYER",
        latlng: { lat: "", lng: "" },
        financeDetails: { isArranged: "", depositAmount: "", preApproved: "" },
        propertyMustHave: {},
        password: "",
        confirmPassword: "",
      });
      setHightlight("");
      setInputList([
        {
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          location: { lat: "", long: "" },
        },
      ]);
      setBathRoomHightlight("");
      setCarHightlight("");
      setMinLand("");
      setMaxLand("");
      setChecked([]);
      setCode("+61");
      setMinProperty("");
      setMaxProperty("");
    }
  }, [id, location]);
  const minPriceRange = [
    // { value: null, label: "Any" },
    { label: "$0", value: 0 },
    { value: 50000, label: "$50,000" },
    { value: 75000, label: "$75,000" },
    { value: 100000, label: "$100,000" },
    { value: 125000, label: "$125,000" },
    { value: 150000, label: "$150,000" },
    { value: 175000, label: "$175,000" },
    { value: 200000, label: "$200,000" },
    { value: 225000, label: "$225,000" },
    { value: 250000, label: "$250,000" },
    { value: 275000, label: "$275,000" },
    { value: 300000, label: "$300,000" },
    { value: 325000, label: "$325,000" },
    { value: 350000, label: "$350,000" },
    { value: 375000, label: "$375,000" },
    { value: 400000, label: "$400,000" },
    { value: 425000, label: "$425,000" },
    { value: 450000, label: "$450,000" },
    { value: 475000, label: "$475,000" },
    { value: 500000, label: "$500,000" },
    { value: 550000, label: "$550,000" },
    { value: 600000, label: "$600,000" },
    { value: 650000, label: "$650,000" },
    { value: 700000, label: "$700,000" },
    { value: 750000, label: "$750,000" },
    { value: 800000, label: "$800,000" },
    { value: 850000, label: "$850,000" },
    { value: 900000, label: "$900,000" },
    { value: 950000, label: "$950,000" },
    { value: 1000000, label: "$1,000,000" },
    { value: 1100000, label: "$1,100,000" },
    { value: 1200000, label: "$1,200,000" },
    { value: 1300000, label: "$1,300,000" },
    { value: 1400000, label: "$1,400,000" },
    { value: 1500000, label: "$1,500,000" },
    { value: 1600000, label: "$1,600,000" },
    { value: 1700000, label: "$1,700,000" },
    { value: 1800000, label: "$1,800,000" },
    { value: 1900000, label: "$1,900,000" },
    { value: 2000000, label: "$2,000,000" },
    { value: 2250000, label: "$2,250,000" },
    { value: 2500000, label: "$2,500,000" },
    { value: 2750000, label: "$2,750,000" },
    { value: 3000000, label: "$3,000,000" },
    { value: 3500000, label: "$3,500,000" },
    { value: 4000000, label: "$4,000,000" },
    { value: 4500000, label: "$4,500,000" },
    { value: 5000000, label: "$5,000,000" },
    { value: 6000000, label: "$6,000,000" },
    { value: 7000000, label: "$7,000,000" },
    { value: 8000000, label: "$8,000,000" },
    { value: 9000000, label: "$9,000,000" },
    { value: 10000000, label: "$10,000,000" },
    { value: 11000000, label: "$11,000,000" },
    { value: 12000000, label: "$12,000,000" },
    { value: 13000000, label: "$13,000,000" },
    { value: 14000000, label: "$14,000,000" },
    { value: 15000000, label: "$15,000,000" },
  ];
  useEffect(() => {
    if (token) {
      setEmailCheck(true);
    }
  }, [token, emailCheck]);
  const handleTypeChange = (e) => {
    console.log("e", e);
    if (e.value._id === "6502e67c4bae95323d453d17" && e.checked === true) {
      let selectType = [...PropertyList?.propertyTypes];
      const formErrors = allValidations("propertyType", selectType, form);
      setChecked(selectType);
      setForm({
        ...form,
        propertyType: selectType,
        formErrors,
      });
    } else if (
      e.value._id === "6502e67c4bae95323d453d17" &&
      e.checked === false
    ) {
      const formErrors = allValidations("propertyType", [], form);
      setChecked([]);
      setForm({
        ...form,
        propertyType: [],
        formErrors,
      });
    } else {
      let selectType = [...check];

      if (e.checked) {
        selectType.push(e.value);
      } else {
        selectType = selectType.filter((type) => type._id !== e.value._id);
      }
      setChecked(selectType);
      const formErrors = allValidations("propertyType", selectType, form);
      setForm({
        ...form,
        propertyType: selectType,
        formErrors,
      });
    }
  };
  useEffect(() => {
    if (id) {
      setChecked(viewPostData?.propertyType);
    }
  }, [id, viewPostData]);
  return {
    handleAddClick,
    handleChangeAddress,
    handleSelectAddress,
    handleRemoveClick,
    handleChangeSuburb,
    handleSelectAddressSuburb,
    form,
    setForm,
    handleChange,
    check,
    setChecked,
    maxRange,
    setMaxRange,
    minRange,
    setMinRange,
    timeFrameDropdown,
    minLandSize,
    maxLand,
    minLand,
    handleClear,
    highlight,
    setHightlight,
    handleRangeChange,
    handleRoomChange,
    bathRoomHightlight,
    carHightlight,
    dates,
    setDates,
    code,
    setCode,
    show,
    setShow,
    page,
    setPage,
    inputList,
    setInputList,
    handleSubmit,
    PropertyList,
    address,
    minProperty,
    maxProperty,
    minPriceRange,
    emailCheck,
    navigate,
    token,
    id,
    handleTypeChange,
  };
};
