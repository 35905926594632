import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import PlusIcon from "../../../assets/icons/plus.png";
import Gallery from "../../../assets/icons/gallery.png";
import {
  CustomInput,
  CustomTextArea,
} from "../../../components/input/AllInput";
import ButtonComponent from "../../../components/buttons/button";
import { Calendar } from "primereact/calendar";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  NewEstablishedProperty,
  OutdoorFeatures,
  PropertyDetail,
  PropertyType,
} from "../../profile-forms/CommonFeild";
import { SellerIndex } from "./SellerIndex";
import moment from "moment";
import { SellerConfirmDetails } from "./SellerConfirmDetails";
import SellerSuccessfullProfile from "./SellerSuccessfullProfile";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { allValidations } from "../../../utils/formValidation";
import Constants from "../../../services/constant";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { ContainerBox } from "../../../shared/ContainerBox";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
export const SellerForm = ({ SetSelectProfile, selectProfile }) => {
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [visible, setVisible] = useState(false);
  const {
    form,
    setForm,
    code,
    setCode,
    handleChange,
    show,
    id,
    handleRemoveClick,
    emailCheck,
    token,
    handleRemoveFloorPlane,
    floorPlan,
    address,
    handleChangeAddress,
    handleSelectAddress,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    handleRoomChange,
    highlight,
    bathRoomHightlight,
    carHightlight,
    handleClear,
    unitData,
    inputList,
    setInputList,
    handleChangeAddFloorPlan,
    PropertyList,
    bedRoomQuantity,
    bathroomQuantity,
    carSpaceQuantity,
    minLandSize,
    maxLandSize,
    maxLand,
    minLand,
    handleTypeChange,
    check,
    setChecked,
    page,
    setPage,
    handleSubmit,
    handleAddImage,
    handleImage,
    handleSelectAddressSuburb,
    handleChangeSuburb,
  } = SellerIndex();
  useEffect(() => {
    const formErrors = allValidations("buildYear", date, form);
    setForm({
      ...form,
      buildYear: moment(date).format("YYYY"),
      formErrors,
    });
  }, [date]);
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  return (
    <>
      {page === 1 ? (
        <>
          <div className="seller-form-container  bg-green-light pt-6">
            <div className="py-7 w-full">
              <ContainerBox>
                <div className="grid justify-content-center lg:mb-6 lg:mt-6 mt-3 mb-3 ">
                  <div className="col-12 md:col-12 lg:col-12 text-center">
                    <h1
                      className="heading_text font-semibold text-dark p-0 m-0"
                      style={{ fontSize: "56px" }}
                    >
                      Seller Brief Form
                    </h1>
                    <p className="text-lg text-black line-height-3 font-medium">
                      Complete your property details below, so we can best match
                      you property to one of our buyers.
                    </p>
                  </div>
                </div>
              </ContainerBox>
              {/* <FormTopHeading
                heading="Seller Brief Form"
                description="Complete your property details below, so we can best match you property to one of our buyers. "
              /> */}
              <ContentArea>
                <div className="grid p-0 m-0">
                  <ContactDetail
                    form={form}
                    show={show}
                    token={token}
                    setForm={setForm}
                    code={code}
                    setCode={setCode}
                    handleChange={handleChange}
                    handleSelectAddressSuburb={handleSelectAddressSuburb}
                    handleChangeSuburb={handleChangeSuburb}
                    address={address}
                    handleSelectStreetAddress={handleSelectStreetAddress}
                    handlechangeStreetAddress={handlechangeStreetAddress}
                    emailCheck={emailCheck}
                  />
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <NewEstablishedProperty
                    form={form}
                    setForm={setForm}
                    handleChange={handleChange}
                  />
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold">
                    Property details<code className="p-error text-sm">*</code>
                  </h2>
                  <div
                    className={`col-12 md:col-6 ${
                      form?.formErrors?.password?.length > 0 ||
                      form?.formErrors?.confirmPassword?.length > 0
                        ? "pointer-events-none"
                        : ""
                    }`}
                  >
                    <PlacesAutocomplete
                      value={
                        form?.address?.streetAddress ||
                        form?.address[0]?.streetAddress
                      }
                      onChange={handleChangeAddress}
                      onSelect={handleSelectAddress}
                      searchOptions={{
                        componentRestrictions: { country: ["au"] },
                        // types: ["locality"],
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                        item,
                      }) => (
                        <div className="subhurb-hightlight mt-2 w-full">
                          <CustomInput
                            label="Property Address"
                            extraClassName="w-full"
                            value={
                              form?.address?.streetAddress ||
                              form?.address[0]?.streetAddress
                            }
                            errorMessage={form?.formErrors?.address}
                            {...getInputProps({
                              placeholder: "Search Full Address or Suburb Only",
                            })}
                            required
                          />

                          {show === true ? (
                            <div className="autocomplete-dropdown-container absolute top-100  bg-white z-2 p-0 shadow-1  mb-2 ">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                                  : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span className="">
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <p className="ml-1" style={{ marginTop: "-15px" }}>
                      {" "}
                      Note- Only the Suburb will display on the Brief, to keep
                      your details private.
                    </p>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="w-full lg:mt-3 md:mt-3 p-0 m-0 ">
                      <label className="text-dark text-lg font-medium flex align-items-start font-bold ml-1">
                        Build Year<code className="p-error text-sm">*</code>
                      </label>
                      <Calendar
                        value={date || form.buildYear}
                        onChange={(e) => setDate(e.value)}
                        view="year"
                        dateFormat="yy"
                        className="seller-calender-picker w-full"
                        inputClassName="p-3 mt-2 ml-1 mr-1 mb-3 w-full"
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                      />
                      {form?.formErrors?.buildYear && (
                        <small className="p-error text-sm ml-2">
                          {form?.formErrors?.buildYear}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-6 ">
                    <CustomInput
                      label="Properties Description"
                      extraClassName="w-full"
                      placeholder="Keep it short"
                      labeClassName="text-dark"
                      name="propertiesTitle"
                      value={form?.propertiesTitle}
                      onChange={handleChange}
                      required
                      errorMessage={form?.formErrors?.propertiesTitle}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="w-full lg:mt-2 md:mt-2 p-0 m-0">
                      <label className="text-dark text-lg font-medium flex align-items-start font-bold ml-1">
                        Asking Price ($)
                        <code className="p-error text-sm">*</code>
                      </label>
                      <InputNumber
                        inputId="integeronly"
                        value={form?.price}
                        onValueChange={handleChange}
                        name="price"
                        placeholder="How much do you want to sell your property for?"
                        className="w-full  bg-transparent mb-3"
                        inputClassName="mt-2 p-3 ml-1 mr-1"
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                      />
                      {form?.formErrors?.price && (
                        <small className="p-error text-sm ml-2">
                          {form?.formErrors?.price}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomTextArea
                      label="Property Highlights"
                      placeholder="More Details = More Interested Buyers!"
                      className=" w-full"
                      extraClassName="w-full"
                      name="Description"
                      value={form?.Description}
                      onChange={handleChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <h2 className="text-dark font-medium text-lg m-0 ml-1 py-0 col-12 mb-2 flex align-items-start font-bold">
                    Add photos<code className="p-error text-sm">*</code>
                  </h2>
                  {form?.formErrors?.image && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.image}
                    </small>
                  )}
                  <div
                    className={`col-12 grid p-0 m-0 ${
                      form?.formErrors?.password?.length > 0 ||
                      form?.formErrors?.confirmPassword?.length > 0
                        ? "pointer-events-none"
                        : ""
                    }`}
                  >
                    {inputList?.map((item, index) => {
                      return (
                        <>
                          <div className="col-12 md:col-4 lg:col-2 mb-0 ">
                            <div className="image-box">
                              <div
                                className="photo-upload bg-lightest-gray text-center border-round-lg relative"
                                style={{
                                  height: "114px",
                                  width: "100%",
                                }}
                              >
                                <input
                                  onChange={(e) => handleImage(e, index)}
                                  id="formImage"
                                  type="file"
                                  accept="image/*"
                                  className="absolute w-full h-full left-0 top-0 opacity-0"
                                  multiple
                                />
                                <label className="h-full flex flex-column align-items-center justify-content-center">
                                  {inputList?.length !== 1 && (
                                    <i
                                      className="pi pi-times absolute left-10 top-0 right-0"
                                      onClick={() => handleRemoveClick(index)}
                                      style={{ color: "#708090" }}
                                    ></i>
                                  )}
                                  <img
                                    className={
                                      item.upload === ""
                                        ? "w-2"
                                        : "w-full h-full fit-cover"
                                    }
                                    src={
                                      typeof item?.upload === "string"
                                        ? Constants.BASE_URL + `${item?.upload}`
                                        : typeof item?.upload === "object" &&
                                          item?.upload !== null
                                        ? window.URL.createObjectURL(
                                            item?.upload
                                          )
                                        : Gallery
                                    }
                                    alt=""
                                  />
                                  {item?.upload === "" ? (
                                    <>
                                      <i
                                        className="pi pi-plus text-gray "
                                        style={{ fontSize: "1rem" }}
                                      ></i>
                                      <p className="mb-0 text-black">
                                        Add a photo
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>

                          {inputList.length - 1 === index && (
                            <div className="col-12 md:col-4 lg:col-2 ">
                              <div
                                className="photo-upload bg-lightest-gray text-center border-round-lg"
                                style={{
                                  height: "114px",
                                  width: "100%",
                                }}
                              >
                                <label
                                  className="h-full flex flex-column align-items-center justify-content-center"
                                  onClick={handleAddImage}
                                >
                                  <i
                                    className="pi pi-plus text-gray "
                                    style={{ fontSize: "1rem" }}
                                  ></i>
                                  <p className="mb-0">Add more photos</p>
                                </label>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <p className="text-gray m-0 ml-1 col-12 md:col-12 mb-4">
                    Maximum 10 images & 10MB file size - PNG, JPEG
                  </p>
                  <h2 className="text-dark font-medium text-lg m-0 ml-1 py-0 col-12 mb-2 flex align-items-start font-bold">
                    Add Floorplan
                  </h2>
                  <div
                    className={`col-12 grid py-0 ${
                      form?.formErrors?.password?.length > 0 ||
                      form?.formErrors?.confirmPassword?.length > 0
                        ? "pointer-events-none"
                        : ""
                    }`}
                  >
                    {floorPlan?.map((item, index) => {
                      return (
                        <div className="col-12 md:col-4 lg:col-2">
                          <div className="image-box">
                            <div
                              className="photo-upload bg-lightest-gray text-center border-round-lg relative"
                              style={{
                                height: "114px",
                                width: "100%",
                              }}
                            >
                              <input
                                onChange={(e) =>
                                  handleChangeAddFloorPlan(e, index)
                                }
                                id="formImage"
                                type="file"
                                accept="image/*"
                                className="absolute w-full h-full left-0 top-0 opacity-0"
                                multiple
                              />
                              <label className="h-full flex flex-column align-items-center justify-content-center">
                                {floorPlan?.length !== 1 && (
                                  <i
                                    className="pi pi-times absolute left-10 top-0 right-0"
                                    onClick={() =>
                                      handleRemoveFloorPlane(index)
                                    }
                                    style={{ color: "#708090" }}
                                  ></i>
                                )}
                                <img
                                  className={
                                    item.upload === ""
                                      ? "w-2"
                                      : "w-full h-full fit-cover"
                                  }
                                  src={
                                    typeof item?.upload === "string"
                                      ? Constants.BASE_URL + `${item?.upload}`
                                      : typeof item?.upload === "object" &&
                                        item?.upload !== null
                                      ? window.URL.createObjectURL(item?.upload)
                                      : Gallery
                                  }
                                  alt=""
                                />
                                {item?.upload === "" ? (
                                  <>
                                    <i
                                      className="pi pi-plus text-gray "
                                      style={{ fontSize: "1rem" }}
                                    ></i>
                                    <p className="mb-0 text-black">
                                      Add a photo
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <p className="text-gray m-0 ml-1 col-12 mb-3">
                    Maximum 10 images & 10MB file size - PNG, JPEG
                  </p>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <PropertyType
                    PropertyList={PropertyList}
                    check={check}
                    setChecked={setChecked}
                    handleTypeChange={handleTypeChange}
                    form={form}
                  />
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <PropertyDetail
                    bedRoomQuantity={bedRoomQuantity}
                    bathroomQuantity={bathroomQuantity}
                    carSpaceQuantity={carSpaceQuantity}
                    form={form}
                    handleRoomChange={handleRoomChange}
                    highlight={highlight}
                    bathRoomHightlight={bathRoomHightlight}
                    carHightlight={carHightlight}
                  />
                  <div className="col-12 md:col-12 mb-2">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <h2 className="text-dark font-medium text-2xl ml-1 col-12 flex align-items-start font-bold m-0 mb-2">
                    Land size m <sup>2</sup>
                    <code className="p-error text-sm">*</code>
                  </h2>
                  <div className="col-12 md:col-6 lg:col-6">
                    <CustomInput
                      extraClassName="w-full"
                      labelClassName="text-black"
                      onChange={handleChange}
                      name="minLand"
                      optionLabel=""
                      value={minLand}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                    {form?.formErrors?.landSize && (
                      <small className="p-error text-sm ml-2">
                        {form?.formErrors?.landSize}
                      </small>
                    )}
                  </div>
                  <div className="col-12 md:col-12 mb-2">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12">
                    <OutdoorFeatures
                      PropertyList={PropertyList}
                      form={form}
                      setForm={setForm}
                      id={id}
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12">
                    <IndoorFeatures
                      PropertyList={PropertyList}
                      form={form}
                      setForm={setForm}
                      id={id}
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12">
                    <ClimateControl
                      PropertyList={PropertyList}
                      form={form}
                      setForm={setForm}
                      id={id}
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                </div>
                <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                  <div className="col-12 md:col-3  lg:col-2 ">
                    <div className="mb-3">
                      <ButtonComponent
                        label="CLEAR ALL"
                        className=" bg-transparent text-black  border-0"
                        onClick={handleClear}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-3 lg:col-2">
                    <div className=" " onClick={gotoBtn}>
                      <Button
                        label="NEXT"
                        className="bg-main border-main w-full p-3 font-semibold font-base"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </ContentArea>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {page === 2 ? (
        <SellerConfirmDetails
          form={form}
          setPage={setPage}
          page={page}
          emailCheck={emailCheck}
          id={id}
        />
      ) : (
        ""
      )}
      {page === 3 ? <SellerSuccessfullProfile /> : ""}
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
      <div className="all-profile-modal">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="all-profile-modal lg:w-5 md:w-10 w-11"
        >
          <p className="m-0 ">
            <h1
              className="text-center font-semibold  py-3"
              style={{ fontSize: "32px", color: "#231F20" }}
            >
              This email already exist.
              <br /> To create a Brief, please login{" "}
            </h1>
            <p className="text-center text-black font-medium text-lg line-height-3">
              Thank you for your interest in creating a Brief with us.<br></br>
              It appears that the email address you provided is already
              associated with an existing account. To proceed, please log in
              using your credentials to create a Brief or manage your existing
              account.
            </p>
            <hr className="mt-5 mb-5" style={{ color: "#CCD1D1" }} />
            <div className=" flex align-items-center justify-content-between ">
              <span>
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white  border-black text-black text-base px-5 px-3"
                  onClick={() => setVisible(false)}
                ></ButtonComponent>
              </span>
              <span>
                <ButtonComponent
                  label="LOG IN "
                  className="bg-white px-5 text-main"
                  onClick={() => navigate("/sign-in")}
                ></ButtonComponent>
              </span>
            </div>
          </p>
        </Dialog>
      </div>
    </>
  );
};
