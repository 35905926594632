import React, { useState, useEffect } from "react";
import { Index } from "../../../layout";
import ContentArea from "../../../shared/ContentArea";
import {
  CustomCheckbox,
  CustomInput,
  CustomPhoneInput,
  CustomRadioButtons,
  CustomCalenderInput,
  CustomDropDown,
  CustomTextArea,
} from "../../../components/input/AllInput";
import { Dialog } from "primereact/dialog";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  OutdoorFeatures,
  PropertyDetail,
  PropertyType,
} from "../CommonFeild";
import { RenterIndex } from "./RenterIndex";
import { Calendar } from "primereact/calendar";
import { RenterConfirmDetails } from "./RenterConfirmDetails";
import RenterSuccessfullProfile from "./RenterSuccessfullProfile";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Button } from "primereact/button";
export const RenterForm = ({ SetSelectProfile, selectProfile }) => {
  const {
    form,
    setForm,
    code,
    setCode,
    handleChange,
    show,
    id,
    minProperty,
    emailCheck,
    token,
    maxProperty,
    inputList,
    timeFrameDropdown,
    handleAddClick,
    handleChangeAddress,
    handleRemoveClick,
    handleSelectAddress,
    handleRoomChange,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    handleRangeChange,
    highlight,
    handleChangeSuburb,
    address,
    handleSelectAddressSuburb,
    bathRoomHightlight,
    carHightlight,
    PropertyList,
    check,
    page,
    setPage,
    handleClear,
    handleTypeChange,
    dates,
    setDates,
    handlePropertyRequirments,
    handleSubmit,
  } = RenterIndex();
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  console.log(form, "====fomr=====");
  return (
    <>
      {page === 1 ? (
        <>
          <div className="renter-form-container  bg-green-light  pt-6">
            <div className="py-7 w-full">
              <ContainerBox>
                <div className="grid justify-content-center lg:mb-6 lg:mt-6 mt-3 mb-3 ">
                  <div className="col-12 md:col-12 lg:col-7 text-center">
                    <h1
                      className="heading_text font-semibold text-dark p-0 m-0"
                      style={{ fontSize: "56px" }}
                    >
                      Renter Brief Form
                    </h1>
                    <p className="text-lg text-black line-height-3 font-medium">
                      Complete the details below, so we can understand your
                      requirements, and our expert team will help you discover
                      the ideal property that aligns with your needs.
                    </p>
                  </div>
                </div>
              </ContainerBox>
              <ContentArea>
                {/* Contact-details */}
                <div className="grid p-0 m-0 ">
                  <ContactDetail
                    form={form}
                    setForm={setForm}
                    token={token}
                    emailCheck={emailCheck}
                    show={show}
                    handlechangeStreetAddress={handlechangeStreetAddress}
                    handleSelectStreetAddress={handleSelectStreetAddress}
                    code={code}
                    setCode={setCode}
                    handleChange={handleChange}
                    address={address}
                    handleSelectAddressSuburb={handleSelectAddressSuburb}
                    handleChangeSuburb={handleChangeSuburb}
                  />

                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Property type<code className="p-error text-sm">*</code>
                    </h2>
                    {form?.formErrors?.propertyType && (
                      <small className="p-error text-sm ml-1">
                        {form?.formErrors?.propertyType}
                      </small>
                    )}
                  </div>
                  {PropertyList?.propertyTypes?.length > 0 &&
                    PropertyList?.propertyTypes?.map((item) => {
                      return (
                        <div className="col-12 md:col-6">
                          {item?.forProfile?.includes("RENTER") ? (
                            <CustomCheckbox
                              optionLabel={item?.propertyType}
                              id={item?.propertyType}
                              name="propertyType"
                              value={item}
                              onChange={handleTypeChange}
                              disabled={
                                form?.formErrors?.password?.length > 0 ||
                                form?.formErrors?.confirmPassword?.length > 0
                                  ? true
                                  : false
                              }
                              checked={check?.some((type) =>
                                item._id === type._id ? true : false
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0 ">
                      Weekly Price Range
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-12  text-center"></div>
                  <div className=" p-0 m-0 grid w-full ">
                    <div className="col-12 md:col-6">
                      <CustomInput
                        extraClassName="w-full"
                        placeholder="Select"
                        label="Min"
                        name="min"
                        keyfilter="int"
                        value={form?.priceRange?.min}
                        onChange={handleRangeChange}
                        errorMessage={form?.formErrors?.min}
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <CustomInput
                        extraClassName="w-full"
                        placeholder="Select"
                        label="Max"
                        keyfilter="int"
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                        name="max"
                        value={form?.priceRange?.max}
                        onChange={handleRangeChange}
                        errorMessage={form?.formErrors?.max}
                      />
                    </div>
                    {/* <Slider className=" mr-1 ml-1 p-0 m-0 price-range"
                      range onChange={(e) => handleRangeChange(e, "pricerange")} name="priceRange"
                      value={form.selectPriceRange} min={50000} max={15000000} /> */}
                  </div>
                  {form?.formErrors?.priceRange && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.priceRange}
                    </small>
                  )}
                  <div className="col-12 md:col-12 mt-2">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
                      Purchase Location
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <>
                    {inputList?.map((item, index) => {
                      return (
                        <div
                          className={`renter-suburb col-12 md:col-12 lg:col-12 ${
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? "pointer-events-none"
                              : ""
                          }`}
                        >
                          <PlacesAutocomplete
                            value={item.streetAddress}
                            onChange={(e) => handleChangeAddress(e, index)}
                            onSelect={(e) => handleSelectAddress(e, index)}
                            searchOptions={{
                              componentRestrictions: { country: ["au"] },
                              types: ["locality"],
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                              item,
                            }) => (
                              <div className="subhurb-hightlight">
                                <CustomInput
                                  label="Suburb"
                                  value={item?.streetAddress}
                                  {...getInputProps({
                                    placeholder: "Search Suburb",
                                    className:
                                      "location-search-input w-full p-3",
                                  })}
                                />
                                {inputList.length !== 1 && (
                                  <i
                                    className="pi pi-minus lg:p-2 md:p-2 "
                                    style={{
                                      " color": "rgb(112, 128, 144)",
                                      // "padding": "10px",
                                      marginTop: "10px",
                                      marginLeft: "5px",
                                      backgroundColor: "#f9fafa",
                                      borderRadius: "10px",
                                    }}
                                    onClick={() => handleRemoveClick(index)}
                                  ></i>
                                  // <button className="mr10" >
                                  //   Remove
                                  // </button>
                                )}
                                {show === true ? (
                                  <div className="autocomplete-dropdown-container absolute top-100 bg-white z-2 p-0 shadow-1  mb-2 lg:col-6">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                                        : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          key={suggestion.placeId}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span className="">
                                            {suggestion.description}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {inputList.length - 1 === index && (
                            <div className=" add-suburb py-2 p-1 ">
                              <p
                                onClick={handleAddClick}
                                className="text-xl text-dark font-medium justify-content-between flex p-2 border-round-lg p-0 m-0"
                                style={{
                                  backgroundColor: "rgba(249, 250, 250, 1)",
                                }}
                              >
                                &nbsp; Add Suburb
                                <span>
                                  <i
                                    className="pi pi-plus"
                                    style={{
                                      fontSize: "1rem",
                                      color: "rgba(42, 53, 61, 1)",
                                    }}
                                  ></i>
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                  {form?.formErrors?.address && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.address}
                    </small>
                  )}
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
                  </div>

                  <PropertyDetail
                    highlight={highlight}
                    carHightlight={carHightlight}
                    bathRoomHightlight={bathRoomHightlight}
                    form={form}
                    handleRoomChange={handleRoomChange}
                  />

                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0">
                      Ready to Rent<code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-6">
                    <CustomDropDown
                      // label="Min"
                      placeholder="Select"
                      extraClassName="w-full"
                      labelClassName="text-black"
                      options={timeFrameDropdown}
                      onChange={handleChange}
                      name="purchaseTimeFrame"
                      optionLabel=""
                      value={form.purchaseTimeFrame}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                    {form?.formErrors?.purchaseTimeFrame && (
                      <small className="p-error text-sm ml-2">
                        {form?.formErrors?.purchaseTimeFrame}
                      </small>
                    )}
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Property requierments
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-4 lg:col-6">
                    <CustomCheckbox
                      optionLabel="Furnished"
                      extraClassName=" w-auto"
                      id="Furnished"
                      name="furnished"
                      value="furnished"
                      onChange={handlePropertyRequirments}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        form?.propertyRequierments?.furnished !== null
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-6 ">
                    <CustomCheckbox
                      optionLabel="Pets considered"
                      extraClassName="w-auto"
                      id="Pets considered"
                      name="petsConsidered"
                      value="petsConsidered"
                      onChange={handlePropertyRequirments}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={
                        form?.propertyRequierments?.petsConsidered !== null
                          ? true
                          : false
                      }
                    />
                  </div>
                  {form?.formErrors?.propertyRequierments && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.propertyRequierments}
                    </small>
                  )}
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12 md:col-12">
                    <OutdoorFeatures
                      PropertyList={PropertyList}
                      setForm={setForm}
                      form={form}
                      id={id}
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12">
                    <IndoorFeatures
                      PropertyList={PropertyList}
                      setForm={setForm}
                      form={form}
                      id={id}
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  <div className="col-12">
                    <ClimateControl
                      PropertyList={PropertyList}
                      setForm={setForm}
                      form={form}
                      id={id}
                    />
                    {/* <RenterClimateControl /> */}
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 m-0 flex align-items-start font-bold">
                      Top 2 Property must-haves
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="grid p-0 m-0 w-full">
                    <div className="col-12 md:col-6 lg:col-6 flex ">
                      <span className="mt-3 text-xl"> 1.</span>
                      <span className="w-full ml-2">
                        <CustomInput
                          extraClassName="w-full"
                          labelClassName="text-black"
                          onChange={handleChange}
                          name="minProperty"
                          optionLabel=""
                          value={minProperty}
                          disabled={
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? true
                              : false
                          }
                        />
                      </span>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6 flex">
                      <span className="mt-3 text-xl"> 2.</span>
                      <span className="w-full ml-2">
                        <CustomInput
                          extraClassName="w-full"
                          labelClassName="text-black"
                          optionLabel=""
                          name="maxProperty"
                          onChange={handleChange}
                          value={maxProperty}
                          disabled={
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? true
                              : false
                          }
                        />
                      </span>
                    </div>
                    {form?.formErrors?.propertyMustHave && (
                      <small className="p-error text-sm ml-3">
                        {form?.formErrors?.propertyMustHave}
                      </small>
                    )}
                  </div>

                  <div className=" renter-textarea col-12 md:col-12 lg:col-12 ">
                    <CustomTextArea
                      label="Notes"
                      className="input_textarea w-full bg-transparent    p-3  mb-3"
                      placeholder="Tell us more about the property you are looking for"
                      name="note"
                      onChange={handleChange}
                      value={form?.note}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                </div>
                <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                  <div className="col-12 md:col-3  lg:col-2 ">
                    <div className="mb-3 lg:mb-0">
                      <ButtonComponent
                        label="CLEAR ALL"
                        className="  bg-transparent text-black  border-0"
                        onClick={handleClear}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-3 lg:col-2">
                    <div className=" " onClick={gotoBtn}>
                      <Button
                        label="NEXT"
                        className="bg-main border-main w-full p-3 font-semibold font-base"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </ContentArea>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {page === 2 ? (
        <RenterConfirmDetails
          form={form}
          setPage={setPage}
          emailCheck={emailCheck}
        />
      ) : (
        ""
      )}
      {page == 3 ? <RenterSuccessfullProfile /> : ""}
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
      <div className="all-profile-modal">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="all-profile-modal lg:w-5 md:w-10 w-11"
        >
          <p className="m-0 ">
            <h1
              className="text-center font-semibold  py-3"
              style={{ fontSize: "32px", color: "#231F20" }}
            >
              This email already exist.
              <br /> To create a Brief, please login{" "}
            </h1>
            <p className="text-center text-black font-medium text-lg line-height-3">
              Thank you for your interest in creating a Brief with us.<br></br>
              It appears that the email address you provided is already
              associated with an existing account. To proceed, please log in
              using your credentials to create a Brief or manage your existing
              account.
            </p>
            <hr className="mt-5 mb-5" style={{ color: "#CCD1D1" }} />
            <div className=" flex align-items-center justify-content-between ">
              <span>
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white  border-black text-black text-base px-5 px-3"
                  onClick={() => setVisible(false)}
                ></ButtonComponent>
              </span>

              <span>
                <ButtonComponent
                  label="LOG IN "
                  className="bg-white px-5 text-main"
                  onClick={() => navigate("/sign-in")}
                ></ButtonComponent>
              </span>
            </div>
          </p>
        </Dialog>
      </div>
    </>
  );
};
