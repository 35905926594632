import React from "react";
import { Index } from "../../../layout";
import ContentArea from "../../../shared/ContentArea";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import { Link } from "react-router-dom";
import LocationIcon from "../../../assets/icons/loaction.png";
import OwnerIcon from "../../../assets/icons/Owner.png";
import AllTypeIcon from "../../../assets/icons/All types.png";
import CheckmarkIcon from "../../../assets/icons/checkmark-circle-01.png";
import PriceIcon from "../../../assets/icons/price.png";
import CalendarIcon from "../../../assets/icons/calendar.png";
import LandIcon from "../../../assets/icons/Land size.png";

import { ConfirmContactDetails, PropertySpaceDetail } from "../CommonFeild";
import { FormTopHeading } from "../../../shared/FormTopHeading";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import ProfilePreview from "./ProfilePreview";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { isAuthenticated } from "../../../services/authService";

export const ConfirmDetails = ({
  data,
  setPage,
  SetSelectProfile,
  selectProfile,
  emailCheck,
}) => {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="buyer-confirm-details-container bg-green-light pt-6 ">
        <div className="py-7 w-full lg:mb-6 lg:mt-6 mt-3 mb-3   ">
          <ContainerBox>
            <FormTopHeading
              heading="Confirm details"
              description="​Please make sure all details are correct and then submit for verification "
            />
            <div>
              {emailCheck === false ? (
                <h2 className="text-center mb-5 mt-0 p-0 m-0 lg:mb-8 md:mb-6 text-main">
                  Your Account has been created Successfully
                </h2>
              ) : (
                ""
              )}
            </div>
          </ContainerBox>

          <ContentArea>
            <div className="grid p-0 m-0 ">
              <ConfirmContactDetails data={data} />
              {/* Type of Purchase Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Type of Purchase
                </h2>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center">
                  <img src={OwnerIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                    {data?.typeOfPurchase === "ownerOccupied"
                      ? "owner Occupied"
                      : data?.typeOfPurchase === "investment"
                      ? "Investment"
                      : data?.typeOfPurchase}
                  </span>
                </div>
              </div>
              {/* <div className="col-12 md:col-12">
                                        <p className=" text-black font-medium text-lg p-0 m-0 "> Demo text</p>
                                    </div> */}
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* New or established property Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  New or established property
                </h2>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center">
                  <img src={AllTypeIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black font-semibold">
                    {data?.establishedProperty === "allTypes"
                      ? "Any"
                      : data?.establishedProperty === "new"
                      ? "New"
                      : data?.establishedProperty === "established"
                      ? "Established"
                      : data?.establishedProperty}
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Property type  Section*/}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Property type
                </h2>
              </div>
              {data?.propertyType?.length > 0 &&
                data?.propertyType?.map((item) => {
                  return (
                    <div className="col-12 md:col-6  mb-3">
                      <div className="flex align-items-center">
                        <img
                          src={`https://therealestateapi.appdeft.biz/${item?.icon}`}
                          width={20}
                          height={20}
                          alt=""
                        ></img>
                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          {item.propertyType}
                        </span>
                      </div>
                    </div>
                  );
                })}

              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Price section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Price
                </h2>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center">
                  <img src={PriceIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black font-semibold">
                    ${data?.priceData?.min}-${data?.priceData?.max}
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Bedrooms section */}
              <PropertySpaceDetail data={data} />

              {/* Purchase timeframe Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Purchase timeframe
                </h2>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center">
                  <img src={CalendarIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black font-semibold ">
                    {data?.purchaseTimeFrame}
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Land Size section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Land Size
                </h2>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <div className="flex align-items-center">
                  <img src={LandIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black font-semibold">
                    {data?.landSize?.min} - {data?.landSize?.max}
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Purchase Location Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Purchase Location
                </h2>
              </div>
              {data?.address?.map((item, index) => {
                return (
                  <>
                    {item?.suburb?.length === 0 &&
                    item?.state.length === 0 ? null : (
                      <div className="col-12 md:col-12 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={LocationIcon}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black text-lg font-semibold">
                            {item?.streetAddress}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}

              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Purchase method Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Purchase method
                </h2>
              </div>
              <div className="col-12 md:col-12  mb-3">
                <div className="flex align-items-center">
                  <img src={PriceIcon} width={20} height={20} alt=""></img>
                  <span className="ml-3 font-medium text-black text-lg font-semibold">
                    {data?.purchaseMethod === "OWNFUNDS"
                      ? "Own Funds"
                      : data?.financeDetails?.isArranged === true
                      ? "Finance is arranged"
                      : "Need to arrange finance"}
                  </span>
                </div>
              </div>
              {data?.financeDetails?.isArranged === true ? (
                <div className="col-12 md:col-12  mb-3">
                  <div className="flex align-items-center">
                    <img
                      src={CheckmarkIcon}
                      width={20}
                      height={20}
                      alt=""
                    ></img>
                    <span className="ml-3 font-medium text-black text-lg font-semibold">
                      Pre-approved -${data?.financeDetails?.preApproved}
                    </span>
                  </div>
                </div>
              ) : null}
              {data?.purchaseMethod !== "OWNFUNDS" ? (
                <div className="col-12 md:col-12  mb-3">
                  <div className="flex align-items-center">
                    <img
                      src={CheckmarkIcon}
                      width={20}
                      height={20}
                      alt=""
                    ></img>
                    <span className="ml-3 font-medium text-black text-lg font-semibold">
                      Deposit -${data?.financeDetails?.depositAmount}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Outdoor features Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Outdoor features
                </h2>
              </div>
              {data?.outdoorFeatures?.length > 0 ? (
                data?.outdoorFeatures?.map((item, index) => {
                  return (
                    <div className="col-12 md:col-6 mb-3">
                      <div className="flex align-items-center">
                        <img
                          src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                          width={20}
                          height={20}
                          alt=""
                        ></img>
                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          {item?.title}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="ml-3 font-medium text-black text-lg font-semibold">
                  N/A
                </span>
              )}

              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Indoor features Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Indoor features
                </h2>
              </div>
              {data?.indoorFeatures?.length > 0 ? (
                data?.indoorFeatures?.map((item) => {
                  return (
                    <div className="col-12 md:col-6 mb-3">
                      <div className="flex align-items-center">
                        <img
                          src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                          width={20}
                          height={20}
                          alt=""
                        ></img>
                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="ml-3 font-medium text-black text-lg font-semibold">
                  N/A
                </span>
              )}
              <div className="col-12 md:col-12">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Climate control & energy Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Climate control & energy
                </h2>
              </div>
              {data?.climateControlEnergy?.length > 0 ? (
                data?.climateControlEnergy?.map((item) => {
                  return (
                    <div className="col-12 md:col-6 mb-3">
                      <div className="flex align-items-center">
                        <img
                          src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                          width={20}
                          height={20}
                          alt=""
                        ></img>
                        <span className="ml-3 font-medium text-black text-lg font-semibold">
                          {item?.title}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="ml-3 font-medium text-black text-lg font-semibold">
                  N/A
                </span>
              )}

              <div className="col-12 md:col-12 mb-3">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              <div className="col-12 md:col-12">
                <h2 className=" text-dark font-medium text-2xl font-bold">
                  Property Must Have
                </h2>
              </div>
              <div className="col-12 md:col-12">
                <div className="flex align-items-center p-0 m-0">
                  <p className="p-0 m-0 text-black text-lg font-semibold">
                    {data?.propertyMustHave?.min}
                  </p>
                </div>
              </div>
              <div className="col-12 md:col-12">
                <div className="flex align-items-center p-0 m-0">
                  <p className="p-0 m-0 text-black text-lg font-semibold">
                    {data?.propertyMustHave?.max}
                  </p>
                </div>
              </div>
              {data?.propertyMustHave?.min?.length == 0 &&
              data?.propertyMustHave?.max?.length == 0 ? (
                <span className="ml-3 font-medium text-black text-lg font-semibold">
                  N/A
                </span>
              ) : null}

              <div className="col-12 md:col-12 mb-3">
                <hr className="buyer-hr p-0 m-0" />
              </div>

              {/* Notes Section */}
              <div className="col-12 md:col-12">
                <h2 className=" text-dark text-lg text-2xl font-bold">Notes</h2>
              </div>
              <div className="col-12 md:col-12 ">
                <div className="flex align-items-center p-0 m-0">
                  <p className="p-0 m-0 text-black font-medium font-semibold">
                    {data?.note?.length > 0 ? (
                      data?.note
                    ) : (
                      <span className="ml-3 font-medium text-black text-lg font-semibold">
                        N/A
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-12 md:col-12 mb-3">
                <hr className="buyer-hr p-0 m-0" />
              </div>
            </div>
            <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
              <div
                className="col-12 md:col-3 md:text-left lg:col-2 mb-3 "
                onClick={gotoBtn}
              >
                <div className="no-underline">
                  <ButtonComponent
                    onClick={() => setPage(1)}
                    label="BACK TO EDIT"
                    className="md:text-left bg-transparent border-0 text-black"
                  ></ButtonComponent>
                </div>
              </div>
              <div className="col-12 md:col-4 lg:col-3 lg:w-3 ">
                <div className="no-underline" onClick={gotoBtn}>
                  <ButtonComponent
                    onClick={() => setPage(3)}
                    label="PREVIEW BRIEF"
                    className="bg-main text-light-cyan  border-0 lg:w-10 lg:mx-6"
                    style={{ color: "rgba(143, 154, 153, 1)" }}
                  ></ButtonComponent>
                </div>
              </div>
            </div>
          </ContentArea>
        </div>
      </div>
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                <p
                  className=" text-black font-medium pick-login-here"
                  style={{ fontSize: "18px" }}
                >
                  {" "}
                  Already have an account?
                  <cod
                    className="text-main font-semibold text-sm cursor-pointer"
                    onClick={() => {
                      navigate("/sign-in");
                      SetSelectProfile(false);
                      gotoBtn();
                    }}
                  >
                    {" "}
                    Login here
                  </cod>{" "}
                </p>
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
