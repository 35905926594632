import React, { useState, useEffect } from "react";
import { Index } from "../../../layout";
import {
  Form,
  PropertTypeFeature,
  PropertySummary,
  PropertyfeaturesContent,
} from "../CommonFeild";
import { ContainerBox } from "../../../shared/ContainerBox";
import BadIcon from "../../../assets/icons/bad (1).png";
import BathroomIcon from "../../../assets/icons/tablelamp.png";
import CarIcon from "../../../assets/icons/car.png";
import ShareIcon from "../../../assets/icons/share.png";
import StarIcon from "../../../assets/icons/star.png";
import { ProfileCard } from "../../../components/cards/ProfileCard";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  buyerGeneralInfo,
  enquieryMessageAction,
} from "../../../redux/actions/buyerAction";
import {
  allPostAction,
  getpostSavedAction,
} from "../../../redux/actions/postAction";
import { postDetailAction } from "../../../redux/actions/postAction";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../redux/actions/toastAction";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import { postSavedUnSavedAction } from "../../../redux/actions/postAction";
import { useNavigate } from "react-router-dom";
import { emailValidation } from "../../../utils/regex";
import FurnishedIcon from "../../../assets/icons/Furnished and Pets considered.png";
import CalendarIcon from "../../../assets/icons/calendar.png";
import PriceIcon from "../../../assets/icons/price.png";

export const RenterMoreInfo = ({ SetSelectProfile, selectProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [outdoor, setOutdoor] = useState([]);
  const [code, setCode] = useState("+61");
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    country: "",
    mobile: "",
    message: "",
  });
  const token = localStorage.getItem("realState");
  useEffect(() => {
    if (id) {
      dispatch(postDetailAction(id));
    }

    dispatch(buyerGeneralInfo());
    dispatch(allPostAction({}));
  }, []);
  const postData = useSelector((state) => state.postDetail.postDetailProfile);
  useEffect(() => {
    let array = [];
    let outDoorNewData = postData?.outdoorFeatures;
    array.push(outDoorNewData);
    let inDoorNewData = postData?.indoorFeatures;
    array.push(inDoorNewData);
    let climateNewData = postData?.climateControlEnergy;
    array.push(climateNewData);
    let result = array.reduce((r, a) => r.concat(a), []);
    setOutdoor(result);
  }, [postData]);
  let data = useSelector((state) => state.allPost.allPostProfile);
  const formErrorCheck = () => {
    let formErrors = {};
    if (form?.fullName === "" || form?.fullName === undefined) {
      formErrors.firstName = "Please Enter Full Name";
    }
    if (form.email === "" || form.email === undefined) {
      formErrors.email = "Please Enter Email";
    } else if (!emailValidation(form.email)) {
      formErrors.email = `Please enter valid email!`;
    }
    if (form.mobile === "" || form.mobile === undefined) {
      formErrors.mobile = "Please Enter Valid Mobile Number";
    } else if (isNaN(form.mobile) == true) {
      formErrors.mobile = "Please Enter Digit Only";
    }
    if (Object.keys(formErrors).length == 0) {
      return true;
    } else {
      setForm({ ...form, formErrors });
      return false;
    }
  };
  const handleSubmit = () => {
    if (form.fullName === "" || form.email === "" || form.mobile === "") {
      dispatch(
        showToast({ severity: "error", summary: "All Fields Required" })
      );
    } else if (!formErrorCheck()) {
      return false;
    } else {
      dispatch(enquieryMessageAction(form, id));
      setForm({
        fullName: "",
        email: "",
        country: "",
        mobile: "",
        message: "",
      });
    }
  };
  const handleSaved = () => {
    if (token) {
      dispatch(postSavedUnSavedAction(id)).then((res) => {
        if (res.success) {
          dispatch(getpostSavedAction());
        }
      });
    } else {
      dispatch(
        showToast({ severity: "error", summary: "Please login to save Brief" })
      );
    }
  };

  const shareFunction = () => {
    navigator.clipboard.writeText(
      "https://therealestateplace.appdeft.biz/#" + location.pathname
    );
    dispatch(
      showToast({ severity: "success", summary: "link is copied to clipbard" })
    );
  };

  const postSaved = useSelector(
    (state) => state.postSavedUnSaved.postSavedUnSavedProfile
  );

  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  useEffect(() => {
    if (token) {
      dispatch(getpostSavedAction());
    }
  }, [token]);
  let similarProperty = data?.filter((item) => item?._id !== id);
  const briefSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const backFunc = () => {
    navigate(-1);
  };
  return (
    <>
      <>
        <div className="pb-6 bg-green-light">
          <ContainerBox>
            <div className="seller-form-container  pt-8 mt-4">
              <div className="lg:mb-5 md:mb-5 mb-5 w-full">
                <div className="col-12 md:col-12 flex lg:mt-5 lg:mb-5 mt-3 mb-3">
                  <Link
                    className="no-underline flex align-items-center"
                    onClick={() => {
                      gotoBtn();
                      backFunc();
                    }}
                  >
                    <i
                      className="pi pi-arrow-left text-black "
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                    <span
                      className="ml-2 font-medium text-lg"
                      style={{ color: "rgba(72, 72, 72, 1)" }}
                    >
                      Back
                    </span>
                  </Link>
                </div>

                <div className=" grid p-0 m-0 ">
                  <div className="col-12 md:col-6 lg:col-7 ">
                    <div
                      className="shadow-2 lg:p-4 p-3"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="flex align-items-start justify-content-between mt-3 md:mb-3">
                        <div
                          class="heading-text-more-info white-space-nowrap overflow-hidden text-overflow-ellipsis lg:w-10 text-dark font-semibold"
                          style={{
                            width: "160px",
                            fontSize: "32px",
                            fontFamily: "Raleway",
                          }}
                        >
                          <div className="grid">
                            <div className="col-12 md:col-12 lg:col-7">
                              <p
                                className="font-semibold p-0 m-0 text-dark heading-text-more-info lg:mb-3 md:mb-3"
                                style={{ fontSize: "32px" }}
                              >
                                Full Renter Brief
                              </p>
                            </div>
                            <div className="col-12 md:col-12 lg:col-5">
                              <p className="text-base m-0 font-medium text-dark">
                                {" "}
                                Renter Id: {`R ${postData?.id}`}
                              </p>
                            </div>
                          </div>
                          <div
                            className="property-space   align-items-center p-0 m-0 mb-3"
                            style={{ display: "flex" }}
                          >
                            <img
                              src={BadIcon}
                              width={24}
                              height={24}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-base text-black">
                              {postData?.bedrooms === null
                                ? "any"
                                : `${postData?.bedrooms}+`}
                            </span>

                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <img
                                src={BathroomIcon}
                                width={24}
                                height={24}
                                alt=""
                              ></img>
                              <span className="ml-3 font-medium text-base text-black">
                                {postData?.bathrooms === null
                                  ? "any"
                                  : `${postData?.bathrooms}+`}
                              </span>
                            </p>

                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <img
                                src={CarIcon}
                                width={24}
                                height={24}
                                alt=""
                              ></img>
                              <span className="ml-3 font-medium text-base text-black">
                                {postData?.carSpaces === null
                                  ? "any"
                                  : `${postData.carSpaces}+`}
                              </span>
                            </p>
                            <p className="flex align-items-center  ml-3 p-0 m-0">
                              <span className="font-medium text-base text-black">
                                |
                              </span>
                              <span className="ml-3 font-medium text-base text-black ">
                                {postData?.propertyType?.length > 0 &&
                                  postData?.propertyType[0]?.propertyType}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="lg:mb-5 md:mb-0 mb-0">
                          <img
                            src={ShareIcon}
                            alt=""
                            height={24}
                            width={24}
                            style={{ cursor: "pointer" }}
                            onClick={shareFunction}
                          />
                          {postSaved.includes(id) == true &&
                          token?.length > 0 ? (
                            <i
                              className="pi pi-star-fill lg:ml-4 md:ml-4 ml-3 text-main "
                              onClick={() => handleSaved()}
                              style={{ fontSize: "1.5rem", color: "#4E515F" }}
                            ></i>
                          ) : (
                            <i
                              className="pi pi-star lg:ml-4 md:ml-4 ml-3 "
                              onClick={() => handleSaved()}
                              style={{ fontSize: "1.5rem", color: "#4E515F" }}
                            ></i>
                          )}{" "}
                        </div>
                      </div>
                      <div className="flex align-items-center lg:hidden ">
                        <img src={BadIcon} width={24} height={24} alt=""></img>
                        <span className="ml-3 font-medium text-base text-black">
                          {postData?.bedrooms === null
                            ? "any"
                            : `${postData.bedrooms}+`}
                        </span>

                        <p className="flex align-items-center  ml-3">
                          <img
                            src={BathroomIcon}
                            width={24}
                            height={24}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-base text-black">
                            {postData?.bathrooms === null
                              ? "any"
                              : `${postData.bathrooms}+`}
                          </span>
                        </p>

                        <p className="flex align-items-center  ml-3">
                          <img
                            src={CarIcon}
                            width={24}
                            height={24}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-base text-black">
                            {postData?.carSpaces === null
                              ? "any"
                              : `${postData.carSpaces}+`}
                          </span>
                        </p>
                        <p className="flex align-items-center  ml-3">
                          <span className="font-medium text-base text-black">
                            |
                          </span>
                          <span className="ml-3 font-medium text-base text-black ">
                            {postData?.propertyType?.length > 0 &&
                              postData?.propertyType[0]?.propertyType}
                          </span>
                        </p>
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-5" />
                      {/* property type */}
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Property type
                          </h2>
                        </div>
                        {postData?.propertyType?.length > 0 &&
                          postData?.propertyType?.map((item, index) => {
                            return (
                              <div className="col-12 md:col-6  mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {item?.propertyType}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />
                      <div className="col-12 md:col-12">
                        <h2 className=" text-dark font-medium text-2xl font-bold">
                          Price
                        </h2>
                      </div>
                      <div className="col-12 md:col-12 mb-3">
                        <div className="flex align-items-center">
                          <img
                            src={PriceIcon}
                            width={20}
                            height={20}
                            alt=""
                          ></img>
                          <span className="ml-3 font-medium text-black font-semibold">
                            {postData?.priceRange?.min &&
                            postData?.priceRange?.max
                              ? `$${postData?.priceRange?.min} - $${postData?.priceRange?.max}`
                              : postData?.priceRange?.min
                              ? `$${postData?.priceRange?.min}`
                              : postData?.priceRange?.max
                              ? `$${postData?.priceRange?.max}`
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 md:col-12">
                        <hr className="buyer-hr p-0 m-0" />
                      </div>
                      {/* Available Date */}
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Available Date
                          </h2>
                        </div>
                        <div className="col-12 md:col-12 mb-3">
                          <div className="flex align-items-center">
                            <img
                              src={CalendarIcon}
                              width={20}
                              height={20}
                              alt=""
                            ></img>
                            <span className="ml-3 font-medium text-black text-lg">
                              {postData?.purchaseTimeFrame}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Property requierments */}
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Property requierments
                          </h2>
                        </div>
                        {postData?.propertyRequierments?.furnished ===
                          "furnished" &&
                        postData?.propertyRequierments?.petsConsidered ===
                          "petsConsidered" ? (
                          <>
                            <div className="col-12 md:col-6  mb-3">
                              <div className="flex align-items-center ">
                                <img
                                  src={FurnishedIcon}
                                  width={20}
                                  height={20}
                                  alt=""
                                ></img>

                                <span className="ml-3 font-medium text-black text-lg ">
                                  Furnished
                                </span>
                              </div>
                            </div>
                            <div className="col-12 md:col-6  mb-3">
                              <div className="flex align-items-center ">
                                <img
                                  src={FurnishedIcon}
                                  width={20}
                                  height={20}
                                  alt=""
                                ></img>

                                <span className="ml-3 font-medium text-black text-lg ">
                                  Pets Considered
                                </span>
                              </div>
                            </div>
                          </>
                        ) : postData?.propertyRequierments?.furnished ===
                            "furnished" &&
                          postData?.propertyRequierments?.petsConsidered ===
                            null ? (
                          <>
                            {" "}
                            <div className="col-12 md:col-6  mb-3">
                              <div className="flex align-items-center ">
                                <img
                                  src={FurnishedIcon}
                                  width={20}
                                  height={20}
                                  alt=""
                                ></img>

                                <span className="ml-3 font-medium text-black text-lg ">
                                  Furnished
                                </span>
                              </div>
                            </div>
                          </>
                        ) : postData?.propertyRequierments?.furnished ===
                            null &&
                          postData?.propertyRequierments?.petsConsidered ===
                            "petsConsidered" ? (
                          <div className="col-12 md:col-6  mb-3">
                            <div className="flex align-items-center ">
                              <img
                                src={FurnishedIcon}
                                width={20}
                                height={20}
                                alt=""
                              ></img>

                              <span className="ml-3 font-medium text-black text-lg">
                                Pets Considered
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />
                      {/* Outdoor features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Outdoor features
                          </h2>
                        </div>
                        {postData?.outdoorFeatures?.length > 0 ? (
                          postData?.outdoorFeatures?.map((item, index) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {item?.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-2 font-medium text-black text-lg mb-3">
                            N/A
                          </span>
                        )}
                      </div>

                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Indoor  features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Indoor features
                          </h2>
                        </div>
                        {postData?.indoorFeatures?.length > 0 ? (
                          postData?.indoorFeatures?.map((item) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {item.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-2 font-medium text-black text-lg mb-3">
                            N/A
                          </span>
                        )}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      {/* Climate control & energy features */}
                      <div className="grid ">
                        <div className="col-12 md:col-12">
                          <h2 className=" text-dark font-semibold text-2xl p-0 m-0">
                            Climate control & energy
                          </h2>
                        </div>
                        {postData?.climateControlEnergy?.length > 0 ? (
                          postData?.climateControlEnergy?.map((item) => {
                            return (
                              <div className="col-12 md:col-6 mb-3">
                                <div className="flex align-items-center">
                                  <img
                                    src={`https://therealestateapi.appdeft.biz/${item.icon}`}
                                    width={20}
                                    height={20}
                                    alt=""
                                  ></img>
                                  <span className="ml-3 font-medium text-black text-lg ">
                                    {item?.title}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span className="ml-2 font-medium text-black text-lg mb-3">
                            N/A
                          </span>
                        )}
                      </div>
                      <hr className="buyer-hr p-0 m-0 mb-3" />

                      <h2 className=" text-dark font-semibold text-lg p-0 m-0">
                        Notes
                      </h2>
                      <p className="font-medium text-base text-black line-height-3">
                        {postData?.note ? postData?.note : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-5 ">
                    <Form
                      setForm={setForm}
                      form={form}
                      handleSubmit={handleSubmit}
                      code={code}
                      setCode={setCode}
                      postData={postData}
                    />
                  </div>
                </div>
              </div>
              {/* <h2
                className=" heading-text-more-info font-semibold text-dark  p-0 m-0 text-center mb-5 mt-5"
                style={{ fontSize: "32px" }}
              >
                Similar properties
              </h2>
              <Slider className="" {...briefSettings}>
                {similarProperty?.map((item, index) => {
                  return index <= 2 ? (
                    <>
                      {item.postType === "BUYER" ||
                      item.postType === "RENTER" ? (
                        item.address?.map((items, childIndex) => {
                          return childIndex <= 0 ? (
                            <div className="p-2">
                              <ProfileCard
                                cardclass="all-profile-card"
                                size=" mb-0 bussiness-card-box"
                                detailClass="brief-card"
                                item={item}
                                index={index}
                                items={items}
                                card={true}
                              />
                            </div>
                          ) : null;
                        })
                      ) : (
                        <div className="p-2">
                          <ProfileCard
                            cardclass="all-profile-card"
                            size=" mb-0 bussiness-card-box"
                            detailClass="brief-card"
                            item={item}
                            index={index}
                            card={true}
                          />
                        </div>
                      )}
                    </>
                  ) : null;
                })}
              </Slider> */}
            </div>
          </ContainerBox>
        </div>
        {selectProfile === true ? (
          <div className="landing-page-modal">
            <CustomDialog
              header={
                <h2
                  className="mt-0 font-semibold text-dark mt-3 ml-3"
                  style={{ fontSize: "32px" }}
                >
                  Pick a Profile to Create a Brief
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                </h2>
              }
              visible={selectProfile}
              onHide={() => SetSelectProfile(false)}
            >
              <PickProfile
                SetSelectProfile={SetSelectProfile}
                selectProfile={selectProfile}
              />
            </CustomDialog>
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
};
