import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeContent from "../../views/home/HomeContent";
import { ButtonOutlined } from "../buttons/button";

export const PickModalProfile = ({ SelectRole, setSelectRole }) => {
  const [active, setActive] = useState();
  const navigate = useNavigate();
  const { modalprofile } = HomeContent();
  const token = localStorage?.getItem("realState")
  const handleSubmit = (item) => {
      navigate("/sign-up", { state: item.name })
      setSelectRole(false)
  }
  return (

    <div className="">

      <h2 className="mt-0 font-semibold text-dark mt-4 text-center " style={{ fontSize: "32px" }} >
        Are you a buyer, seller or renter?
      </h2>

      <p className="mb-3 text-black font-medium  mb-5 text-center text-lg">
        Let us know so we can provide you with the best possible service.
      </p>
      <div className="grid mb-3">
        {modalprofile?.map((item, index) => {
          return (
            <div className="lg:col-4 col-12 mb-4" key={item.id} name={item.heading}
              onClick={(e) => { setActive(item.id); handleSubmit(item); }}>

              <div className={`shadow-2 text-center p-3 bg-white  profile-select cursor-pointer text-black  ${active == item.id && "active"
                }`} style={{ borderRadius: "10px" }}  >

                <img src={item.img} alt="" width={48} height={48} />
                <p className="text-lg  font-medium">{item.heading}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* <ButtonOutlined label="CONTINUE " onClick={() => handleCheck()} /> */}
    </div>
  );
};
