import "./styles.scss";
import Index from "./routes.js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo({ top: 0, left: 0 });
  }, [pathname]);

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Index />
    </div>
  );
}

export default App;
