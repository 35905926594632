import React from "react";
import { length, equal, customPasswordCheck, notEqual } from "./javascript";
import { firstLetterToUppercase, stringValidation, emailValidation, number } from "./regex";
let password = ''
let confirmPassword = ''
let newPassword = ""
let oldPassword = ""
export const allValidations = (name, value, state) => {
    const formErrors = { ...state.formErrors };
    if (name === 'password') {
        password = value
    }
    if (name === 'confirmPassword') {
        confirmPassword = value
    }
    if (name === "oldPassword") {
        oldPassword = value
    }
    if (name === "newPassword") {
        newPassword = value
    }
    switch (name) {
        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "password":
            if (!equal(length(value))) {
                const valid = customPasswordCheck(value)
                if (valid) {
                    formErrors[name] = valid
                }
                else {
                    formErrors[name] = ''
                }
            } else formErrors[name] = 'Password is required!'
            break;
        case "oldPassword":
            if (!equal(length(value))) {
                const valid = customPasswordCheck(value)
                if (valid) {
                    formErrors[name] = valid
                }
                else {
                    formErrors[name] = ''
                }
            } else formErrors[name] = 'Password is required!'
            break;
        case "loginPassword":
            if (equal(length(value))) {
                formErrors[name] = `Password is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "firstName":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "lastName":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case 'confirmPassword':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`
            } else if (password === "") {
                formErrors[name] = ''
            }
            else if (notEqual(password, value)) {
                formErrors[name] = `Password and Confirm Password is not match!`
            }
            else if (equal(password, value)) {
                formErrors[name] = ''
            }
            break;

        case 'newPassword':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`
            } else if (oldPassword === "") {
                formErrors[name] = ''
            }
            else if (notEqual(oldPassword, value)) {
                formErrors[name] = `New Password and Confirm Password is not match!`
            }
            else if (equal(oldPassword, value)) {
                formErrors[name] = ''
            }
            break;
        case 'mobile':
            if (!number(value)) {
                formErrors[name] = 'Please enter only number!'
            } else if (equal(length(value))) {
                formErrors[name] = 'Mobile number is required!'
            } else {
                formErrors[name] = ''
            }
            break
        case 'fullAddress':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case 'fullName':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case 'message':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case 'suburb':
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "typeOfPurchase":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "establishedProperty":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "streetAddress":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "bedrooms":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "bathrooms":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "carSpaces":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "priceRange":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "purchaseTimeFrame":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "landSize":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "address":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "purchaseMethod":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "propertyType":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "buildYear":
            if (value === "Invalid date") {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "propertiesTitle":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "price":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "Unit":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "Description":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "image":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "addFloorplan":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "propertyRequierments":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "availableDate":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "min":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            }
            else {
                formErrors[name] = "";
            }
            break;
        case "financeDetails":
            if (value?.length === 0) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            }
            else {
                formErrors[name] = "";
            }
            break;
            case "depositAmount":
                if (value?.length === 0) {
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                }
                else {
                    formErrors[name] = "";
                }
                break;
        case "max":
            if (value?.length === "") {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "propertyMustHave":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        default:
            break;
    }
    return formErrors;
}