import React, { useEffect } from "react";
import Logo from "../assets/logo/The Buyers place.png";
import ButtonComponent from "../components/buttons/button";
import hamburgerIcon from "../assets/icons/hamburger.png";
import { Link, useLocation } from "react-router-dom";
import { ContainerBox } from "../shared/ContainerBox";
import profileIcon from "../assets/images/profileframe.png";
import { useNavigate } from "react-router-dom";
import { PickModalProfile } from "../components/modal/PickModalProfile";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import MainLogo from "../assets/images/Logo.png";
import WhiteMenuToggleIcon from "../assets/icons/white menu toggle.png";

export default function Topbar({ SetSelectProfile, selectProfile }) {
  let location = useLocation();
  const navigate = useNavigate();
  const closeRef = useRef();
  const token = localStorage.getItem("realState");
  const [SelectRole, setSelectRole] = useState(false);
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const [headerColor, setHeaderColor] = useState("");

  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setHeaderColor("rgba(243, 244, 245, 1) ")
      : setHeaderColor("");
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });

  return (
    <>
      <div
        className={`topbar_container w-full left-0 top-0 fixed z-5 pt-5 pb-3 lg:pt-0 md:pt-0 lg:pb-0 md:pb-0 m-0 ${location.pathname === "/"
          ? ""
          : " header-sign-up"
          }`}
        style={location.pathname === "/" ? { background: headerColor } : null}
      >
        <ContainerBox style={{ width: "100%" }}>
          <div className="lg:py-3 grid p-0 flex align-items-center justify-content-between lg:justify-content-center container-inner m-0 ">
            <div className="md:col-3 m-0">
              {location.pathname === "/" &&
                headerColor !== "rgba(243, 244, 245, 1) " ? (
                <Link to="/" className="no-underline" onClick={gotoBtn}>
                  <img
                    src={Logo}
                    alt=""
                    width={229}
                    height={56}
                    className="landing-page-logo ml-3"
                  />
                </Link>
              ) : (
                <Link to="/" className="no-underline" onClick={gotoBtn}>
                  <img
                    src={MainLogo}
                    alt=""
                    width={229}
                    height={56}
                    className="landing-page-logo ml-3"
                  />
                </Link>
              )}
            </div>
            <input
              ref={closeRef}
              type="checkbox"
              name="menuToggle"
              id="menuToggle"
              hidden
            />
            {location.pathname === "/" &&
              headerColor !== "rgba(243, 244, 245, 1) " ? (
              <label htmlFor="menuToggle">
                <img
                  src={WhiteMenuToggleIcon}
                  height={24}
                  width={24}
                  alt="HamburgerIcon"
                  className="mr-3"
                />
              </label>
            ) : (
              <label htmlFor="menuToggle">
                <img
                  src={hamburgerIcon}
                  height={24}
                  width={24}
                  alt="HamburgerIcon"
                  className="mr-3"
                />
              </label>
            )}

            <div
              className={`col-12 md:col-9 menu-container ${location.pathname === "/" &&
                headerColor !== "rgba(243, 244, 245, 1) "
                ? "home-nav"
                : "other-nav"
                } `}
            >
              <div className="col-12 md:col-3 p-0 m-0 flex justify-content-end ml-auto text-right align-items-center">
                <div></div>
                <label htmlFor="menuToggle" className="close-icon">
                  X
                </label>
              </div>
              <div className="grid align-items-center  justify-content-around flex text-gray-light ">
                <div className="nav_link text-lg font-medium  ">
                  <Link
                    to="/"
                    className="no-underline"
                    onClick={() => {
                      {
                        closeRef.current.checked = false;
                      }
                      gotoBtn();
                    }}
                  >
                    <span>Home</span>
                  </Link>
                </div>
                <div className="nav_link text-lg font-medium">
                  <Link
                    className="no-underline"
                    onClick={() => {
                      SetSelectProfile(true);
                      gotoBtn();
                      closeRef.current.checked = false;
                    }}
                  >
                    <span>Buy</span>
                  </Link>
                </div>
                <div className="nav_link text-lg font-medium">
                  <Link
                    className="no-underline"
                    onClick={() => {
                      SetSelectProfile(true);
                      gotoBtn();
                      closeRef.current.checked = false;
                    }}
                  >
                    <span>Sell</span>
                  </Link>
                </div>
                <div className="nav_link text-lg font-medium">
                  <Link
                    className="no-underline"
                    onClick={() => {
                      SetSelectProfile(true);
                      gotoBtn();
                      closeRef.current.checked = false;
                    }}
                  >
                    <span>Rent</span>
                  </Link>
                </div>
                <div className="nav_link text-lg font-medium">
                  <Link
                    to={"/about-us"}
                    onClick={() => {
                      closeRef.current.checked = false;
                      gotoBtn();
                    }}
                  >
                    <span>About us</span>
                  </Link>
                </div>
               
                <div className="nav_link text-lg font-medium">
                  <Link
                    to={"/contact-us"}
                    onClick={() => {
                      closeRef.current.checked = false;
                    }}
                  >
                    <span>Contact us</span>
                  </Link>
                </div>
                {token === null ? (
                  <>
                    <div className="nav_link login-button ">
                      {headerColor ? (
                        <Link
                          to="/sign-in"
                          className="no-underline"
                          onClick={() => {
                            {
                              closeRef.current.checked = false;
                            }
                            gotoBtn();
                          }}
                        >
                          <ButtonComponent
                            label="LOGIN"
                            className={`login-btn border-1 border-main text-base text-main border-1 lg:bg-transparent md:bg-transparent font-medium w-max p-3 px-4 `}
                          />
                        </Link>
                      ) : (
                        <Link
                          to="/sign-in"
                          className="no-underline"
                          onClick={() => {
                            {
                              closeRef.current.checked = false;
                            }
                            gotoBtn();
                          }}
                        >
                          <ButtonComponent
                            label="LOGIN"
                            className={`login-btn border-1 border-white   text-base text-light border-1 lg:bg-transparent md:bg-transparent font-medium w-max p-3 px-4 `}
                          />
                        </Link>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="nav_link login-button ">
                      {location.pathname === "/" &&
                        headerColor !== "rgba(243, 244, 245, 1) " ? (
                        <p
                          className="no-underline "
                          onClick={() => navigate("/my-properties")}
                        >
                          <i
                            className="pi pi-star star-icon "
                            style={{ fontSize: "1.5rem" }}
                          ></i>
                        </p>
                      ) : (
                        <p
                          className="no-underline "
                          onClick={() => navigate("/my-properties")}
                        >
                          <i
                            className="pi pi-star star-icon "
                            style={{ fontSize: "1.5rem", color: "#4E515F" }}
                          ></i>
                        </p>
                      )}
                    </div>
                    <div className="nav_link font-medium">
                      <Link
                        to="/profile"
                        className="no-underline"
                        onClick={() => {
                          closeRef.current.checked = false;
                        }}
                      >
                        <img src={profileIcon} alt="" height={48} width={48} />
                      </Link>
                    </div>
                  </>
                )}
              </div>
              {SelectRole === true ? (
                <div className="profile-modal">
                  <Dialog
                    style={{ minHeight: "100px", maxWidth: "615px" }}
                    visible={SelectRole}
                    onHide={() => setSelectRole(false)}
                    className="profile-modal w-full"
                    closable={true}
                  >
                    <PickModalProfile
                      SelectRole={SelectRole}
                      setSelectRole={setSelectRole}
                    />
                  </Dialog>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ContainerBox>
        {location.pathname === "/" &&
          headerColor !== "rgba(243, 244, 245, 1) " ? (
          <hr className="" style={{ color: "#F9FAFA" }} />
        ) : null}
      </div>
    </>
  );
}
