import React, { useState, useEffect, useRef } from "react";
import { Index } from "../../../layout";
import ContentArea from "../../../shared/ContentArea";
import { Button } from "primereact/button";
import {
  CustomCheckbox,
  CustomInput,
  CustomRadioButtons,
  CustomCalenderInput,
  CustomDropDown,
  CustomTextArea,
  CustomInputNumber,
} from "../../../components/input/AllInput";
import { ContainerBox } from "../../../shared/ContainerBox";
import ButtonComponent from "../../../components/buttons/button";
import {
  ClimateControl,
  ContactDetail,
  IndoorFeatures,
  OutdoorFeatures,
  PropertyDetail,
} from "../CommonFeild";
import { BuyerIndex } from "./BuyerIndex";
import { ConfirmDetails } from "./ConfirmDetails";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { PickProfile } from "../../home/PickProfile";
import ProfilePreview from "./ProfilePreview";
import SuccessfullProfile from "./SuccessfullProfile";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Divider } from "antd";
import { isAuthenticated } from "../../../services/authService";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { Dropdown } from "primereact/dropdown";
export const BuyerForm = ({ SetSelectProfile, selectProfile }) => {
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const {
    form,
    handleChangeSuburb,
    token,
    address,
    handleSelectAddressSuburb,
    navigate,
    id,
    emailCheck,
    minProperty,
    maxProperty,
    timeFrameDropdown,
    handlechangeStreetAddress,
    handleSelectStreetAddress,
    PropertyList,
    handleSelectAddress,
    handleSubmit,
    handleChangeAddress,
    inputList,
    show,
    page,
    setPage,
    code,
    setCode,
    handleChange,
    check,
    handleClear,
    handleTypeChange,
    handleRoomChange,
    handleRangeChange,
    highlight,
    bathRoomHightlight,
    carHightlight,
    setForm,
    minLandSize,
    maxLand,
    minLand,
    handleAddClick,
    handleRemoveClick,
    minPriceRange,
  } = BuyerIndex();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (emailCheck === true && token === null) {
      setVisible(true);
    }
  }, [emailCheck]);
  return (
    <>
      {page === 1 ? (
        <>
          <div className="Buyer-form-container bg-green-light pt-6 ">
            <div className="py-7 w-full ">
              <ContainerBox>
                <div className="grid justify-content-center lg:mb-6 lg:mt-6 mt-3 mb-3  p-0 m-0">
                  <div className="lg:col-7 md:col-12 col-12 text-center">
                    <h1
                      className="heading_text font-semibold text-dark p-0 m-0"
                      style={{ fontSize: "56px" }}
                    >
                      Buyer Brief Form
                    </h1>
                    <p className="text-lg text-black line-height-3 font-medium">
                      Complete the details below, so we can understand your
                      requirements, and then we will help you discover the ideal
                      property that aligns with your needs.
                    </p>
                  </div>
                </div>
              </ContainerBox>
              <ContentArea>
                {/* / Contact-details / */}
                <div className="grid p-0 m-0  ">
                  <ContactDetail
                    handleChange={handleChange}
                    token={token}
                    address={address}
                    handleSelectAddressSuburb={handleSelectAddressSuburb}
                    handleChangeSuburb={handleChangeSuburb}
                    setForm={setForm}
                    form={form}
                    code={code}
                    setCode={setCode}
                    highlight={highlight}
                    handlechangeStreetAddress={handlechangeStreetAddress}
                    handleSelectStreetAddress={handleSelectStreetAddress}
                    show={show}
                    emailCheck={emailCheck}
                    navigate={navigate}
                  />
                  <div className="col-12 md:col-12 ">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  {/* {/ Type of Purchase Section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Type of Purchase<code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-4 lg:col-3 lg:w-3 ">
                    <CustomRadioButtons
                      className="Owner-occupied"
                      optionLabel="Owner occupied"
                      extraClassName="Owner-occupied w-auto"
                      labelClass="font-bold"
                      id="Owner"
                      name="typeOfPurchase"
                      value="ownerOccupied"
                      onChange={handleChange}
                      checked={form?.typeOfPurchase === "ownerOccupied"}
                      errorMessage={form?.formErrors?.typeOfPurchase}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-3 ">
                    <CustomRadioButtons
                      optionLabel="Investment"
                      extraClassName="w-auto"
                      id="Investment"
                      name="typeOfPurchase"
                      value="investment"
                      onChange={handleChange}
                      checked={form?.typeOfPurchase === "investment"}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  {/* {/ New or Established property section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      New or Established property
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-3 lg:col-2 lg:w-2">
                    <CustomRadioButtons
                      optionLabel="Any"
                      extraClassName="w-auto"
                      id="types"
                      name="establishedProperty"
                      value="allTypes"
                      onChange={handleChange}
                      checked={form?.establishedProperty === "allTypes"}
                      errorMessage={form?.formErrors?.establishedProperty}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-3 lg:col-2 lg:w-2">
                    <CustomRadioButtons
                      optionLabel="New"
                      extraClassName="w-auto"
                      id="New"
                      name="establishedProperty"
                      value="new"
                      onChange={handleChange}
                      checked={form?.establishedProperty === "new"}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-3 lg:col-2 lg:w-2">
                    <CustomRadioButtons
                      optionLabel="Established"
                      extraClassName="w-auto"
                      id="establishedProperty"
                      name="establishedProperty"
                      value="established"
                      onChange={handleChange}
                      checked={form?.establishedProperty === "established"}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  {/* {/ Property type Section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Property type<code className="p-error text-sm">*</code>
                    </h2>
                    {form?.formErrors?.propertyType && (
                      <small className="p-error text-sm ml-2">
                        {form?.formErrors?.propertyType}
                      </small>
                    )}
                  </div>
                  {PropertyList?.propertyTypes?.length > 0 &&
                    PropertyList?.propertyTypes?.map((item) => {
                      return (
                        <div className="col-12 md:col-6">
                          {item?.forProfile?.includes("BUYER") ? (
                            <CustomCheckbox
                              optionLabel={item?.propertyType}
                              id={item?.propertyType}
                              name="propertyType"
                              value={item}
                              onChange={handleTypeChange}
                              disabled={
                                form?.formErrors?.password?.length > 0 ||
                                form?.formErrors?.confirmPassword?.length > 0
                                  ? true
                                  : false
                              }
                              checked={check?.some((type) =>
                                item._id === type._id ? true : false
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}

                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  {/* {/ Price Range Section /} */}
                  <div className="col-12 md:col-12">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Price Range<code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  {/* <p className="col-12 md:col-12 p-0 m-0 text-center">
                    <div style={{ marginTop: "-37px", fontSize: "20px", color: "#00051A", fontWeight: "bold" }}>{Object.keys(form?.priceRange)?.length === 0 ? "Any Price" : form?.priceRange?.min === 50000 && form?.priceRange?.max === 15000000 || form?.priceRange?.max > 15000000 ? "Any Price" : form.priceRange?.max === 15000000 ? `More than $${form?.priceRange?.min < 1000000 ? Math.floor(form.priceRange?.min / 1000) + "k" : Math.floor(form?.priceRange?.min / 1000000) + "M"}` : form?.priceRange?.max < 15000000 && form.priceRange?.min === 50000 ? `Up to $${form.priceRange?.max < 1000000 ? Math.floor(form?.priceRange?.max / 1000) + "K" : Math.floor(form?.priceRange?.max / 1000000) + "M"}` : `$${form?.priceRange?.min < 100000 ? Math.floor(form?.priceRange?.min / 1000) + "k" : Math.floor(form?.priceRange?.min / 1000000) + "M"} to $${form?.priceRange?.max < 1000000 ? Math.floor(form?.priceRange?.max / 1000) + "K" : Math.floor(form?.priceRange?.max / 1000000) + "M"}`}</div>
                  </p> */}
                  <div className="  p-2 grid w-full">
                    <div className="col-12 md:col-6">
                      <CustomDropDown
                        extraClassName="w-full"
                        placeholder="Select"
                        label="Min"
                        options={minPriceRange}
                        optionLabel="label"
                        name="min"
                        value={form?.priceRange?.min}
                        onChange={handleRangeChange}
                        errorMessage={form?.formErrors?.min}
                      />
                    </div>
                    {console.log("form", form)}
                    <div className="col-12 md:col-6">
                      <CustomDropDown
                        extraClassName="w-full"
                        placeholder="Select"
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                        label="Max"
                        options={
                          form?.priceRange?.min
                            ? minPriceRange.slice(
                                minPriceRange.findIndex((item) => {
                                  return item.value == form?.priceRange.min;
                                })
                              )
                            : minPriceRange.filter((range) => range.value !== 0)
                        }
                        optionLabel="label"
                        name="max"
                        value={form?.priceRange?.max}
                        onChange={handleRangeChange}
                        errorMessage={form?.formErrors?.max}
                      />
                    </div>
                    {/* <Slider className=" mr-1 ml-1 p-0 m-0 price-range"
                      range onChange={(e) => handleRangeChange(e, "pricerange")} name="priceRange"
                      value={form.selectPriceRange} min={50000} max={15000000} /> */}
                  </div>
                  {form?.formErrors?.priceRange && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.priceRange}
                    </small>
                  )}
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>
                  {/* 


                  {/* {/ Bedrooms Section /} */}

                  <PropertyDetail
                    handleRoomChange={handleRoomChange}
                    form={form}
                    highlight={highlight}
                    bathRoomHightlight={bathRoomHightlight}
                    carHightlight={carHightlight}
                  />
                  <div className="col-12 md:col-12 ">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div>

                  {/* {/ Purchase timeframe section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold">
                      Purchase timeframe
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 lg:col-6">
                    <CustomDropDown
                      label="Min"
                      placeholder="Select"
                      extraClassName="w-full"
                      labelClassName="text-black"
                      options={timeFrameDropdown}
                      onChange={handleChange}
                      name="purchaseTimeFrame"
                      optionLabel=""
                      value={form.purchaseTimeFrame}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                    {form?.formErrors?.purchaseTimeFrame && (
                      <small className="p-error text-sm ml-2">
                        {form?.formErrors?.purchaseTimeFrame}
                      </small>
                    )}
                  </div>
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
                  </div>

                  {/* {/ Land size Section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
                      Land size<code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 lg:col-6">
                    <CustomDropDown
                      label="Min"
                      placeholder="Select"
                      extraClassName="w-full"
                      labelClassName="text-black"
                      options={["Any", ...minLandSize]}
                      onChange={handleChange}
                      name="minLand"
                      optionLabel=""
                      value={minLand}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 md:col-6 lg:col-6">
                    <CustomDropDown
                      label="Max"
                      placeholder="Select"
                      extraClassName="w-full"
                      labelClassName="text-black"
                      optionLabel=""
                      name="maxLand"
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      options={
                        minLand
                          ? [
                              "Any",
                              ...minLandSize.slice(
                                minLand !== "Any"
                                  ? minLandSize.indexOf(minLand)
                                  : 0
                              ),
                            ]
                          : ["Any", ...minLandSize]
                      }
                      onChange={handleChange}
                      value={maxLand}
                    />
                  </div>
                  {form?.formErrors?.landSize && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.landSize}
                    </small>
                  )}
                  <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
                  </div>

                  {/* {/ Purchase Location section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
                      Purchase Location
                      <code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <>
                    {inputList?.map((item, index) => {
                      return (
                        <div
                          className={`col-12 md:col-12 lg:col-12 ${
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? "pointer-events-none"
                              : ""
                          }`}
                        >
                          <PlacesAutocomplete
                            value={item.streetAddress}
                            onChange={(e) => handleChangeAddress(e, index)}
                            onSelect={(e) => handleSelectAddress(e, index)}
                            searchOptions={{
                              componentRestrictions: { country: ["au"] },
                              types: ["locality"],
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                              item,
                            }) => (
                              <div className="subhurb-hightlight lg:mr-0 md:mr-0 mr-4">
                                <CustomInput
                                  label="Suburb"
                                  value={item?.streetAddress}
                                  {...getInputProps({
                                    placeholder: "Search Suburb",
                                    className:
                                      "location-search-input w-full p-3",
                                  })}
                                />
                                {inputList.length !== 1 && (
                                  <i
                                    className="pi pi-minus lg:p-2 md:p-2 "
                                    style={{
                                      " color": "rgb(112, 128, 144)",
                                      // "padding": "10px",
                                      marginTop: "10px",
                                      marginLeft: "5px",
                                      backgroundColor: "#f9fafa",
                                      borderRadius: "10px",
                                    }}
                                    onClick={() => handleRemoveClick(index)}
                                  ></i>
                                  // <button className="mr10" >
                                  //   Remove
                                  // </button>
                                )}
                                {show === true ? (
                                  <div className="autocomplete-dropdown-container absolute top-100 bg-white z-2 p-0 shadow-1  mb-2 lg:col-6">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active mb-2 p-2 border-bottom-1 text-sm surface-border"
                                        : "suggestion-item mb-2 p-2 border-bottom-1 text-sm surface-border";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          key={suggestion.placeId}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span className="">
                                            {suggestion.description}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {inputList.length - 1 === index && (
                            <div className=" add-suburb py-2 p-1 ">
                              <p
                                onClick={handleAddClick}
                                className="text-xl text-dark font-medium justify-content-between flex p-2 border-round-lg p-0 m-0"
                                style={{
                                  backgroundColor: "rgba(249, 250, 250, 1)",
                                }}
                              >
                                &nbsp; Add Suburb
                                <span>
                                  <i
                                    className="pi pi-plus"
                                    style={{
                                      fontSize: "1rem",
                                      color: "rgba(42, 53, 61, 1)",
                                    }}
                                  ></i>
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                  {form?.formErrors?.address && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.address}
                    </small>
                  )}
                  <div className="col-12 md:col-12 mb-3">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0 mt-2" />
                  </div>

                  {/* {/ Purchase method section /} */}
                  <div className="col-12 md:col-12 ">
                    <h2 className="text-dark font-medium text-2xl ml-1 flex align-items-start font-bold m-0">
                      Purchase method<code className="p-error text-sm">*</code>
                    </h2>
                  </div>
                  <div className="col-12 md:col-4 lg:col-3 lg:w-2">
                    <CustomRadioButtons
                      optionLabel="Finance"
                      extraClassName="Owner-occupied w-auto"
                      id="Finance"
                      name="purchaseMethod"
                      value="FINANCE"
                      onChange={handleChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={form?.purchaseMethod === "FINANCE"}
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-3 ">
                    <CustomRadioButtons
                      optionLabel="Own Funds"
                      extraClassName="w-auto"
                      id="Cash"
                      name="purchaseMethod"
                      value="OWNFUNDS"
                      onChange={handleChange}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                      checked={form?.purchaseMethod === "OWNFUNDS"}
                    />
                  </div>

                  {/* <div className="col-12 md:col-12">
                    <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                  </div> */}
                </div>

                {form?.purchaseMethod === "FINANCE" ? (
                  <div className="grid m-0 p-0">
                    <div className="col-12 md:col-4 lg:col-4 lg:w-3">
                      <CustomRadioButtons
                        optionLabel="Finance is arranged"
                        extraClassName="Owner-occupied w-auto"
                        id="arranged"
                        name="financeDetails"
                        value={true}
                        onChange={handleChange}
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                        checked={form?.financeDetails?.isArranged === true}
                      />
                    </div>
                    <div className="col-12 md:col-8 lg:col-8 ">
                      <CustomRadioButtons
                        optionLabel="Need to arrange finance"
                        extraClassName="w-auto"
                        id="financeDetails"
                        name="financeDetails"
                        value={false}
                        onChange={handleChange}
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                        checked={form?.financeDetails?.isArranged === false}
                      />
                    </div>
                  </div>
                ) : null}
                {form?.formErrors?.financeDetails && (
                  <small className="p-error text-sm ml-3">
                    {form?.formErrors?.financeDetails}
                  </small>
                )}
                {form?.purchaseMethod === "FINANCE" &&
                form?.financeDetails?.isArranged === false ? (
                  <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section mb-3 ">
                    <label
                      htmlFor="integer"
                      className="font-bold block mb-2 text-lg ml-1"
                    >
                      Deposit ($)
                    </label>
                    <div
                      className="border-1 border-round-lg flex align-items-center ml-1"
                      style={{ borderColor: "#8F9A99" }}
                    >
                      <span>
                        <i
                          className="pi pi-dollar ml-2 "
                          style={{ fontSize: "1rem", marginTop: "6px" }}
                        ></i>
                      </span>
                      <InputNumber
                        inputClassName="border-0"
                        className=" buyer-finance-content w-full p-2  border-0"
                        extraClassName="border-0"
                        inputId="integeronly"
                        name="depositAmount"
                        onValueChange={handleChange}
                        placeholder="amount"
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                        value={form?.financeDetails?.depositAmount}
                        style={{ border: "0px" }}
                      />
                    </div>
                  </div>
                ) : null}
                {form?.financeDetails?.isArranged === true &&
                form?.purchaseMethod === "FINANCE" ? (
                  <div className="grid p-0 m-0 mb-3">
                    <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section">
                      <label
                        htmlFor="integer"
                        className="font-bold block mb-2 text-lg"
                      >
                        Pre-approved ($)
                      </label>
                      <div
                        className="border-1 border-round-lg flex align-items-center ml-1"
                        style={{ borderColor: "#8F9A99" }}
                      >
                        <span>
                          <i
                            className="pi pi-dollar ml-2  "
                            style={{ fontSize: "1rem", marginTop: "6px" }}
                          ></i>
                        </span>
                        <InputNumber
                          inputClassName="border-0"
                          label="Pre-approved ($)"
                          className=" buyer-finance-content w-full p-2 border-0"
                          inputId="integeronly"
                          name="preApproved"
                          onValueChange={handleChange}
                          value={form?.financeDetails?.preApproved}
                          disabled={
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? true
                              : false
                          }
                          placeholder="amount"
                        />
                      </div>
                    </div>
                    <div className="buyer-finance-content col-12 md:col-6 buyer-finance-section">
                      <label
                        htmlFor="integer"
                        className="font-bold block mb-2 text-lg"
                      >
                        Deposit ($)
                      </label>
                      <div
                        className="border-1 border-round-lg flex align-items-center ml-1"
                        style={{ borderColor: "#8F9A99" }}
                      >
                        <span>
                          <i
                            className="pi pi-dollar ml-2 "
                            style={{ fontSize: "1rem", marginTop: "6px" }}
                          ></i>
                        </span>
                        <InputNumber
                          inputClassName="border-0"
                          label="Deposit ($)"
                          className=" buyer-finance-content w-full p-2 border-0"
                          inputId="integeronly"
                          name="depositAmount"
                          onValueChange={handleChange}
                          value={form?.financeDetails?.depositAmount}
                          placeholder="amount"
                          disabled={
                            form?.formErrors?.password?.length > 0 ||
                            form?.formErrors?.confirmPassword?.length > 0
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {form?.formErrors?.depositAmount && (
                  <small className="p-error text-sm ml-3">
                    {form?.formErrors?.depositAmount}
                  </small>
                )}
                {form?.formErrors?.purchaseMethod && (
                  <small className="p-error text-sm ml-3">
                    {form?.formErrors?.purchaseMethod}
                  </small>
                )}
                <div className="col-12 md:col-12 mb-3 ">
                  <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                </div>
                {/* {/ Outdoor Features section /} */}
                <div className="col-12 md:col-12 ">
                  <OutdoorFeatures
                    PropertyList={PropertyList}
                    form={form}
                    setForm={setForm}
                    id={id}
                  />
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                </div>

                {/* {/ Indoor Features section /} */}
                <div className="col-12 md:col-12">
                  <IndoorFeatures
                    PropertyList={PropertyList}
                    form={form}
                    setForm={setForm}
                    id={id}
                  />
                </div>
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                </div>

                {/* {/ Climate Control Features section /} */}
                <div className="col-12 md:col-12">
                  <ClimateControl
                    PropertyList={PropertyList}
                    form={form}
                    setForm={setForm}
                    id={id}
                  />
                </div>
                <div className="col-12 md:col-12 ">
                  <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                </div>
                <div className="col-12 md:col-12 ">
                  <h2 className="text-dark font-medium text-2xl ml-1  flex align-items-start font-bold">
                    Top 2 Property must-haves
                    <code className="p-error text-sm">*</code>
                  </h2>
                </div>
                <div className="grid p-0 m-0">
                  <div className="col-12 md:col-6 lg:col-6 flex ">
                    <span className="mt-3 text-xl"> 1.</span>
                    <span className="w-full ml-2">
                      <CustomInput
                        extraClassName="w-full"
                        labelClassName="text-black"
                        onChange={handleChange}
                        name="minProperty"
                        optionLabel=""
                        value={minProperty}
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                      />
                    </span>
                  </div>
                  <div className="col-12 md:col-6 lg:col-6 flex">
                    <span className="mt-3 text-xl"> 2.</span>
                    <span className="w-full ml-2">
                      <CustomInput
                        extraClassName="w-full"
                        labelClassName="text-black"
                        optionLabel=""
                        name="maxProperty"
                        onChange={handleChange}
                        value={maxProperty}
                        disabled={
                          form?.formErrors?.password?.length > 0 ||
                          form?.formErrors?.confirmPassword?.length > 0
                            ? true
                            : false
                        }
                      />
                    </span>
                  </div>
                  {form?.formErrors?.propertyMustHave && (
                    <small className="p-error text-sm ml-3">
                      {form?.formErrors?.propertyMustHave}
                    </small>
                  )}
                </div>

                {/* {/ notes /} */}
                <div className=" buyer-textarea grid m-0 p-0 mt-3">
                  <div className="col-12 md:col-12 lg:col-12">
                    <CustomTextArea
                      label="Notes"
                      className="buyer-input-textarea w-full bg-transparent    p-3  mb-3"
                      placeholder="Tell us more about the property you are looking for."
                      name="note"
                      onChange={handleChange}
                      value={form?.note}
                      disabled={
                        form?.formErrors?.password?.length > 0 ||
                        form?.formErrors?.confirmPassword?.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>

                {/* {/ hr /} */}
                <div className="col-12 md:col-12 mb-3">
                  <hr className="buyer-hr ml-1 mr-1 p-0 m-0" />
                </div>

                <div className="buyer-form-button grid p-0 m-0 flex justify-content-between">
                  <div className="col-12 md:col-3  lg:col-2 ">
                    <div className="mb-3">
                      <ButtonComponent
                        onClick={() => handleClear()}
                        label="CLEAR ALL"
                        className=" bg-transparent text-black  border-0"
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-3 lg:col-2">
                    <div className=" ">
                      <Button
                        label="NEXT"
                        className="bg-main border-main w-full p-3 font-semibold font-base"
                        onClick={() => {
                          handleSubmit();
                          gotoBtn();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ContentArea>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {page === 2 ? (
        <ConfirmDetails
          data={form}
          setPage={setPage}
          emailCheck={emailCheck}
          selectProfile={selectProfile}
          SetSelectProfile={SetSelectProfile}
        />
      ) : (
        ""
      )}
      {page === 3 ? (
        <ProfilePreview data={form} setPage={setPage} emailCheck={emailCheck} />
      ) : (
        ""
      )}
      {page === 4 ? <SuccessfullProfile setPage={setPage} /> : ""}
      {selectProfile === true ? (
        <div className="landing-page-modal">
          <CustomDialog
            header={
              <h2
                className="mt-0 font-semibold text-dark mt-3 ml-3"
                style={{ fontSize: "32px" }}
              >
                Pick a Profile to Create a Brief
                {isAuthenticated() ? null : (
                  <p
                    className=" text-black font-medium pick-login-here"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Already have an account?
                    <cod
                      className="text-main font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        navigate("/sign-in");
                        SetSelectProfile(false);
                        gotoBtn();
                      }}
                    >
                      {" "}
                      Login here
                    </cod>{" "}
                  </p>
                )}
              </h2>
            }
            visible={selectProfile}
            onHide={() => SetSelectProfile(false)}
          >
            <PickProfile
              SetSelectProfile={SetSelectProfile}
              selectProfile={selectProfile}
            />
          </CustomDialog>
        </div>
      ) : (
        ""
      )}
      <div className="all-profile-modal">
        <Dialog
          visible={visible}
          style={{ width: "100vw" }}
          onHide={() => setVisible(false)}
          className="all-profile-modal lg:w-5 md:w-10 w-11"
        >
          <p className="m-0 ">
            <h1
              className="text-center font-semibold  py-3"
              style={{ fontSize: "32px", color: "#231F20" }}
            >
              This email already exist.
              <br /> To create a Brief, please login{" "}
            </h1>
            <p className="text-center text-black font-medium text-lg line-height-3">
              Thank you for your interest in creating a Brief with us.<br></br>
              It appears that the email address you provided is already
              associated with an existing account. To proceed, please log in
              using your credentials to create a Brief or manage your existing
              account.
            </p>
            <hr className="mt-5 mb-5" style={{ color: "#CCD1D1" }} />
            <div className=" flex align-items-center justify-content-between ">
              <span>
                <ButtonComponent
                  label="CANCEL"
                  className="bg-white  border-black text-black text-base px-5 px-3"
                  onClick={() => setVisible(false)}
                ></ButtonComponent>
              </span>

              <span>
                <ButtonComponent
                  label="LOG IN "
                  className="bg-white px-5 text-main"
                  onClick={() => navigate("/sign-in")}
                ></ButtonComponent>
              </span>
            </div>
          </p>
        </Dialog>
      </div>
    </>
  );
};
